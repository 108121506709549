import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import ReportTable from "../../../../../components/report-table";
import ViewWrapper from "../../../../../components/view-wrapper";
import { useAPI } from "../../../../../utils/hooks";
import { IOutputLessorLesseeSummary } from "../../../../../interfaces";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../../../../constants";
import { useAxios } from "../../../../../components/axios-provider";
import {
  generateLessorLesseeSummaryReport,
  getOutputLessorSummaryUrl,
} from "../../../../../utils/helpers";

interface IProps {
  getLessorOutputSummary: (
    dealId: number,
  ) => Promise<IOutputLessorLesseeSummary>;
}

export default function DealOutputLessorSummaryView({
  getLessorOutputSummary,
}: IProps) {
  const { caseDealId, dealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const [lessorSummary, setOutputLessorSummary] =
    React.useState<IOutputLessorLesseeSummary>();

  const {
    callAPI: getLessorOutputSummaryCallAPI,
    loading: getLessorOutputSummaryLoading,
    errored: getLessorOutputSummaryFailed,
  } = useAPI((dealId: number) => getLessorOutputSummary(Number(dealId)), {
    initialLoading: true,
  });

  React.useEffect(() => {
    getLessorOutputSummaryCallAPI(dealIdToUse).then((res) => {
      res && setOutputLessorSummary(res);
    });
  }, [dealIdToUse]);

  const rows = React.useMemo(
    () =>
      lessorSummary ? generateLessorLesseeSummaryReport(lessorSummary) : [],
    [lessorSummary],
  );

  return (
    <ViewWrapper
      loading={getLessorOutputSummaryLoading}
      error={getLessorOutputSummaryFailed}
      loadingPercentage={
        urlLoadingPercentages[getOutputLessorSummaryUrl(Number(dealIdToUse))]
      }
    >
      <Box>
        <Grid container>
          <Grid item xs={12} md={6} marginTop={2}>
            <Box>
              <ReportTable columns={ITEMS_AND_TOTAL_TABLE_COLUMN} rows={rows} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </ViewWrapper>
  );
}
