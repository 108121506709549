import React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import Button from "../../../../components/button";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import GeneralAssumptionFormModal from "../../../../components/general-assumptions-form-modal";
import ConstructionLoanDebt from "./construction-loan";
import ConditionalProtect from "../../../../components/conditional-protect";
import InvestorBridgeLoan from "./investor-bridge-loan";
import TransferBridgeLoan from "./transfer-bridge-loan";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import LogsWrapper from "../../../../components/logs-wrapper";
import Logs from "../../../../components/logs";
import LogsButton from "../../../../components/logs-button";
import { setCurrentOrgCurvesAction } from "../../../../utils/redux/slices";
import { cn, numberToUSD } from "../../../../utils/helpers";
import {
  useAPI,
  useAppSelector,
  useDrawer,
  useLogs,
} from "../../../../utils/hooks";
import {
  CLOSING_DATE_POINT_CHOICES,
  DATE_SELECTION_TYPE_CHOICES,
  FUNDING_METHODOLOGY_CHOICES,
  GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE,
} from "../../../../constants";
import {
  IGetCurvesParams,
  ILogsConfiguration,
  IOrganizationCurve,
  ISelectOption,
  ServerPaginatedResponse,
  IGeneralAssumptionResponse,
  IGeneralAssumptionsForm,
  IGeneralAssumptionsFormErrors,
} from "../../../../interfaces";

interface IProps {
  getGeneralAssumption: (
    dealId: number,
  ) => Promise<IGeneralAssumptionResponse[]>;
  addGeneralAssumption: (
    dealId: number,
    form: IGeneralAssumptionsForm,
  ) => Promise<IGeneralAssumptionResponse>;
  updateGeneralAssumption: (
    dealId: number,
    debtId: number,
    form: IGeneralAssumptionsForm,
  ) => Promise<IGeneralAssumptionResponse>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
}

export default function DealSizingConstructionDebt({
  getGeneralAssumption,
  addGeneralAssumption,
  updateGeneralAssumption,
  getCurves,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId, caseDealId } = useParams();

  const dispatch = useDispatch();
  const { currentDeal } = useAppSelector((s) => s.deal);
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const [generalAssumption, setGeneralAssumption] =
    React.useState<IGeneralAssumptionResponse>();
  const [addGeneralAssumptionsModalOpen, setAddGeneralAssumptionsModalOpen] =
    React.useState<boolean>(false);
  const [editGeneralAssumptionsModalOpen, setEditGeneralAssumptionsModalOpen] =
    React.useState<boolean>(false);
  const [form, setForm] = React.useState<IGeneralAssumptionsForm>(
    GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE,
  );

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  React.useEffect(() => {
    getGeneralAssumptionsCallAPI(Number(dealIdToUse)).then((response) => {
      if (Array.isArray(response)) {
        setGeneralAssumption(response[0]);
      }
    });
  }, [dealIdToUse]);

  const isOnCase = React.useMemo(() => {
    return dealId !== caseDealId;
  }, [dealId, caseDealId]);

  const {
    callAPI: getGeneralAssumptionsCallAPI,
    errored: getGeneralAssumptionsErrored,
    loading: loadinngGetGeneralAssumption,
  } = useAPI((dealId: number) => getGeneralAssumption(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: addGeneralAssumptionCallAPI,
    fieldErrors: addGeneralAssumptionFieldErrors,
    setFieldErrors: setAddGeneralAssumptionFieldErrors,
    loading: addGeneralAssumptionsLoading,
  } = useAPI<IGeneralAssumptionResponse, IGeneralAssumptionsFormErrors>(
    (dealId: number, form: IGeneralAssumptionsForm) =>
      addGeneralAssumption(dealId, form),
  );

  const handleGetCurves = async () => {
    const curves = await getCurves({ curve_type: ["IRC"] }).catch(() => null);
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));
  };

  const ircCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves.map((c) => ({
      label: c.name,
      value: String(c.id),
    }));
  }, [currentOrgCurves]);

  const {
    callAPI: updateGeneralAssumptionCallAPI,
    fieldErrors: updateGeneralAssumptionFormErrors,
    setFieldErrors: setUpdateGeneralAssumptionFormErrors,
    loading: updateGeneralAssumptionsLoading,
  } = useAPI<IGeneralAssumptionResponse, IGeneralAssumptionsFormErrors>(
    (dealId: number, dealTermDebtId: number, form: IGeneralAssumptionsForm) =>
      updateGeneralAssumption(dealId, dealTermDebtId, form),
  );

  const handleOnAddGeneralAssumption = async (
    form: IGeneralAssumptionsForm,
  ) => {
    const generalAssumption = await addGeneralAssumptionCallAPI(
      Number(caseDealId),
      form,
    );

    if (generalAssumption) {
      setGeneralAssumption(generalAssumption);
    }

    return generalAssumption;
  };

  const handleOnEditGeneralAssumption = async (
    form: IGeneralAssumptionsForm,
  ) => {
    const updatedGeneralAssumption = await updateGeneralAssumptionCallAPI(
      Number(caseDealId),
      generalAssumption?.id,
      form,
    );
    updatedGeneralAssumption && setGeneralAssumption(updatedGeneralAssumption);

    return updatedGeneralAssumption;
  };

  const handleOpenAddGeneralAssumptionsModal = async () => {
    await handleGetCurves();
    setAddGeneralAssumptionsModalOpen(true);
  };

  const handleCloseAddGeneralAssumptionsModal = () => {
    setAddGeneralAssumptionsModalOpen(false);
  };

  const handleOpenEditGeneralAssumptionsModal = async () => {
    await handleGetCurves();
    const {
      debt_closing_date_type,
      debt_closing_date_point,
      solve_iteratively,
      debt_closing_date,
      equity_letter_of_credit_fee,
      funding_methodology,
      loc_fee,
      loc_for_construction_credit_support,
      min_equity_during_construction,
      sofr_org_curve,
      admin_agent_fees,
    } = generalAssumption!;
    setForm({
      debt_closing_date_type,
      debt_closing_date_point,
      solve_iteratively,
      debt_closing_date,
      equity_letter_of_credit_fee,
      funding_methodology,
      loc_fee,
      loc_for_construction_credit_support: loc_for_construction_credit_support
        ? "YES"
        : "NO",
      min_equity_during_construction,
      sofr_org_curve: sofr_org_curve,
      admin_agent_fees,
    });
    setEditGeneralAssumptionsModalOpen(true);
  };

  const handleCloseEditGeneralAssumptionsModal = () => {
    setEditGeneralAssumptionsModalOpen(false);
  };

  const generalAssumptionCardData = React.useMemo(() => {
    if (!generalAssumption) return [];
    return [
      {
        label: "Date Selection Type",
        value: {
          text: DATE_SELECTION_TYPE_CHOICES[
            generalAssumption.debt_closing_date_type as keyof typeof DATE_SELECTION_TYPE_CHOICES
          ],
        },
      },
      ...(generalAssumption.debt_closing_date_type === "MD"
        ? [
            {
              label: "Debt Closing Date Point",
              value: {
                text: CLOSING_DATE_POINT_CHOICES[
                  generalAssumption.debt_closing_date_point as keyof typeof CLOSING_DATE_POINT_CHOICES
                ],
              },
            },
          ]
        : []),
      {
        label: "Debt Closing Date",
        value: {
          text: generalAssumption.debt_closing_date_calculated ?? "N/A",
        },
      },
      {
        label: "Funding Methodology",
        value: {
          text: FUNDING_METHODOLOGY_CHOICES[
            generalAssumption.funding_methodology as keyof typeof FUNDING_METHODOLOGY_CHOICES
          ],
        },
      },
      {
        label: "Minimum Equity During Construction",
        value: {
          text:
            generalAssumption.min_equity_during_construction?.toString() + "%",
        },
      },
      ...(generalAssumption.funding_methodology === "DF"
        ? [
            {
              label: "Equity Letter of Credit Fee",
              value: {
                text:
                  generalAssumption.equity_letter_of_credit_fee.toString() +
                  "%",
              },
            },
          ]
        : []),
      {
        label: "Letter of Credit for Construction Credit Support",
        value: {
          text: generalAssumption?.loc_for_construction_credit_support
            ? "Yes"
            : "No",
        },
      },
      ...(generalAssumption.loc_for_construction_credit_support
        ? [
            {
              label: "Letter of Credit Fee",
              value: {
                text: generalAssumption.loc_fee?.toString() + "%",
              },
            },
          ]
        : []),
      ...(generalAssumption?.sofr_org_curve_detail
        ? [
            {
              label: "Interest Rate Curve",
              value: {
                text: (
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() =>
                      window.open(
                        `/configuration/data/curves/${generalAssumption.sofr_org_curve_detail.curve_group}/${generalAssumption.sofr_org_curve_detail.id}`,
                      )
                    }
                  >
                    {generalAssumption?.sofr_org_curve_detail?.name}
                    <OpenInNewIcon fontSize="small" />
                  </Link>
                ),
              },
            },
          ]
        : []),
      {
        label: "Admin Agent Fees",
        value: { text: numberToUSD.format(generalAssumption.admin_agent_fees) },
      },
      {
        label:
          "Iteratively update Deal FMV to solve for IDC and Construction Fees",
        value: {
          text: generalAssumption.solve_iteratively ? "Yes" : "No",
        },
      },
    ];
  }, [generalAssumption]);

  const constructionDebtLogConfiguration: ILogsConfiguration = {
    id: Number(generalAssumption?.id),
    type: "dealconstructiondebtgeneralsizing",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      constructionDebtLogConfiguration.type,
      constructionDebtLogConfiguration.id,
    );
  };

  return (
    <ViewWrapper
      loading={loadinngGetGeneralAssumption}
      error={getGeneralAssumptionsErrored}
    >
      {!generalAssumption && (
        <Box
          data-pw="empty-general-assumptions"
          className={styles.classes.emptyContainer}
        >
          <Box>
            {!isOnCase && (
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add General Assumptions"
                  onClick={handleOpenAddGeneralAssumptionsModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>
            )}
            <Typography variant="body1" className={styles.classes.createInfo}>
              General Assumptions on this Deal does not exist.
              {!isOnCase &&
                ` Please click "Add General Assumptions" to add one`}
            </Typography>
          </Box>
        </Box>
      )}

      {generalAssumption && (
        <React.Fragment>
          <Box className={cn("flex justify-end items-center gap-2 mb-4")}>
            <LogsButton onClick={handleOnOpenLogs} />

            <ToggleSizingOutputButton />
          </Box>
          <Box className={cn("flex flex-col gap-4")}>
            <Box
              data-pw="general-assumptions-summary"
              className={cn("grid grid-cols-2")}
            >
              <DetailsCard
                dataPw="general-assumptions-summary-card"
                heading="General Assumptions"
                sections={[
                  {
                    fields: generalAssumptionCardData,
                  },
                ]}
                onEditClick={
                  !isOnCase ? handleOpenEditGeneralAssumptionsModal : undefined
                }
              />
            </Box>
            <Box className={cn("grid grid-cols-3 gap-4")}>
              <ConstructionLoanDebt />
              <InvestorBridgeLoan />
              {/* If transfer or direct pay only then show the transfer bridge loan */}
              {currentDeal &&
                ["TR", "DP"].includes(currentDeal.tax_credit_structure) && (
                  <TransferBridgeLoan />
                )}
            </Box>
          </Box>
        </React.Fragment>
      )}

      <GeneralAssumptionFormModal
        open={addGeneralAssumptionsModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Add General Assumptions"
        loading={addGeneralAssumptionsLoading}
        onClose={handleCloseAddGeneralAssumptionsModal}
        onConfirm={handleOnAddGeneralAssumption}
        setFormErrors={setAddGeneralAssumptionFieldErrors}
        formErrors={addGeneralAssumptionFieldErrors}
        ircCurves={ircCurvesOptions}
      />

      <GeneralAssumptionFormModal
        open={editGeneralAssumptionsModalOpen}
        form={form}
        setForm={setForm}
        headerLabel="Edit General Assumptions"
        loading={updateGeneralAssumptionsLoading}
        onClose={handleCloseEditGeneralAssumptionsModal}
        onConfirm={handleOnEditGeneralAssumption}
        setFormErrors={setUpdateGeneralAssumptionFormErrors}
        formErrors={updateGeneralAssumptionFormErrors}
        ircCurves={ircCurvesOptions}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={constructionDebtLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination?.totalItems}
          id={constructionDebtLogConfiguration.id}
        />
      </LogsWrapper>
    </ViewWrapper>
  );
}
