import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import { useAPI } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { ITableRow, IDeveloperReportSummary } from "../../../../../interfaces";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../../../../constants";
import {
  cn,
  generateDeveloperReportSummary,
  getDeveloperReportSummaryUrl,
} from "../../../../../utils/helpers";

interface IProps {
  getDeveloperReportSummary: (
    dealId: number,
  ) => Promise<IDeveloperReportSummary>;
}

export default function DealOutputDeveloperSummaryView({
  getDeveloperReportSummary,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { caseDealId, dealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const [developerReportSummary, setDeveloperReportSummary] =
    React.useState<IDeveloperReportSummary>();

  const {
    callAPI: getDeveloperReportSummaryCallAPI,
    errored: getDeveloperReportSummaryFailed,
    loading: loadingDeveloperReportSummary,
    errorObj: getDeveloperReportSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI((dealId: number) => getDeveloperReportSummary(dealId), {
    initialLoading: true,
  });

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDeveloperReportSummaryCallAPI(Number(dealIdToUse)).then((response) => {
        if (response) {
          setDeveloperReportSummary(response);
        }
      });
    }
  }, [dealIdToUse]);

  React.useEffect(() => {
    !loadingDeveloperReportSummary &&
      dispatch(updateDealOutputLoadedAction(!getDeveloperReportSummaryFailed));
  }, [getDeveloperReportSummaryFailed, loadingDeveloperReportSummary]);

  const summaryTableRows: ITableRow[] | null = React.useMemo(() => {
    if (developerReportSummary) {
      return generateDeveloperReportSummary(developerReportSummary);
    }
    return [];
  }, [developerReportSummary]);

  return (
    <ViewWrapper
      loading={loadingDeveloperReportSummary}
      error={getDeveloperReportSummaryFailed}
      errorHeading={getDeveloperReportSummaryErrorObj?.heading}
      errorDescription={getDeveloperReportSummaryErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[getDeveloperReportSummaryUrl(Number(dealIdToUse))]
      }
    >
      <Box className={cn("mt-4 flex justify-between gap-4")}>
        <Box className={cn("w-[40%] min-w-[400px]")}>
          <ReportTable
            columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
            rows={summaryTableRows}
            fullWidth
          />
        </Box>
      </Box>
    </ViewWrapper>
  );
}
