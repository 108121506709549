/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import SwapIcon from "@mui/icons-material/SwapHoriz";
import BulbIcon from "@mui/icons-material/Lightbulb";
import DoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MuiButton from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { format } from "date-fns";
import { isNumber } from "lodash";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyles from "./styles";
import Tabs from "../../../../components/tabs";
import ViewWrapper from "../../../../components/view-wrapper";
import DetailsCard from "../../../../components/details-card";
import DealTermDebtFormModal from "../../../../components/deal-debt-form-modal";
import DealFeeFormModal from "../../../../components/deal-fee-form-modal";
import ProjectRevenueContractFormModal from "../../../../components/project-revenue-contract-form-modal";
import ProjectMerchantRevenueFormModal from "../../../../components/project-merchant-revenue-form-modal";
import ProjectExpensesFormModal from "../../../../components/project-expenses-form-modal";
import ProjectTimingFormModal from "../../../../components/project-timing-form-modal";
import ProjectCostFormModal from "../../../../components/project-cost-form-modal";
import ProjectProductionFormModal from "../../../../components/project-production-form-modal";
import ProjectTaxCreditFormModal from "../../../../components/project-tax-credit-form-modal";
import DealPartnershipInvestorConfigurationFormModal from "../../../../components/deal-tax-equity-form-modal";
import DealDepreciationElectionEditModal from "../../../../components/deal-depreciation-election-edit-form-modal";
import EditTaxCreditTransfer from "../../../../components/edit-tax-credit-transfer-form-modal";
import EditTaxCreditDirectPay from "../../../../components/edit-tax-credit-direct-form-modal";
import UpdateDealOtherSizingFormModal from "../../../../components/update-deal-other-sizing-form-modal";
import PopoverMenu from "../../../../components/popover-menu";
import DealTimingFormModal from "../../../../components/deal-timing-form-modal";
import ProjectEBITDAFormModal from "../../../../components/project-EBITDA-form-modal";
import EditDealTaxCreditTransfer from "../../../../components/edit-deal-tax-credit-transfer-form-modal";
import DealSponsorEquityFormModal from "../../../../components/deal-sponsor-equity-form-modal";
import ProjectCosts from "../../../project/cost/cost-inputs/project-costs";
import CostItemFormModal from "../../../../components/cost-item-form-modal";
import ProjectOtherFlowFormModal from "../../../../components/other-flow-form-modal";
import ConstructionLoanModal from "../../../../components/construction-loan-form-modal";
import TransferBridgeFormModal from "../../../../components/transfer-bridge-form-modal";
import ProjectCreditSupportFormModal from "../../../../components/project-credit-support-form-modal";
import GeneralAssumptionFormModal from "../../../../components/general-assumptions-form-modal";
import InvestorBridgeFormModal from "../../../../components/investor-bridge-form-modal";
import ConditionalProtect from "../../../../components/conditional-protect";
import CaseDetailsTable from "./Table";
import { getProjectTimingDateSchedule } from "../../../../apis/project/timing";
import ProjectDepreciationStreamFormModal from "../../../../components/project-depreciation-stream-form-modal";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import {
  setCurrentOrgCurvesAction,
  setDeleteModalPropsAction,
} from "../../../../utils/redux/slices";
import {
  CONSTRUCTION_DEBT_BASE_RATE_TYPE,
  CONSTRUCTION_LOAN_FORM_DEFAULT_STATE,
  GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE,
  INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  TRANSFER_BRIDGE_LOAN_FORM_DEFAULT_STATE,
  DEAL_DEPRECIATION_ELECTION,
  DEAL_OTHER_SIZING,
  PROJECT_EXPENSE_TYPE,
  PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT,
  PROJECT_OTHER_STREAM_TYPE,
  PROJECT_CONTRACTED_REVENUE_TYPE,
  PROJECT_TIMING_HORIZON_FIELD_MAPPING,
  DEAL_TERM_DEBT_FORM_DEFAULT_STATE,
  UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE,
  DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
  DEAL_TAX_EQUITY_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
  PROJECT_REVENUE_FORM_DEFAULT_STATE,
  PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
  PROJECT_EXPENSE_FORM_DEFAULT_STATE,
  UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
  UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE,
  UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
  DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE,
  UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  DEAL_FEE_FORM_DEFAULT_STATE,
  PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
  PROJECT_OBLICATION_TYPE,
  PROJECT_CREDIT_SUPPORT_COST_METHOD,
  COST_ITEM_FORM_DEFAULT_STATE,
  DEAL_FEE_TYPES,
  DEAL_FEE_ALLOCATION_METHODS,
  DATE_SELECTION_TYPES,
  PROJECT_DEPRECIATION_STREAM_TYPE,
  DEFAULT_PROJECT_DEPRECIATION_STREAM_FORM,
  DEAL_FEES_TABLE_COLUMN_HEADERS,
  DEAL_CREDIT_SUPPORTS_TABLE_COLUMN_HEADERS,
  DEAL_DEPRECIATION_STREAMS_TABLE_COLUMN_HEADERS,
  DEAL_REVENUES_TABLE_COLUMN_HEADERS,
  DEAL_EXPENSES_TABLE_COLUMN_HEADERS,
  DEAL_OTHER_FLOWS_TABLE_COLUMN_HEADERS,
  DEAL_MERCHANT_REVENUE_TABLE_COLUMN_HEADERS,
  PROJECT_MERCHANT_REVENUE_TYPE,
} from "../../../../constants";
import {
  IDealCase,
  IDealDebt,
  IUpdateDealDepreciationElectionForm,
  IUpdateDealDepreciationElectionFormErrors,
  IDealFat,
  IDealOtherSizing,
  IDealTaxCreditTransferForm,
  IDealTaxCreditTransferFormErrors,
  IDealTaxCreditTransferResults,
  IDealTiming,
  IDealTermDebtForm,
  IDealTermDebtFormErrors,
  IDealTaxEquityForm,
  IDealTaxEquityFormErrors,
  IProjectRevenueForm,
  IProjectRevenueFormErrors,
  IUpdateProjectTimingForm,
  IUpdateProjectTimingFormErrors,
  IFatDealProjectTransfer,
  IDealTaxEquity,
  IProjectCost,
  IProjectDetailsFat,
  IProjectExpense,
  IProjectMerchantRevenue,
  IProjectProduction,
  IProjectRevenue,
  IProjectEBITDA,
  IProjectTaxCredit,
  IProjectTiming,
  ITab,
  IUpdateDealDepreciationResponse,
  IUpdateDealTimingForm,
  IUpdateDealTimingFormErrors,
  IUpdateDealOtherSizingForm,
  IUpdateDealOtherSizingFormErrors,
  IUpdateProjectCostFormErrors,
  IProjectExpenseForm,
  IProjectExpenseFormErrors,
  IProjectMerchantRevenueForm,
  IProjectMerchantRevenueFormErrors,
  IUpdateProjectProductionForm,
  IUpdateProjectProductionFormErrors,
  IProjectEBITDA_Form,
  IProjectEBITDA_FormErrors,
  IUpdateProjectTaxCreditForm,
  IUpdateProjectTaxCreditFormErrors,
  IDealSponsorEquityForm,
  IDealSponsorEquityFormErrors,
  IDealSponsorEquity,
  ISelectOption,
  IGetCurvesParams,
  ServerPaginatedResponse,
  IOrganizationCurve,
  IProjectOtherFlow,
  IProjectOtherFlowForm,
  IProjectOtherFlowFormErrors,
  IDealFee,
  IDealFeeForm,
  IDealFeeFormErrors,
  IProjectCostForm,
  IProjectCreditSupport,
  IAddProjectCreditSupportFormErrors,
  IAddProjectCreditSupportForm,
  IUpdateProjectCreditSupportFormErrors,
  IUpdateProjectCreditSupportForm,
  ICostItemResponse,
  ICostItemFormErrors,
  ICostItemForm,
  IProjectTimingDateScheduleResults,
  IDealTaxCreditTransferPortfolio,
  IDealTaxCreditTransferPortfolioForm,
  IDealTaxCreditTransferPortfolioFormErrors,
  IProjectDepreciationStream,
  IUpdateProjectDepreciationStreamForm,
  IAddProjectDepreciationStreamForm,
  IUpdateProjectDepreciationStreamFormErrors,
  IAddProjectDepreciationStreamFormErrors,
  IConstructionLoanForm,
  IConstructionLoanFormErrors,
  IConstructionLoanResponse,
  IGeneralAssumptionResponse,
  IGeneralAssumptionsForm,
  IGeneralAssumptionsFormErrors,
  IInvestorBridgeLoanForm,
  IInvestorBridgeLoanFormErrors,
  IInvestorBridgeLoanResponse,
  ITransferBridgeLoanForm,
  ITransferBridgeLoanFormErrors,
  ITransferBridgeLoanResponse,
} from "../../../../interfaces";
import {
  cn,
  formatNumberForView,
  getCaseDealBlockDetails,
  getCaseProjectBlockDetails,
  getProjectSeasonalityAdjustmentFactors,
  getTableColumnAccordingToStatus,
  numberToUSD,
  numberWithCommas,
  trimString,
} from "../../../../utils/helpers";

interface IProps {
  getDealCaseDetails: (dealId: number, caseId: number) => Promise<IDealCase>;
  getDealDetailsFat: (dealId: number) => Promise<IDealFat>;
  getProjectDetailsFat: (projectId: number) => Promise<IProjectDetailsFat>;
  deleteDealDebt: (dealId: number, taxEquityId: number) => Promise<boolean>;
  addDealDebt: (dealId: number, form: IDealTermDebtForm) => Promise<IDealDebt>;
  updateDealDebt: (
    dealId: number,
    taxEquityId: number,
    form: IDealTermDebtForm,
  ) => Promise<IDealDebt>;
  editDealDepreciation: (
    dealId: number,
    form: IUpdateDealDepreciationElectionForm,
  ) => Promise<IUpdateDealDepreciationResponse>;
  updateDealTaxCreditTransferDetails: (
    dealId: number,
    transferSizingId: number,
    form: IDealTaxCreditTransferForm,
  ) => Promise<IDealTaxCreditTransferResults>;
  updateDealTaxEquity: (
    dealId: number,
    taxEquityId: number,
    form: IDealTaxEquityForm,
  ) => Promise<IDealTaxEquity>;
  updateDealTiming: (
    dealId: number,
    timingId: number,
    form: IUpdateDealTimingForm,
  ) => Promise<IDealTiming>;
  addProjectRevenueContract: (
    projectId: number,
    form: IProjectRevenueForm,
  ) => Promise<IProjectRevenue>;
  editProjectRevenueContract: (
    projectId: number,
    revenueContractId: number,
    form: IProjectRevenueForm,
  ) => Promise<IProjectRevenue>;
  deleteProjectRevenueContract: (
    projectId: number,
    revenueContractId: number,
  ) => Promise<boolean>;
  addMerchantRevenue: (
    projectId: number,
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue>;
  updateMerchantRevenue: (
    projectId: number,
    revenueId: number,
    form: IProjectMerchantRevenueForm,
  ) => Promise<IProjectMerchantRevenue>;
  deleteProjectMerchantRevenueContract: (
    projectId: number,
    revenueContractId: number,
  ) => Promise<boolean>;
  addProjectExpense: (
    projectId: number,
    form: IProjectExpenseForm,
  ) => Promise<IProjectExpense>;
  updateProjectExpense: (
    projectId: number,
    expenseId: number,
    form: IProjectExpenseForm,
  ) => Promise<IProjectExpense>;
  deleteExpense: (projectId: number, expenseId: number) => Promise<boolean>;
  editProjectTiming: (
    projectId: number,
    projectTimingId: number,
    form: IUpdateProjectTimingForm,
  ) => Promise<IProjectTiming>;
  updateProjectProduction: (
    id: number,
    productionId: number,
    form: IUpdateProjectProductionForm,
  ) => Promise<IProjectProduction>;
  updateProjectTaxCredit: (
    projectId: number,
    taxCreditId: number,
    form: IUpdateProjectTaxCreditForm,
  ) => Promise<IProjectTaxCredit>;
  updateProjectEBITDA: (
    projectId: number,
    scheduleOverrideId: number,
    form: IProjectEBITDA_Form,
  ) => Promise<IProjectEBITDA>;
  getDealOtherSizing: (dealId: number) => Promise<IDealOtherSizing[]>;
  updateDealOtherSizing: (
    dealId: number,
    sizingId: number,
    form: IUpdateDealOtherSizingForm,
  ) => Promise<IDealOtherSizing>;
  updateDealSponsorEquity: (
    dealId: number,
    taxEquityId: number,
    form: IDealSponsorEquityForm,
  ) => Promise<IDealSponsorEquity>;
  getCurves: (
    params: IGetCurvesParams,
  ) => Promise<ServerPaginatedResponse<IOrganizationCurve[]>>;
  addProjectOtherFlow: (
    projectId: number,
    form: IProjectOtherFlowForm,
  ) => Promise<IProjectOtherFlow>;
  editProjectOtherFlow: (
    projectId: number,
    id: number,
    form: IProjectOtherFlowForm,
  ) => Promise<IProjectOtherFlow>;
  deleteProjectOtherFlow: (projectId: number, id: number) => Promise<boolean>;
  addDealFee: (dealId: number, form: IDealFeeForm) => Promise<IDealFee>;
  updateDealFee: (
    dealId: number,
    feeId: number,
    form: IDealFeeForm,
  ) => Promise<IDealFee>;
  deleteDealFee: (dealId: number, feeId: number) => Promise<boolean>;
  updateProjectCosts: (
    form: { profit_percent: number },
    projectId: number,
    costId: number,
  ) => Promise<any>;
  deleteProjectCreditSupport: (
    projectId: number,
    expenseId: number,
  ) => Promise<boolean>;
  addProjectCreditSupport: (
    projectId: number,
    form: IAddProjectCreditSupportForm,
  ) => Promise<IProjectCreditSupport>;
  editProjectCreditSupport: (
    projectId: number,
    expenseId: number,
    form: IAddProjectCreditSupportForm,
  ) => Promise<IProjectCreditSupport>;
  createProjectCostItem: (
    form: ICostItemForm,
    projectId: number,
    costId: number,
  ) => Promise<ICostItemResponse>;
  addGeneralAssumption: (
    id: number,
    form: IGeneralAssumptionsForm,
  ) => Promise<IGeneralAssumptionResponse>;
  getGeneralAssumption: (id: number) => Promise<IGeneralAssumptionResponse[]>;
  updateGeneralAssumption: (
    id: number,
    debtId: number,
    form: IGeneralAssumptionsForm,
  ) => Promise<IGeneralAssumptionResponse>;
  addConstructionLoan: (
    dealId: number,
    form: IConstructionLoanForm,
  ) => Promise<IConstructionLoanResponse>;
  addTransferLoan: (
    dealId: number,
    form: ITransferBridgeLoanForm,
  ) => Promise<ITransferBridgeLoanResponse>;
  deleteConstructionLoan: (id: number, debtId: number) => Promise<boolean>;
  deleteTransferLoan: (id: number, debtId: number) => Promise<boolean>;
  updateConstructionLoan: (
    id: number,
    debtId: number,
    form: IConstructionLoanForm,
  ) => Promise<IConstructionLoanResponse>;
  updateTransferLoan: (
    id: number,
    debtId: number,
    form: ITransferBridgeLoanForm,
  ) => Promise<ITransferBridgeLoanResponse>;
  addInvestorLoan: (
    id: number,
    form: IInvestorBridgeLoanForm,
  ) => Promise<IInvestorBridgeLoanResponse>;
  updateInvestorLoan: (
    id: number,
    debtId: number,
    form: IInvestorBridgeLoanForm,
  ) => Promise<IInvestorBridgeLoanResponse>;
  deleteInvestorLoan: (id: number, debtId: number) => Promise<boolean>;
  updateDealTransferPortfolio: (
    dealId: number,
    transferConfigId: number,
    form: IDealTaxCreditTransferPortfolioForm,
  ) => Promise<IDealTaxCreditTransferPortfolio>;
  addProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    form: IAddProjectDepreciationStreamForm,
  ) => Promise<IProjectDepreciationStream>;
  updateProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
    form: IUpdateProjectDepreciationStreamForm,
  ) => Promise<IProjectDepreciationStream>;
  deleteProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
  ) => Promise<boolean>;
}

export default function DealCaseDetailsView({
  getDealCaseDetails,
  getDealDetailsFat,
  getProjectDetailsFat,
  updateDealTaxEquity,
  updateDealTaxCreditTransferDetails,
  addDealDebt,
  deleteDealDebt,
  updateDealDebt,
  editDealDepreciation,
  updateDealTiming,
  addProjectRevenueContract,
  editProjectRevenueContract,
  deleteProjectRevenueContract,
  addMerchantRevenue,
  updateMerchantRevenue,
  deleteProjectMerchantRevenueContract,
  addProjectExpense,
  updateProjectExpense,
  deleteExpense,
  editProjectTiming,
  updateProjectProduction,
  updateProjectTaxCredit,
  updateProjectEBITDA,
  getDealOtherSizing,
  updateDealOtherSizing,
  updateDealSponsorEquity,
  getCurves,
  addProjectOtherFlow,
  editProjectOtherFlow,
  deleteProjectOtherFlow,
  addDealFee,
  updateDealFee,
  deleteDealFee,
  updateProjectCosts,
  deleteProjectCreditSupport,
  addProjectCreditSupport,
  editProjectCreditSupport,
  createProjectCostItem,
  addGeneralAssumption,
  updateGeneralAssumption,
  addConstructionLoan,
  addTransferLoan,
  deleteConstructionLoan,
  deleteTransferLoan,
  updateConstructionLoan,
  updateTransferLoan,
  addInvestorLoan,
  updateInvestorLoan,
  deleteInvestorLoan,
  updateDealTransferPortfolio,
  addProjectDepreciationStream,
  deleteProjectDepreciationStream,
  updateProjectDepreciationStream,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId, caseId } = useParams();

  const dispatch = useDispatch();
  const { currentOrgCurves } = useAppSelector((s) => s.org);

  const [degradationCurveToggle, setDegradationCurveToggle] =
    React.useState<boolean>(false);
  const [availabilityCurveToggle, setAvailabilityCurveToggle] =
    React.useState<boolean>(false);
  const [curtailmentCurveToggle, setCurtailmentCurveToggle] =
    React.useState<boolean>(false);
  const [lossCurveToggle, setLossCurveToggle] = React.useState<boolean>(false);
  const [selectedProject, setSelectedProject] = React.useState<number>(0);
  const [visibleTaxCreditProjects, setVisibleTaxCreditProjects] =
    React.useState<number>(3);

  const [addDealDebtModalOpen, setAddDealDebtModalOpen] =
    React.useState<boolean>(false);
  const [editDealDebtModalOpen, setEditDealDebtModalOpen] =
    React.useState<boolean>(false);
  const [dealTermDebtForm, setDealTermDebtForm] =
    React.useState<IDealTermDebtForm>(DEAL_TERM_DEBT_FORM_DEFAULT_STATE);
  const [editDepreciationModalOpen, setEditDepreciationModalOpen] =
    React.useState<boolean>(false);
  const [dealDepreciationForm, setDealDepreciationForm] =
    React.useState<IUpdateDealDepreciationElectionForm>(
      UPDATE_DEAL_DEPRECIATION_ELECTION_FORM_DEFAULT_STATE,
    );
  const [editDealTaxCreditModal, setEditDealTaxCreditModal] =
    React.useState<boolean>(false);
  const [dealTaxCreditForm, setDealTaxCreditForm] =
    React.useState<IDealTaxCreditTransferForm>(
      DEAL_TAX_CREDIT_TRANSFER_FORM_DEFAULT_STATE,
    );
  const [editDealTaxEquityModalOpen, setEditDealTaxEquityModalOpen] =
    React.useState<boolean>(false);
  const [dealTaxEquityForm, setDealTaxEquityForm] =
    React.useState<IDealTaxEquityForm>(DEAL_TAX_EQUITY_FORM_DEFAULT_STATE);
  const [updateDealTimingModalOpen, setUpdateDealTimingModalOpen] =
    React.useState<boolean>(false);
  const [updateDealTimingForm, setUpdateDealTimingForm] =
    React.useState<IUpdateDealTimingForm>(
      UPDATE_DEAL_TIMING_FORM_DEFAULT_STATE,
    );
  const [
    updateDealSponsorEquityModalOpen,
    setUpdateDealSponsorEquityModalOpen,
  ] = React.useState<boolean>(false);
  const [updateDealSponsorEquityForm, setUpdateDealSponsorEquityForm] =
    React.useState<IDealSponsorEquityForm>(
      DEAL_SPONSOR_EQUITY_FORM_DEFAULT_STATE,
    );
  const [
    updateDealTaxCreditTranferConstraintsModalOpen,
    setUpdateDealTaxCreditTranferConstraintsModalOpen,
  ] = React.useState<boolean>(false);
  const [
    dealTaxCreditTranferConstraintsForm,
    setDealTaxCreditTranferConstraintsForm,
  ] = React.useState<IDealTaxCreditTransferPortfolioForm>(
    UPDATE_DEAL_TAX_CREDIT_TRANSFER_CONSTRAINTS_FORM_DEFAULT_STATE,
  );
  const [selectedDealFeeToEdit, setSelectedDealFeeToEdit] =
    React.useState<number>();
  const [addDealFeeModalOpen, setAddDealFeeModalOpen] =
    React.useState<boolean>(false);
  const [updateDealFeeModalOpen, setUpdateDealFeeModalOpen] =
    React.useState<boolean>(false);
  const [dealFeeForm, setDealFeeForm] = React.useState<IDealFeeForm>(
    DEAL_FEE_FORM_DEFAULT_STATE,
  );
  const [dealOtherSizing, setDealOtherSizing] =
    React.useState<IDealOtherSizing>();
  const [updateDealOtherSizingModalOpen, setUpdateDealOtherSizingModalOpen] =
    React.useState<boolean>(false);
  const [updateDealSizingForm, setUpdateDealSizingForm] =
    React.useState<IUpdateDealOtherSizingForm>({
      id: null,
      expense_allocation_method: "",
    });

  const [addRevenueContractModalOpen, setAddRevenueContractModalOpen] =
    React.useState<boolean>(false);
  const [
    editProjectRevenueContractModalOpen,
    setProjectEditRevenueContractModalOpen,
  ] = React.useState<boolean>(false);
  const [selectedProjectRevenueToEdit, setSelectedProjectRevenueToEdit] =
    React.useState<number>();
  const [projectRevenueForm, setProjectRevenueForm] =
    React.useState<IProjectRevenueForm>(PROJECT_REVENUE_FORM_DEFAULT_STATE);
  const [addMerchantRevenueModalOpen, setAddMerchantRevenueModalOpen] =
    React.useState<boolean>(false);
  const [updateMerchantRevenueModalOpen, setUpdateMerchantRevenueModalOpen] =
    React.useState<boolean>(false);
  const [selectedMerchantRevenueToEdit, setSelectedMerchantRevenueToEdit] =
    React.useState<number>();
  const [merchantRevenueForm, setMerchantRevenueForm] =
    React.useState<IProjectMerchantRevenueForm>(
      PROJECT_MERCHANT_REVENUE_FORM_DEFAULT_STATE,
    );
  const [addProjectExpenseModalOpen, setAddProjectExpenseModalOpen] =
    React.useState<boolean>(false);
  const [updateProjectExpenseModalOpen, setUpdateProjectExpenseModalOpen] =
    React.useState<boolean>(false);
  const [selectedProjectExpenseToEdit, setSelectedProjectExpenseToEdit] =
    React.useState<number>();
  const [projectExpenseForm, setProjectExpenseForm] =
    React.useState<IProjectExpenseForm>(PROJECT_EXPENSE_FORM_DEFAULT_STATE);
  const [updateEBITDAModalOpen, setUpdateEBITDAModalOpen] =
    React.useState<boolean>(false);
  const [projectEBITDAForm, setProjectEBITDAForm] =
    React.useState<IProjectEBITDA_Form>({
      ebitda: [],
    });
  const [editProjectTimingModalOpen, setEditProjectTimingModalOpen] =
    React.useState<boolean>(false);
  const [projectTimingForm, setProjectTimingForm] =
    React.useState<IUpdateProjectTimingForm>(
      UPDATE_PROJECT_TIMING_FORM_DEFAULT_STATE,
    );
  const [updateProjectCostModalOpen, setUpdateProjectCostModalOpen] =
    React.useState<boolean>(false);
  const [projectCostForm, setProjectCostForm] =
    React.useState<IProjectCostForm>({
      fmv_step_up_input_type: "",
      profit_percent: "",
      fmv_step_up: "",
    });
  const [
    updateProjectProductionModalOpen,
    setUpdateProjectProductionModalOpen,
  ] = React.useState<boolean>(false);
  const [projectProductionForm, setProjectProductionForm] =
    React.useState<IUpdateProjectProductionForm>(
      UPDATE_PROJECT_PRODUCTION_FORM_DEFAULT_STATE,
    );
  const [updateProjectTaxCreditModalOpen, setUpdateProjectTaxCreditModalOpen] =
    React.useState<boolean>(false);
  const [projectTaxCreditForm, setProjectTaxCreditForm] =
    React.useState<IUpdateProjectTaxCreditForm>(
      UPDATE_PROJECT_TAX_CREDIT_FORM_DEFAULT_STATE,
    );
  const [addProjectOtherFlowModalOpen, setAddProjectStreamModalOpen] =
    React.useState<boolean>(false);
  const [updateProjectOtherFlowModalOpen, setUpdateProjectOtherFlowModalOpen] =
    React.useState<boolean>(false);
  const [selectedProjectOtherFlow, setSelectedProjectOtherFlow] =
    React.useState<number>();
  const [projectOtherFlowForm, setProjectOtherFlowForm] =
    React.useState<IProjectOtherFlowForm>(
      PROJECT_OTHER_FLOW_FORM_DEFAULT_STATE,
    );

  const [addCreditSupportModalOpen, setAddCreditSupportModalOpen] =
    React.useState<boolean>(false);
  const [updateCreditSupportModalOpen, setUpdateCreditSupportModalOpen] =
    React.useState<boolean>(false);
  const [selectedCreditSupportToEdit, setSelectedCreditSupportToEdit] =
    React.useState<number>();

  const [creditSupportForm, setCreditSupportForm] = React.useState<
    IAddProjectCreditSupportForm | IUpdateProjectCreditSupportForm
  >({
    obligation_type: "",
    name: "",
    credit_support_method: "",
    input_type: "",
    credit_support_amount: "",
    start_date_type: "",
    credit_support_start_date: null,
    credit_support_start_point: "",
    end_date_type: "",
    credit_support_end_date: null,
    credit_support_end_point: "",
    letter_of_credit_fee: "",
    yearfrac_convention: "",
  });

  const [addTransferBridgeLoanModalOpen, setAddTransferBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [editTransferBridgeLoanModalOpen, setEditTransferBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [tranferBridgeForm, setTranferBridgeForm] =
    React.useState<ITransferBridgeLoanForm>(
      TRANSFER_BRIDGE_LOAN_FORM_DEFAULT_STATE,
    );
  const [generalAssumptionForm, setGeneralAssumptionForm] =
    React.useState<IGeneralAssumptionsForm>(
      GENERAL_ASSUMPTIONS_FORM_DEFAULT_STATE,
    );
  const [constructionLoan, setConstructionLoan] =
    React.useState<IConstructionLoanResponse | null>(null);
  const [addConstructionLoanModalOpen, setAddConstructionLoanModalOpen] =
    React.useState<boolean>(false);
  const [editConstructionLoanModalOpen, setEditConstructionLoanModalOpen] =
    React.useState<boolean>(false);
  const [consstructionLoanForm, setConsstructionLoanForm] =
    React.useState<IConstructionLoanForm>(CONSTRUCTION_LOAN_FORM_DEFAULT_STATE);

  React.useState<IInvestorBridgeLoanResponse | null>(null);
  const [addInvestorBridgeLoanModalOpen, setAddInvestorBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [editInvestorBridgeLoanModalOpen, setEditInvestorBridgeLoanModalOpen] =
    React.useState<boolean>(false);
  const [investorBridgeForm, setInvestorBridgeForm] =
    React.useState<IInvestorBridgeLoanForm>(
      INVESTOR_BRIDGE_LOAN_FORM_DEFAULT_STATE,
    );

  const [costDateSchedule, setCostDateSchedule] = React.useState<string[]>([]);
  const [openAddCostModal, setOpenAddCostModal] = React.useState(false);
  const [addCostForm, setAddCostForm] = React.useState<ICostItemForm>(
    COST_ITEM_FORM_DEFAULT_STATE,
  );
  const [
    projectSeasonalityAdjustmentFactors,
    setProjectSeasonalityAdjustmentFactors,
  ] = React.useState<Array<{ name: string; value: string }>>([]);
  const [projectProductionInputValues, setProjectProductionInputValues] =
    React.useState({});
  const [addDepreciationStreamModalOpen, setAddDepreciationStreamModalOpen] =
    React.useState<boolean>(false);
  const [
    updateDepreciationStreamModalOpen,
    setUpdateDepreciationStreamModalOpen,
  ] = React.useState<boolean>(false);
  const [projectDepreciationForm, setProjectDepreciationForm] = React.useState<
    IAddProjectDepreciationStreamForm | IUpdateProjectDepreciationStreamForm
  >(DEFAULT_PROJECT_DEPRECIATION_STREAM_FORM);
  const [selectedStreamToUpdate, setSelectedStreamToUpdate] = React.useState<{
    depreciationId: number;
    streamId: number;
  }>();

  const {
    callAPI: updateDealSponsorEquityCallAPI,
    fieldErrors: updateDealSponsorEquityFormErrors,
    setFieldErrors: setUpdateDealSponsorEquityFormErrors,
    loading: updateDealSponsorEquityLoading,
  } = useAPI<IDealSponsorEquity, IDealSponsorEquityFormErrors>(
    (dealId: number, taxEquityId: number, form: IDealSponsorEquityForm) =>
      updateDealSponsorEquity(dealId, taxEquityId, form),
  );

  const {
    callAPI: createCostItemCallAPI,
    loading: createCostItemLoading,
    fieldErrors: createCostItemFormErrors,
    setFieldErrors: setCreateCostItemFormErrors,
  } = useAPI<ICostItemResponse, ICostItemFormErrors>(
    (form, projectId, costId) => createProjectCostItem(form, projectId, costId),
  );

  const {
    callAPI: getDealCaseDetailsCallAPI,
    errored: getDealCaseDetailsErrored,
    loading: getDealCaseDetailsLoading,
    response: caseDetails,
  } = useAPI(
    (dealId: number, caseId: number) => getDealCaseDetails(dealId, caseId),
    { initialLoading: true },
  );

  const {
    callAPI: getDealDetailsFatCallAPI,
    errored: getDealDetailsFatErrored,
    loading: getDealDetailsFatLoading,
    response: dealDetailsFat,
  } = useAPI((dealId: number) => getDealDetailsFat(dealId), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectDetailsFatCallAPI,
    errored: getProjectDetailsFatErrored,
    loading: getProjectDetailsFatLoading,
    response: projectDetailsFat,
  } = useAPI((projectId: number) => getProjectDetailsFat(projectId));

  const {
    callAPI: addDealDebtCallAPI,
    fieldErrors: addDealTermDebtFormErrors,
    setFieldErrors: setAddDealTermDebtFormErrors,
    loading: addDealDebtLoading,
  } = useAPI<IDealDebt, IDealTermDebtFormErrors>(
    (dealId: number, form: IDealTermDebtForm) => addDealDebt(dealId, form),
  );

  const {
    callAPI: updateDealDebtCallAPI,
    fieldErrors: updateDealTermDebtFormErrors,
    setFieldErrors: setUpdateDealTermDebtFormErrors,
    loading: updateDealDebtLoading,
  } = useAPI<IDealDebt, IDealTermDebtFormErrors>(
    (dealId: number, dealTermDebtId: number, form: IDealTermDebtForm) =>
      updateDealDebt(dealId, dealTermDebtId, form),
  );

  const {
    callAPI: editDealDepreciationCallAPI,
    fieldErrors: editDealDepreciationFormErrors,
    setFieldErrors: setEditDealDepreciationFormErrors,
    loading: editDealDepreciationLoading,
  } = useAPI<
    IUpdateDealDepreciationElectionForm,
    IUpdateDealDepreciationElectionFormErrors
  >((dealId: number, form: IUpdateDealDepreciationElectionForm) =>
    editDealDepreciation(Number(dealId), form),
  );

  const {
    callAPI: updateDealTaxCreditDetailsCallAPI,
    loading: loadingUpdateDealTaxCreditDetails,
    fieldErrors: dealTaxCreditFormErrors,
    setFieldErrors: setDealTaxCreditFormErrors,
  } = useAPI<IDealTaxCreditTransferResults, IDealTaxCreditTransferFormErrors>(
    (form: IDealTaxCreditTransferForm, sizingId: number, dealId: number) =>
      updateDealTaxCreditTransferDetails(dealId, sizingId, form),
  );

  const {
    callAPI: updateDealTaxEquityCallAPI,
    fieldErrors: updateDealTaxEquityFormErrors,
    setFieldErrors: setUpdateDealTaxEquityFormErrors,
    loading: updateDealTaxEquityLoading,
  } = useAPI<IDealTaxEquity, IDealTaxEquityFormErrors>(
    (dealId: number, taxEquityId: number, form: IDealTaxEquityForm) =>
      updateDealTaxEquity(dealId, taxEquityId, form),
  );

  const {
    callAPI: updateDealTimingCallAPI,
    fieldErrors: updateDealTimingFormErrors,
    setFieldErrors: setUpdateDealTimingFormErrors,
    loading: updateDealTimingLoading,
  } = useAPI<IDealTiming, IUpdateDealTimingFormErrors>(
    (dealId: number, timingId: number, form: IUpdateDealTimingForm) =>
      updateDealTiming(dealId, timingId, form),
  );

  const {
    callAPI: editProjectTimingCallAPI,
    fieldErrors: editProjectTimingFormErrors,
    setFieldErrors: setEditProjectTimingFormErrors,
    loading: editProjectTimingLoading,
  } = useAPI<IProjectTiming, IUpdateProjectTimingFormErrors>(
    (projectId: number, timingId: number, form: IUpdateProjectTimingForm) =>
      editProjectTiming(projectId, timingId, form),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateProjectCostCallAPI,
    fieldErrors: updateProjectCostFormErrors,
    setFieldErrors: setUpdateProjectCostFormErrors,
    loading: updateProjectCostLoading,
  } = useAPI<IProjectCost, IUpdateProjectCostFormErrors>(
    (projectId: number, costId: number, form: { profit_percent: number }) =>
      updateProjectCosts(form, projectId, costId),
  );

  const {
    callAPI: updateProjectProductionCallAPI,
    fieldErrors: updateProjectProductionFormErrors,
    setFieldErrors: setUpdateProjectProductionFormErrors,
    loading: updatingProduction,
  } = useAPI<IProjectProduction, IUpdateProjectProductionFormErrors>(
    (
      projectId: number,
      productionId: number,
      form: IUpdateProjectProductionForm,
    ) => updateProjectProduction(projectId, productionId, form),
  );

  const {
    callAPI: updateProjectTaxCreditCallAPI,
    fieldErrors: updateProjectTaxCreditFormErrors,
    setFieldErrors: setUpdateProjectTaxCreditFormErrors,
    loading: updateProjectTaxCreditLoading,
  } = useAPI<IProjectTaxCredit, IUpdateProjectTaxCreditFormErrors>(
    (
      projectId: number,
      taxCreditId: number,
      form: IUpdateProjectTaxCreditForm,
    ) => updateProjectTaxCredit(projectId, taxCreditId, form),
  );

  const {
    callAPI: addProjectRevenueContractCallAPI,
    fieldErrors: addProjectRevenueContractFormErrors,
    setFieldErrors: setAddProjectRevenueContractFormErrors,
    loading: addingRevenueContract,
  } = useAPI<IProjectRevenue, IProjectRevenueFormErrors>(
    (projectId: number, form: IProjectRevenueForm) =>
      addProjectRevenueContract(projectId, form),
  );

  const {
    callAPI: editProjectRevenueContractCallAPI,
    fieldErrors: editProjectRevenueContractFormErrors,
    setFieldErrors: setEditProjectRevenueContractFormErrors,
    loading: editProjectRevenueContractLoading,
  } = useAPI<IProjectRevenue, IProjectRevenueFormErrors>(
    (projectId: number, revenueId: number, form: IProjectRevenueForm) =>
      editProjectRevenueContract(projectId, revenueId, form),
  );

  const { callAPI: deleteProjectRevenueContractCallAPI } = useAPI(
    (projectId: number, revenueId: number) =>
      deleteProjectRevenueContract(projectId, revenueId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: addMerchantRevenueCallAPI,
    fieldErrors: addMerchantRevenueFormErrors,
    setFieldErrors: setAddMerchantRevenueFormErrors,
    loading: addMerchantRevenueLoading,
  } = useAPI<IProjectMerchantRevenue, IProjectMerchantRevenueFormErrors>(
    (projectId: number, form: IProjectMerchantRevenueForm) =>
      addMerchantRevenue(projectId, form),
  );

  const {
    callAPI: updateMerchantRevenueCallAPI,
    fieldErrors: updateMerchantRevenueFormErrors,
    setFieldErrors: setUpdateMerchantRevenueFormErrors,
    loading: updateMerchantRevenueLoading,
  } = useAPI<IProjectMerchantRevenue, IProjectMerchantRevenueFormErrors>(
    (
      projectId: number,
      merchantRevenueId: number,
      form: IProjectMerchantRevenueForm,
    ) => updateMerchantRevenue(projectId, merchantRevenueId, form),
  );

  const { callAPI: deleteProjectMerchantRevenueContractCallAPI } = useAPI(
    (projectId: number, revenueId: number) =>
      deleteProjectMerchantRevenueContract(projectId, revenueId),
    { setConfirmModalLoading: true },
  );

  const [editGeneralAssumptionsModalOpen, setEditGeneralAssumptionsModalOpen] =
    React.useState<boolean>(false);

  const {
    callAPI: addProjectExpenseCallAPI,
    fieldErrors: addProjectExpenseFormErrors,
    setFieldErrors: setAddProjectExpenseFormErrors,
    loading: addProjectExpenseLoading,
  } = useAPI<IProjectExpense, IProjectExpenseFormErrors>(
    (projectId: number, form: IProjectExpenseForm) =>
      addProjectExpense(Number(projectId), form),
  );

  const {
    callAPI: updateProjectExpenseCallAPI,
    fieldErrors: updateProjectExpenseFormErrors,
    setFieldErrors: setUpdateProjectExpenseFormErrors,
    loading: updateProjectExpenseLoading,
  } = useAPI<IProjectExpense, IProjectExpenseFormErrors>(
    (projectId: number, expenseId: number, form: IProjectExpenseForm) =>
      updateProjectExpense(projectId, expenseId, form),
  );

  const { callAPI: deleteExpenseCallAPI } = useAPI(
    (projectId, expenseId) => deleteExpense(projectId, expenseId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateProjectEBITDACallAPI,
    fieldErrors: updateProjectEBITDAFormErrors,
    setFieldErrors: setUpdateProjectEBITDAFormErrors,
    loading: updatingEBITDA,
  } = useAPI<IProjectEBITDA, IProjectEBITDA_FormErrors>(
    (projectId: number, id: number, form: IProjectEBITDA_Form) =>
      updateProjectEBITDA(projectId, id, form),
  );

  const { callAPI: getDealOtherSizingCallAPI } = useAPI((id) =>
    getDealOtherSizing(Number(id)),
  );

  const { callAPI: deleteDealDebtCallAPI } = useAPI(
    (dealId: number, debtId: number) => deleteDealDebt(dealId, debtId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateDealOtherSizingCallAPI,
    loading: loadingUpdateDealOtherSizing,
    fieldErrors: updateDealOtherSizingFormErrors,
    setFieldErrors: setUpdateDealOtherSizingFormErrors,
  } = useAPI<IDealOtherSizing, IUpdateDealOtherSizingFormErrors>(
    (form: IUpdateDealOtherSizingForm, sizingId: number, id: number) => {
      return updateDealOtherSizing(Number(id), Number(sizingId), form);
    },
  );

  const {
    callAPI: updateTaxCreditTransferCallAPI,
    loading: loadingUpdateTaxCreditTransfer,
    fieldErrors: updateTaxCreditTransferFormErrors,
    setFieldErrors: setUpdateTaxCreditTransferFormErrors,
  } = useAPI<
    IDealTaxCreditTransferPortfolio,
    IDealTaxCreditTransferPortfolioFormErrors
  >(
    (
      form: IDealTaxCreditTransferPortfolioForm,
      id: number,
      transferConfigId: number,
    ) => updateDealTransferPortfolio(Number(id), transferConfigId, form),
  );

  const {
    callAPI: addProjectOtherFlowCallAPI,
    fieldErrors: addProjectOtherFlowFormErrors,
    setFieldErrors: setAddProjectOtherFlowFormErrors,
    loading: loadingAddProjectOtherFlow,
  } = useAPI<IProjectOtherFlow, IProjectOtherFlowFormErrors>(
    (projectId: number, form: IProjectOtherFlowForm) =>
      addProjectOtherFlow(projectId, form),
  );

  const {
    callAPI: editProjectOtherFlowCallAPI,
    fieldErrors: editProjectOtherFlowFormErrors,
    setFieldErrors: setEditProjectOtherFlowFormErrors,
    loading: loadingEditProjectOtherFlow,
  } = useAPI<IProjectOtherFlow, IProjectOtherFlowFormErrors>(
    (projectId: number, id: number, form: IProjectOtherFlowForm) =>
      editProjectOtherFlow(projectId, id, form),
  );

  const { callAPI: deleteProjectOtherFlowCallAPI } = useAPI(
    (projectId: number, id: number) => deleteProjectOtherFlow(projectId, id),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: addDealFeeCallAPI,
    fieldErrors: addDealFeeFormErrors,
    setFieldErrors: setAddDealFeeFormErrors,
    loading: addDealFeeLoading,
  } = useAPI<IDealFee, IDealFeeFormErrors>(
    (dealId: number, form: IDealFeeForm) => addDealFee(Number(dealId), form),
  );

  const {
    callAPI: updateDealFeeCallAPI,
    fieldErrors: updateDealFeeFormErrors,
    setFieldErrors: setUpdateDealFeeFormErrors,
    loading: updateDealFeeLoading,
  } = useAPI<IDealFee, IDealFeeFormErrors>(
    (dealId: number, feeId: number, form: IDealFeeForm) =>
      updateDealFee(dealId, feeId, form),
  );

  const { callAPI: deleteDealFeeCallAPI } = useAPI(
    (dealId, feeId) => deleteDealFee(dealId, feeId),
    {
      setConfirmModalLoading: true,
    },
  );

  const {
    callAPI: addGeneralAssumptionCallAPI,
    fieldErrors: addGeneralAssumptionFieldErrors,
    setFieldErrors: setAddGeneralAssumptionFieldErrors,
    loading: addGeneralAssumptionsLoading,
  } = useAPI<IGeneralAssumptionResponse, IGeneralAssumptionsFormErrors>(
    (dealId: number, form: IGeneralAssumptionsForm) =>
      addGeneralAssumption(dealId, form),
  );

  const {
    callAPI: addConstructionLoanCallAPI,
    fieldErrors: addConstructionLoanFieldErrors,
    setFieldErrors: setAddConstructionLoanFieldErrors,
    loading: addConstructionLoanLoading,
  } = useAPI<IConstructionLoanResponse, IConstructionLoanFormErrors>(
    (dealId: number, form: IConstructionLoanForm) =>
      addConstructionLoan(dealId, form),
  );

  const {
    callAPI: updateConstructionLoanCallAPI,
    fieldErrors: updateConstructionLoanFormErrors,
    setFieldErrors: setUpdateConstructionLoanFormErrors,
    loading: updateConstructionLoanLoading,
  } = useAPI<IConstructionLoanResponse, IConstructionLoanFormErrors>(
    (dealId: number, loanId: number, form: IConstructionLoanForm) =>
      updateConstructionLoan(dealId, loanId, form),
  );

  const {
    callAPI: addProjectDepreciationStreamCallAPI,
    fieldErrors: addProjectDepreciationStreamFormErrors,
    setFieldErrors: setAddProjectDepreciationStreamFormErrors,
    loading: addDepreciationStreamLoading,
  } = useAPI<
    IProjectDepreciationStream,
    IAddProjectDepreciationStreamFormErrors
  >(
    (
      projectId: number,
      depreciationId: number,
      form: IAddProjectDepreciationStreamForm,
    ) => addProjectDepreciationStream(projectId, depreciationId, form),
  );

  const handleOnAddConstructionLoan = async (form: IConstructionLoanForm) => {
    const loan = await addConstructionLoanCallAPI(
      Number(dealDetailsFat?.id),
      form,
    );
    loan && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return loan;
  };

  const handleOnEditConstructionLoan = async (form: IConstructionLoanForm) => {
    const updatedLoan = await updateConstructionLoanCallAPI(
      Number(dealDetailsFat?.id),
      dealDetailsFat?.deal_construction_debt?.id,
      form,
    );
    updatedLoan && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return updatedLoan;
  };

  const handleOpenAddConstructionLoanModal = async () => {
    setAddConstructionLoanModalOpen(true);
  };

  const handleCloseAddConstructionLoanModal = () => {
    setAddConstructionLoanModalOpen(false);
  };

  const handleOpenEditConstructionLoanModal = async () => {
    if (dealDetailsFat?.deal_construction_debt) {
      const {
        advance_rate,
        created,
        created_by,
        general_sizing,
        id,
        modified,
        ...formData
      } = dealDetailsFat?.deal_construction_debt;
      setConsstructionLoanForm(formData);
      setEditConstructionLoanModalOpen(true);
    }
  };

  const handleCloseEditConstructionLoanModal = () => {
    setEditConstructionLoanModalOpen(false);
  };

  const {
    callAPI: addInvestorBridgeLoanCallAPI,
    fieldErrors: addInvestorBridgeLoanFieldErrors,
    setFieldErrors: setAddInvestorBridgeLoanFieldErrors,
    loading: addInvestorBridgeLoanLoading,
  } = useAPI<IInvestorBridgeLoanResponse, IInvestorBridgeLoanFormErrors>(
    (dealId: number, form: IInvestorBridgeLoanForm) =>
      addInvestorLoan(dealId, form),
  );

  const {
    callAPI: updateInvestorBridgeLoanCallAPI,
    fieldErrors: updateInvestorBridgeLoanFormErrors,
    setFieldErrors: setUpdateInvestorBridgeLoanFormErrors,
    loading: updateInvestorBridgeLoanLoading,
  } = useAPI<IInvestorBridgeLoanResponse, IInvestorBridgeLoanFormErrors>(
    (dealId: number, loanId: number, form: IInvestorBridgeLoanForm) =>
      updateInvestorLoan(dealId, loanId, form),
  );

  const handleOnAddInvestorBridgeLoan = async (
    form: IInvestorBridgeLoanForm,
  ) => {
    const loan = await addInvestorBridgeLoanCallAPI(
      Number(dealDetailsFat?.id),
      form,
    );
    loan && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return loan;
  };

  const handleOnEditInvestorBridgeLoan = async (
    form: IInvestorBridgeLoanForm,
  ) => {
    const updatedLoan = await updateInvestorBridgeLoanCallAPI(
      Number(dealDetailsFat?.id),
      dealDetailsFat?.deal_investor_bridge_debt?.id,
      form,
    );
    updatedLoan && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return updatedLoan;
  };

  const handleOpenAddInvestorBridgeLoanModal = async () => {
    setAddInvestorBridgeLoanModalOpen(true);
  };

  const handleCloseAddInvestorBridgeLoanModal = () => {
    setAddInvestorBridgeLoanModalOpen(false);
  };

  const handleOpenEditInvestorBridgeLoanModal = async () => {
    if (dealDetailsFat?.deal_investor_bridge_debt) {
      const {
        created,
        created_by,
        general_sizing,
        id,
        modified,
        sizing_type,
        ...formData
      } = dealDetailsFat?.deal_investor_bridge_debt;
      setInvestorBridgeForm(formData);
      setEditInvestorBridgeLoanModalOpen(true);
    }
  };

  const handleCloseEditInvestorBridgeLoanModal = () => {
    setEditInvestorBridgeLoanModalOpen(false);
  };

  const {
    callAPI: updateGeneralAssumptionCallAPI,
    fieldErrors: updateGeneralAssumptionFormErrors,
    setFieldErrors: setUpdateGeneralAssumptionFormErrors,
    loading: updateGeneralAssumptionsLoading,
  } = useAPI<IGeneralAssumptionResponse, IGeneralAssumptionsFormErrors>(
    (dealId: number, dealTermDebtId: number, form: IGeneralAssumptionsForm) =>
      updateGeneralAssumption(dealId, dealTermDebtId, form),
  );

  const handleOnAddGeneralAssumption = async (
    form: IGeneralAssumptionsForm,
  ) => {
    const generalAssumption = await addGeneralAssumptionCallAPI(
      Number(dealDetailsFat?.id),
      form,
    );
    generalAssumption && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return generalAssumption;
  };

  const handleOnEditGeneralAssumption = async (
    form: IGeneralAssumptionsForm,
  ) => {
    const updatedGeneralAssumption = await updateGeneralAssumptionCallAPI(
      Number(dealDetailsFat?.id),
      dealDetailsFat?.deal_construction_debt_general_sizing?.id,
      form,
    );
    updatedGeneralAssumption && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return updatedGeneralAssumption;
  };
  const [addGeneralAssumptionsModalOpen, setAddGeneralAssumptionsModalOpen] =
    React.useState<boolean>(false);

  const handleOpenAddGeneralAssumptionsModal = async () => {
    await handleGetCurves(["IRC"]);
    setAddGeneralAssumptionsModalOpen(true);
  };

  const handleCloseAddGeneralAssumptionsModal = () => {
    setAddGeneralAssumptionsModalOpen(false);
  };

  const handleOpenEditGeneralAssumptionsModal = async () => {
    await handleGetCurves(["IRC"]);
    if (dealDetailsFat?.deal_construction_debt_general_sizing) {
      const {
        created,
        created_by,
        deal,
        id,
        modified,
        sofr_org_curve_detail,
        ...formData
      } = dealDetailsFat?.deal_construction_debt_general_sizing;
      setGeneralAssumptionForm({
        ...formData,
        loc_for_construction_credit_support:
          formData.loc_for_construction_credit_support ? "YES" : "NO",
      });
      setEditGeneralAssumptionsModalOpen(true);
    }
  };

  const handleCloseEditGeneralAssumptionsModal = () => {
    setEditGeneralAssumptionsModalOpen(false);
  };

  const { callAPI: deleteInvestorLoanCallAPI } = useAPI(
    (id: number, loanId: number) => deleteInvestorLoan(id, loanId),
    { setConfirmModalLoading: true },
  );

  const handleDeleteConstructionLoan = async () => {
    const deleted = await deleteInvestorLoanCallAPI(
      dealDetailsFat?.id,
      dealDetailsFat?.deal_investor_bridge_debt?.id,
    );

    deleted && getDealDetailsFatCallAPI(dealDetailsFat?.id);
  };

  const handleOnDeleteInvestorBridge = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Investor Bridge Loan",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteConstructionLoan(),
      }),
    );
  };

  const onAddStream = async (form: IProjectOtherFlowForm) => {
    if (projectDetailsFat) {
      const { id: projectId } = projectDetailsFat;
      const cash = await addProjectOtherFlowCallAPI(Number(projectId), form);
      cash && getProjectDetailsFatCallAPI(projectId);
      return cash;
    }
    return undefined;
  };

  const handleDeleteStream = async (id: number) => {
    if (projectDetailsFat) {
      const { id: projectId } = projectDetailsFat;
      const deleted = await deleteProjectOtherFlowCallAPI(
        Number(projectId),
        id,
      );
      deleted && getProjectDetailsFatCallAPI(projectId);
    }
  };

  const onDeleteStream = (id: number) => {
    if (id) {
      dispatch(
        setDeleteModalPropsAction({
          open: true,
          title: "Delete Other Flow",
          description: "Are you sure you want to delete?",
          onConfirm: () => handleDeleteStream(id),
        }),
      );
    }
  };

  const handleOpenEditStreamModal = (id: number) => {
    setSelectedProjectOtherFlow(id);
    if (id) {
      const stream = projectDetailsFat?.other_flows.find((c) => c.id === id);
      if (stream) {
        const { name, stream_strip, type, input_type } = stream;
        setProjectOtherFlowForm({
          name,
          stream_strip,
          type,
          input_type,
        });
        setUpdateProjectOtherFlowModalOpen(true);
      }
    }
  };

  const handleCloseEditStreamModal = () => {
    setUpdateProjectOtherFlowModalOpen(false);
    setSelectedProjectOtherFlow(undefined);
  };

  const onEditStream = async (form: IProjectOtherFlowForm) => {
    const { id: projectId } = projectDetailsFat!;
    const cash = await editProjectOtherFlowCallAPI(
      Number(projectId),
      selectedProjectOtherFlow,
      form,
    );
    cash && getProjectDetailsFatCallAPI(projectId);
    return cash;
  };

  React.useEffect(() => {
    getDealCaseDetailsCallAPI(dealId, caseId);
  }, [dealId, caseId]);

  React.useEffect(() => {
    if (dealDetailsFat) {
      const { id: dealId } = dealDetailsFat;
      getDealOtherSizingCallAPI(dealId).then((res) => {
        res && setDealOtherSizing(res[0]);
      });
    }
  }, [dealDetailsFat]);

  React.useEffect(() => {
    if (!dealDetailsFat || caseDetails?.id !== caseId) {
      caseDetails && getDealDetailsFatCallAPI(caseDetails.child_deal.id);
    }
  }, [caseDetails, dealId, caseId]);

  React.useEffect(() => {
    const isFromCurrentDeal = caseDetails?.child_deal_projects.some(
      (project) => project.id === selectedProject,
    );
    if (caseDetails?.child_deal_projects.length && !isFromCurrentDeal) {
      setSelectedProject(caseDetails.child_deal_projects[0].id);
    }
  }, [caseDetails]);

  React.useEffect(() => {
    if (selectedProject) {
      getProjectDetailsFatCallAPI(selectedProject);
    }
  }, [selectedProject]);

  const projectDateSchedule: string[] = React.useMemo(() => {
    return projectDetailsFat?.timing.date_schedule || [];
  }, [projectDetailsFat]);

  const handleProjectChange = (tab: ITab) => {
    setSelectedProject(Number(tab.value));
  };

  const hadleProjectChangeFromPopover = (projectId: number) => {
    setSelectedProject(projectId);
  };

  const handleOnEditDealTaxEquity = async (form: IDealTaxEquityForm) => {
    if (dealDetailsFat?.partnership_investor_configuration) {
      const {
        id: dealId,
        partnership_investor_configuration: { id: taxEquityId },
      } = dealDetailsFat;

      const taxEquity = await updateDealTaxEquityCallAPI(
        dealId,
        taxEquityId,
        form,
      );

      taxEquity && getDealDetailsFatCallAPI(dealId);
      return taxEquity;
    }
    return undefined;
  };

  const handleOnAddDealDebt = async (form: IDealTermDebtForm) => {
    if (dealDetailsFat) {
      const { id: dealId } = dealDetailsFat;
      const dealTermDebt = await addDealDebtCallAPI(Number(dealId), form);

      dealTermDebt && getDealDetailsFatCallAPI(Number(dealId));
      return dealTermDebt;
    }
    return undefined;
  };

  const handleOnEditDealDebt = async (form: IDealTermDebtForm) => {
    if (dealDetailsFat?.debt) {
      const {
        id: dealId,
        debt: { id: dealTermDebtId },
      } = dealDetailsFat;
      const updatedDealDebt = await updateDealDebtCallAPI(
        dealId,
        dealTermDebtId,
        form,
      );
      updatedDealDebt && getDealDetailsFatCallAPI(Number(dealId));
      return updatedDealDebt;
    }
    return undefined;
  };

  const handleOnEditDealDepreciation = async (
    form: IUpdateDealDepreciationElectionForm,
  ) => {
    const { id: dealId } = dealDetailsFat!;
    const updatedDealDepreciation = await editDealDepreciationCallAPI(
      dealId,
      form,
    );

    updatedDealDepreciation && getDealDetailsFatCallAPI(Number(dealId));
    return updatedDealDepreciation;
  };

  const handleEditDealTaxCreditTransfer = async (
    form: IDealTaxCreditTransferForm,
  ) => {
    const { id: dealId } = dealDetailsFat!;
    const updatedForm = {
      payment_dates_lag: form.payment_dates_lag,
      payment_date_num_1: form.payment_date_num_1,
      payment_periodicity: form.isPTC ? form.payment_periodicity : null,
      payment_rate: form.payment_rate,
      placed_in_service_date: form.placed_in_service_date,
      pre_filing_date: form.pre_filing_date,
      transfer_portion: form.transfer_portion,
    };
    const { id } = form;
    const dealTaxCreditTransfer = await updateDealTaxCreditDetailsCallAPI(
      updatedForm,
      Number(id),
      dealId,
    );
    dealTaxCreditTransfer && getDealDetailsFatCallAPI(dealId);

    return dealTaxCreditTransfer;
  };

  const handleUpdateDealTiming = async (form: IUpdateDealTimingForm) => {
    const updatedForm = form;
    if (dealDetailsFat?.tax_credit_structure !== "TR") {
      delete updatedForm.tct_buyer_tax_filing_month;
      delete updatedForm.tct_buyer_tax_year_end_month;
      delete updatedForm.tct_seller_tax_filing_month;
      delete updatedForm.tct_seller_tax_year_end_month;
    }
    if (dealDetailsFat && dealDetailsFat) {
      const { id: dealId } = dealDetailsFat;
      const { id: timingId } = dealDetailsFat?.timing;
      const timing = await updateDealTimingCallAPI(
        Number(dealId),
        timingId,
        updatedForm,
      );
      timing && getDealDetailsFatCallAPI(dealId);
      return timing;
    }
    return undefined;
  };

  const handleOnEditProjectTiming = async (form: IUpdateProjectTimingForm) => {
    const updatedForm = { ...form };

    Object.keys(updatedForm).forEach((key) => {
      if (updatedForm[key as keyof IUpdateProjectTimingForm] === "") {
        updatedForm[key as keyof IUpdateProjectTimingForm] = null as never;
      }
    });

    const fieldsToInclude =
      PROJECT_TIMING_HORIZON_FIELD_MAPPING[
        updatedForm.horizon as keyof typeof PROJECT_TIMING_HORIZON_FIELD_MAPPING
      ];

    const formToSubmit = Object.keys(updatedForm).reduce(
      (obj, key) => ({
        ...obj,
        [key as keyof IUpdateProjectTimingForm]: fieldsToInclude.includes(
          key as keyof IUpdateProjectTimingForm,
        )
          ? updatedForm[key as keyof IUpdateProjectTimingForm]
          : null,
      }),
      {} as IUpdateProjectTimingForm,
    );

    if (projectDetailsFat?.timing) {
      const { id: projectTimingId } = projectDetailsFat.timing;
      const { id: projectId } = projectDetailsFat;
      if (
        // if any of the timing fields are changed
        Object.keys(formToSubmit).some(
          (key) =>
            formToSubmit[key as keyof IUpdateProjectTimingForm] !==
            projectDetailsFat.timing[key as keyof IUpdateProjectTimingForm],
        )
      ) {
        dispatch(
          setDeleteModalPropsAction({
            open: true,
            title: "Confirm Timing Changes",
            description:
              "You are modifying timing, operating life, or periodicity, which may impact any price curves or strips of data you entered in expenses, revenue, etc. We will do our best to adjust them automatically, but please re-check or edit those as appropriate",
            confirmBtnLabel: "Confirm",
            confirmBtnType: "primary",
            onConfirm: async () => {
              const timing = await editProjectTimingCallAPI(
                projectId,
                projectTimingId,
                formToSubmit,
              );
              if (timing) {
                handleCloseEditTimingModal();
                getProjectDetailsFatCallAPI(projectId);
              }
            },
          }),
        );
      } else {
        const timing = await editProjectTimingCallAPI(
          projectId,
          projectTimingId,
          formToSubmit,
        );
        timing && getProjectDetailsFatCallAPI(projectId);
        return timing;
      }
    }
    return undefined;
  };

  const handleUpdateProjectCost = async (form: IProjectCostForm) => {
    if (projectDetailsFat?.cost) {
      const { id: costId } = projectDetailsFat.cost.project_cost;
      const { id: projectId } = projectDetailsFat;
      const cost = await updateProjectCostCallAPI(projectId, costId, form);
      cost && getProjectDetailsFatCallAPI(projectId);
      return cost;
    }
    return undefined;
  };

  const handleUpdateProduction = async (form: IUpdateProjectProductionForm) => {
    if (projectDetailsFat?.production) {
      const {
        id: projectId,
        production: { id: productionId },
      } = projectDetailsFat;
      const production = await updateProjectProductionCallAPI(
        projectId,
        productionId,
        form,
      );

      production && getProjectDetailsFatCallAPI(projectId);
      return production;
    }
    return undefined;
  };

  const handleOnUpdateProjectTaxCredit = async (
    form: IUpdateProjectTaxCreditForm,
  ) => {
    if (projectDetailsFat?.tax_credit) {
      const {
        id: projectId,
        tax_credit: { id: taxCreditId },
      } = projectDetailsFat;
      const taxCredit = await updateProjectTaxCreditCallAPI(
        projectId,
        taxCreditId,
        form,
      );
      taxCredit && getProjectDetailsFatCallAPI(projectId);
      return taxCredit;
    }
    return undefined;
  };

  const handleAddRevenueContract = async (form: IProjectRevenueForm) => {
    if (projectDetailsFat) {
      const { id: projectId } = projectDetailsFat;
      const revenue = await addProjectRevenueContractCallAPI(projectId, form);

      revenue && getProjectDetailsFatCallAPI(projectId);
      return revenue;
    }
    return undefined;
  };

  const handleEditProjectRevenueContract = async (
    form: IProjectRevenueForm,
  ) => {
    if (projectDetailsFat) {
      const revenue = await editProjectRevenueContractCallAPI(
        projectDetailsFat.id,
        selectedProjectRevenueToEdit,
        form,
      );

      revenue && getProjectDetailsFatCallAPI(projectDetailsFat.id);
      return revenue;
    }
    return undefined;
  };

  const handleAddMerchantRevenue = async (
    form: IProjectMerchantRevenueForm,
  ) => {
    if (projectDetailsFat) {
      const { id: projectId } = projectDetailsFat;
      const merchantRevenue = await addMerchantRevenueCallAPI(projectId, form);

      merchantRevenue && getProjectDetailsFatCallAPI(projectId);
      return merchantRevenue;
    }
    return undefined;
  };

  const handleUpdateMerchantRevenue = async (
    form: IProjectMerchantRevenueForm,
  ) => {
    if (projectDetailsFat) {
      const merchantRevenue = await updateMerchantRevenueCallAPI(
        projectDetailsFat.id,
        Number(selectedMerchantRevenueToEdit),
        form,
      );

      merchantRevenue && getProjectDetailsFatCallAPI(projectDetailsFat.id);
      return merchantRevenue;
    }
    return undefined;
  };

  const {
    callAPI: addTransferBridgeLoanCallAPI,
    fieldErrors: addTransferBridgeLoanFieldErrors,
    setFieldErrors: setAddTransferBridgeLoanFieldErrors,
    loading: addTransferBridgeLoanLoading,
  } = useAPI<ITransferBridgeLoanResponse, ITransferBridgeLoanFormErrors>(
    (dealId: number, form: ITransferBridgeLoanForm) =>
      addTransferLoan(dealId, form),
  );

  const {
    callAPI: updateTransferBridgeLoanCallAPI,
    fieldErrors: updateTransferrBridgeLoanFormErrors,
    setFieldErrors: setUpdateTransferBridgeLoanFormErrors,
    loading: updateTransferBridgeLoanLoading,
  } = useAPI<ITransferBridgeLoanResponse, ITransferBridgeLoanFormErrors>(
    (dealId: number, loanId: number, form: ITransferBridgeLoanForm) =>
      updateTransferLoan(dealId, loanId, form),
  );

  const handleOnAddTransferBridgeLoan = async (
    form: ITransferBridgeLoanForm,
  ) => {
    const loan = await addTransferBridgeLoanCallAPI(
      Number(dealDetailsFat?.id),
      form,
    );
    loan && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return loan;
  };

  const handleOnEditTransferBridgeLoan = async (
    form: ITransferBridgeLoanForm,
  ) => {
    const updatedLoan = await updateTransferBridgeLoanCallAPI(
      Number(dealDetailsFat?.id),
      dealDetailsFat?.deal_transfer_bridge_debt?.id,
      form,
    );
    updatedLoan && getDealDetailsFatCallAPI(dealDetailsFat?.id);

    return updatedLoan;
  };

  const handleOpenAddTransferBridgeLoanModal = async () => {
    setAddTransferBridgeLoanModalOpen(true);
  };

  const handleCloseAddTransferBridgeLoanModal = () => {
    setAddTransferBridgeLoanModalOpen(false);
  };

  const handleOpenEditTransferBridgeLoanModal = async () => {
    if (dealDetailsFat?.deal_transfer_bridge_debt) {
      const { created, created_by, general_sizing, id, modified, ...formData } =
        dealDetailsFat?.deal_transfer_bridge_debt;
      setTranferBridgeForm(formData);
      setEditTransferBridgeLoanModalOpen(true);
    }
  };

  const handleCloseEditTransferBridgeLoanModal = () => {
    setEditTransferBridgeLoanModalOpen(false);
  };

  const { callAPI: deleteTransferBridgeLoanCallAPI } = useAPI(
    (id: number, loanId: number) => deleteTransferLoan(id, loanId),
    { setConfirmModalLoading: true },
  );

  const handleDeleteTransferBridgeLoan = async () => {
    const deleted = await deleteTransferBridgeLoanCallAPI(
      dealDetailsFat?.id,
      dealDetailsFat?.deal_transfer_bridge_debt?.id,
    );

    deleted && getDealDetailsFatCallAPI(dealDetailsFat?.id);
  };

  const handleOnDeleteTransferBridge = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Transfer Bridge Loan",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteTransferBridgeLoan(),
      }),
    );
  };

  const { callAPI: deleteConstructionCallAPI } = useAPI(
    (id: number, loanId: number) => deleteConstructionLoan(id, loanId),
    { setConfirmModalLoading: true },
  );

  const handleDeleteConstructionBridgeLoan = async () => {
    const deleted = await deleteConstructionCallAPI(
      dealDetailsFat?.id,
      dealDetailsFat?.deal_construction_debt?.id,
    );

    deleted && getDealDetailsFatCallAPI(dealDetailsFat?.id);
  };

  const handleOnDeleteConstructionLoan = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Construction Loan",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteConstructionBridgeLoan(),
      }),
    );
  };

  const handleOnAddProjectExpense = async (form: IProjectExpenseForm) => {
    if (projectDetailsFat) {
      const { id: projectId } = projectDetailsFat;
      const expense = await addProjectExpenseCallAPI(projectId, form);

      expense && getProjectDetailsFatCallAPI(projectId);
      return expense;
    }
    return undefined;
  };

  const handleOnUpdateProjectExpense = async (form: IProjectExpenseForm) => {
    if (projectDetailsFat) {
      const { id: projectId } = projectDetailsFat;
      const expense = await updateProjectExpenseCallAPI(
        projectId,
        Number(selectedProjectExpenseToEdit),
        form,
      );

      expense && getProjectDetailsFatCallAPI(projectId);
      return expense;
    }
    return undefined;
  };

  const handleOnUpdateProjectEBITDA = async (form: IProjectEBITDA_Form) => {
    if (projectDetailsFat?.schedule_override) {
      const {
        id: projectId,
        schedule_override: { id },
      } = projectDetailsFat;
      const scheduleOverride = await updateProjectEBITDACallAPI(
        projectId,
        id,
        form,
      );
      scheduleOverride && getProjectDetailsFatCallAPI(projectId);
      return scheduleOverride;
    }
    return undefined;
  };

  const onUpdateDealSizing = async (form: IUpdateDealOtherSizingForm) => {
    const { id: dealId } = dealDetailsFat!;
    const res = await updateDealOtherSizingCallAPI(form, form.id, dealId);
    res && setDealOtherSizing(res);
    return res;
  };

  const onUpdateDealTaxEquityClick = () => {
    if (dealDetailsFat?.partnership_investor_configuration) {
      const {
        id,
        deal,
        created,
        modified,
        created_by,
        tax_equity_amount,
        new_federal_tax_rate,
        federal_tax_rate_change_year,
        tax_step_tax_split,
        federal_tax_rate,
        ...restFormData
      } = dealDetailsFat.partnership_investor_configuration;
      setDealTaxEquityForm({
        ...restFormData,
        tax_equity_input_method: restFormData.tax_equity_input_method ?? "",
        change_in_federal_tax_rate: isNumber(
          new_federal_tax_rate || federal_tax_rate_change_year,
        )
          ? "Y"
          : "N",
        new_federal_tax_rate: new_federal_tax_rate || "",
        tax_step_tax_split: tax_step_tax_split || "",
        federal_tax_rate: federal_tax_rate || "",
        federal_tax_rate_change_year,
      });
      setEditDealTaxEquityModalOpen(true);
    }
  };

  const onUpdateDealDebtClick = async () => {
    if (dealDetailsFat?.debt) {
      await handleGetCurves(["IRC"]);
      const { id, deal, created, modified, created_by, ...restFormData } =
        dealDetailsFat.debt;
      setDealTermDebtForm({
        ...restFormData,
      });
      setEditDealDebtModalOpen(true);
    }
  };

  const handleDeleteDealDebt = async () => {
    if (dealDetailsFat?.debt) {
      const { id: dealId, debt: dealTermDebt } = dealDetailsFat;
      const response = await deleteDealDebtCallAPI(
        Number(dealId),
        dealTermDebt.id,
      );
      if (response) {
        getDealDetailsFatCallAPI(Number(dealId));
      }
    }
  };

  const onDealDebtActionBtnClick = async () => {
    if (!dealDetailsFat?.debt) {
      await handleGetCurves(["IRC"]);
      setAddDealDebtModalOpen(true);
      return;
    }

    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal Term Debt",
        description: "Are you sure you want to delete this Deal Debt?",
        onConfirm: handleDeleteDealDebt,
      }),
    );
  };

  const onUpdateDealDepreciationClick = (
    macrsElection: string,
    year: string,
  ) => {
    setDealDepreciationForm({
      macrs_election: macrsElection,
      year,
    });
    setEditDepreciationModalOpen(true);
  };

  const onUpdateDealTaxCreditClick = (transfer: IFatDealProjectTransfer) => {
    const {
      id,
      maximum_payment,
      minimum_roi,
      payment_dates_lag,
      payment_periodicity,
      payment_rate,
      pre_filing_date,
      transfer_portion,
      discount_rate,
      tax_credit_type,
    } = transfer.transfer_sizing;
    setDealTaxCreditForm({
      id: String(id),
      creditEndDate: "itc end date",
      creditStartDate: "itc start date",
      isITC: tax_credit_type === "ITC",
      isPTC: tax_credit_type === "PTC",
      payment_date_num_1: transfer.payment_date_num_1,
      placed_in_service_date: transfer.placed_in_service_date,
      payment_dates_lag,
      payment_periodicity,
      payment_rate,
      pre_filing_date,
      transfer_portion,
    });
    setEditDealTaxCreditModal(true);
  };

  const onUpdateDealTimingClick = () => {
    if (dealDetailsFat?.timing) {
      const {
        developer_horizon,
        tct_seller_tax_year_end_month,
        tct_buyer_tax_year_end_month,
        tct_seller_tax_filing_month,
        tct_buyer_tax_filing_month,
      } = dealDetailsFat?.timing;
      setUpdateDealTimingForm({
        developer_horizon,
        tct_seller_tax_year_end_month,
        tct_buyer_tax_year_end_month,
        tct_seller_tax_filing_month,
        tct_buyer_tax_filing_month,
      });
      setUpdateDealTimingModalOpen(true);
    }
  };

  const handleCloseUpdateDealTimingModal = () => {
    setUpdateDealTimingModalOpen(false);
  };

  const onUpdateProjectTimingClick = () => {
    if (projectDetailsFat?.timing) {
      const {
        created,
        created_by,
        id,
        modified,
        project,
        date_schedule,
        end_date,
        num_dates_in_schedule,
        ...formDetails
      } = projectDetailsFat?.timing;
      setProjectTimingForm({ ...formDetails });
      setEditProjectTimingModalOpen(true);
    }
  };

  const onUpdateProjectCostClick = () => {
    if (projectDetailsFat?.cost) {
      const { fmv_step_up_input_type, profit_percent, fmv_step_up_amount } =
        projectDetailsFat?.cost.project_cost;
      setProjectCostForm({
        fmv_step_up_input_type: fmv_step_up_input_type,
        profit_percent: profit_percent.toString(),
        fmv_step_up: fmv_step_up_amount.toString(),
      });
      setUpdateProjectCostModalOpen(true);
    }
  };

  const handleEditDealTaxCreditTransferConstraint = async (
    form: IDealTaxCreditTransferPortfolioForm,
  ) => {
    if (dealDetailsFat && dealDetailsFat.deal_transfer_config) {
      const { id: dealId } = dealDetailsFat;
      const { id: transferConfigId } = dealDetailsFat.deal_transfer_config;

      const updatedDealTransferPortfolio = await updateTaxCreditTransferCallAPI(
        form,
        dealId,
        transferConfigId,
      );
      updatedDealTransferPortfolio &&
        setUpdateDealTaxCreditTranferConstraintsModalOpen(false);
      updatedDealTransferPortfolio && getDealDetailsFatCallAPI(dealId);
      return updatedDealTransferPortfolio;
    }
    return undefined;
  };

  const onUpdateProjectProductionClick = () => {
    if (projectDetailsFat?.production) {
      const { created, created_by, id, modified, project, ...formDetails } =
        projectDetailsFat?.production;
      setProjectProductionForm({ ...formDetails });
      const factors = getProjectSeasonalityAdjustmentFactors(
        projectDetailsFat?.production,
      );
      if (formDetails.production_input_type === "TI") {
        // Table Input
        const parsedProductionInputs = JSON.parse(
          projectDetailsFat?.production?.production_input,
        );
        const productionInputs = Object.keys(parsedProductionInputs).reduce(
          (acc, currentKey) => {
            return {
              ...acc,
              [currentKey]: formatNumberForView(
                parsedProductionInputs[currentKey],
              ), // formatting numbers
            };
          },
          {} as Record<string, string>,
        );
        setProjectProductionInputValues(productionInputs);
      } else {
        // Single Input
        // setProductionInputValues(JSON.parse(production_input));
        // form.probability_factor_type = Object.keys(JSON.parse(production_input))[0] as keyof typeof PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE;
        // form.gross_production = JSON.parse(production_input)[form.probability_factor_type];
      }
      setProjectSeasonalityAdjustmentFactors(factors);
      setUpdateProjectProductionModalOpen(true);
    }
  };

  const onUpdateProjectTaxCreditClick = () => {
    if (projectDetailsFat?.tax_credit) {
      const { created, created_by, id, modified, project, ...formDetails } =
        projectDetailsFat?.tax_credit;
      setProjectTaxCreditForm({ ...formDetails });
      setUpdateProjectTaxCreditModalOpen(true);
    }
  };

  const handleGetCurves = async (
    curve_type: IGetCurvesParams["curve_type"],
  ) => {
    const curves = await getCurves({ curve_type }).catch(() => null);
    curves && dispatch(setCurrentOrgCurvesAction(curves.results));

    return curves;
  };

  const onUpdateProjectRevenueClick = async (id: number) => {
    const selectedRevenue = projectDetailsFat?.revenue_contracts.find(
      (revenue) => revenue.id === id,
    );

    if (selectedRevenue) {
      await handleGetCurves(["PC"]);
      const { id, created, created_by, modified, project, ...formDetails } =
        selectedRevenue;
      const defaultBaseYear = getDefaultBaseYear(projectDetailsFat?.timing.cod);
      setProjectRevenueForm({
        ...formDetails,
        base_year: formDetails.base_year || defaultBaseYear,
      });
      setSelectedProjectRevenueToEdit(id);
      setProjectEditRevenueContractModalOpen(true);
    }
  };

  const onDeleteRevenueClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Revenue",
        description: "Are you sure you want to delete this revenue?",
        onConfirm: () => handleDeleteRevenueContract(id),
      }),
    );
  };

  const handleDeleteRevenueContract = async (id: number) => {
    const response = await deleteProjectRevenueContractCallAPI(
      Number(selectedProject),
      id,
    );

    if (response) {
      getProjectDetailsFatCallAPI(Number(selectedProject));
    }
  };

  const onUpdateProjectMerchantRevenueClick = async (id: number) => {
    const merchantRevenue = projectDetailsFat?.merchant_revenues.find(
      (mR) => mR.id === id,
    );

    if (merchantRevenue) {
      await handleGetCurves(["PC", "ELCC"]);
      const {
        base_year,
        escalator,
        price,
        price_curve,
        price_input_type,
        type,
        name,
        revenue_curve,
        price_haircut,
        eligible_capacity_input_type,
        cash_basis_lag,
        eligible_capacity_percentage,
        eligible_capacity_percentage_curve,
        org_curve,
        differential_method,
        basis_curve,
        basis_differential,
        eligible_capacity_org_curve,
      } = merchantRevenue;
      setSelectedMerchantRevenueToEdit(id);
      const defaultBaseYear = getDefaultBaseYear(projectDetailsFat?.timing.cod);
      setMerchantRevenueForm({
        base_year: base_year || defaultBaseYear,
        escalator,
        type: type,
        name: name || "",
        price,
        price_curve,
        price_input_type,
        revenue_curve,
        price_haircut,
        eligible_capacity_input_type,
        cash_basis_lag,
        eligible_capacity_percentage,
        eligible_capacity_percentage_curve,
        org_curve,
        basis_curve,
        basis_differential,
        differential_method,
        eligible_capacity_org_curve,
      });
      setUpdateMerchantRevenueModalOpen(true);
    }
  };

  const onDeleteMerchantRevenueClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Merchant Revenue",
        description: "Are you sure you want to delete this revenue?",
        onConfirm: () => handleDeleteMerchantRevenueContract(id),
      }),
    );
  };

  const handleDeleteMerchantRevenueContract = async (id: number) => {
    const response = await deleteProjectMerchantRevenueContractCallAPI(
      Number(selectedProject),
      id,
    );

    if (response) {
      getProjectDetailsFatCallAPI(Number(selectedProject));
    }
  };

  const onUpdateDealOtherSizingClick = () => {
    setUpdateDealSizingForm({
      expense_allocation_method:
        dealOtherSizing?.expense_allocation_method as keyof typeof DEAL_OTHER_SIZING,
      id: Number(dealOtherSizing?.id),
    });
    setUpdateDealOtherSizingModalOpen(true);
  };

  const onUpdateDealOtherSizingClose = () => {
    setUpdateDealOtherSizingModalOpen(false);
  };

  const onUpdateProjectExpenseClick = (id: number) => {
    const selectedExpense = projectDetailsFat?.expenses.find(
      (e) => e.id === id,
    );
    if (selectedExpense) {
      const {
        expense_input_type,
        expense_unit_type,
        cash_basis_lag,
        expense_strip,
        start_date,
        escalator,
        base_year,
        acres,
        escalation_method,
        expense,
        rate,
        term,
        type,
        name,
        term_input_method,
        start_input_method,
        term_start_point,
        term_end_point,
        monthly_allocation_type,
      } = selectedExpense;
      setSelectedProjectExpenseToEdit(selectedExpense.id);
      const defaultBaseYear = getDefaultBaseYear(projectDetailsFat?.timing.cod);
      const formData: IProjectExpenseForm = {
        escalation_method: escalation_method || "",
        base_year: base_year || defaultBaseYear,
        acres: acres || "",
        expense: expense || "",
        rate: rate || "",
        expense_strip: expense_strip?.length
          ? expense_strip
          : new Array(projectDateSchedule.length || 0).fill(0),
        escalator,
        expense_input_type,
        start_input_method: start_input_method || "",
        term_start_point: term_start_point || "",
        expense_unit_type: expense_unit_type || null,
        cash_basis_lag: cash_basis_lag,
        start_date: start_date || "",
        term_end_point: term_end_point || "",
        term_input_method: term_input_method || "",
        term: term || "",
        type,
        name: name || "",
        monthly_allocation_type: monthly_allocation_type || "",
        dollars_per_yr_percentage: "",
      };
      setProjectExpenseForm(formData);
      setUpdateProjectExpenseModalOpen(true);
    }
  };

  const onDeleteExpenseClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Expense",
        description: "Are you sure you want to delete this expense?",
        onConfirm: () => handleDeleteExpense(id),
      }),
    );
  };

  const { callAPI: deleteProjectCreditSupportCallAPI } = useAPI(
    (projectId: number, creditSupportId: number) =>
      deleteProjectCreditSupport(projectId, creditSupportId),
  );

  const {
    callAPI: addProjectCreditSupportCallAPI,
    fieldErrors: addProjectCreditSupportFormErrors,
    setFieldErrors: setAddProjectCreditSupportFormErrors,
    loading: addProjectCreditSupportLoading,
  } = useAPI<IProjectCreditSupport, IAddProjectCreditSupportFormErrors>(
    (projectId: number, form: IAddProjectCreditSupportForm) =>
      addProjectCreditSupport(projectId, form),
  );

  const {
    callAPI: updateProjectCreditSupportCallAPI,
    fieldErrors: updateProjectCreditSupportFormErrors,
    setFieldErrors: setUpdateProjectCreditSupportFormErrors,
    loading: updateProjectCreditSupportLoading,
  } = useAPI<IProjectCreditSupport, IUpdateProjectCreditSupportFormErrors>(
    (
      projectId: number,
      creditSupportId: number,
      form: IUpdateProjectCreditSupportForm,
    ) => editProjectCreditSupport(projectId, creditSupportId, form),
  );

  const handleOnUpdateCreditSupport = async (
    form: IUpdateProjectCreditSupportForm,
  ) => {
    const credit_support = await updateProjectCreditSupportCallAPI(
      Number(selectedProject),
      Number(selectedCreditSupportToEdit),
      form,
    );

    if (credit_support) {
      getProjectDetailsFatCallAPI(Number(selectedProject));
    }
    return credit_support;
  };

  const handleCloseUpdateCreditSupportModal = () => {
    setUpdateCreditSupportModalOpen(false);
  };

  const handleCloseAddCreditSupportModal = () => {
    setAddCreditSupportModalOpen(false);
  };

  const handleOnAddCreditSupport = async (
    form: IAddProjectCreditSupportForm,
  ) => {
    const credit_support = await addProjectCreditSupportCallAPI(
      Number(selectedProject),
      form,
    );
    if (credit_support) {
      getProjectDetailsFatCallAPI(Number(selectedProject));
    }
    return credit_support;
  };

  const handleDeleteCreditSupport = async (id: number) => {
    const credit_support = await deleteProjectCreditSupportCallAPI(
      Number(selectedProject),
      id,
    );
    if (credit_support) {
      getProjectDetailsFatCallAPI(Number(selectedProject));
    }
  };

  const onDeleteCreditSupportClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Credit Support",
        description: "Are you sure you want to delete this credit support?",
        onConfirm: () => handleDeleteCreditSupport(id),
      }),
    );
  };

  const handleOpenUpdateCreditSupportModal = (id: number) => {
    const selectedCreditSupport = projectDetailsFat?.credit_supports.find(
      (e) => e.id === id,
    );
    if (selectedCreditSupport) {
      const {
        obligation_type,
        name,
        credit_support_method,
        input_type,
        credit_support_amount,
        start_date_type,
        credit_support_start_date,
        credit_support_start_point,
        end_date_type,
        credit_support_end_date,
        credit_support_end_point,
        letter_of_credit_fee,
        yearfrac_convention,
      } = selectedCreditSupport;
      setSelectedCreditSupportToEdit(selectedCreditSupport.id);
      const formData: IUpdateProjectCreditSupportForm = {
        obligation_type: obligation_type,
        name: name,
        credit_support_method: credit_support_method,
        input_type: input_type,
        credit_support_amount: credit_support_amount,
        start_date_type: start_date_type,
        credit_support_start_date: credit_support_start_date,
        credit_support_start_point: credit_support_start_point,
        end_date_type: end_date_type,
        credit_support_end_date: credit_support_end_date,
        credit_support_end_point: credit_support_end_point,
        letter_of_credit_fee: letter_of_credit_fee,
        yearfrac_convention: yearfrac_convention,
      };
      setCreditSupportForm(formData);
      setUpdateCreditSupportModalOpen(true);
    }
  };

  const handleOpenAddCreditSupportModal = () => {
    setCreditSupportForm((prevState) => ({
      ...prevState,
      obligation_type: "",
      credit_support_method: "",
      input_type: "",
      credit_support_amount: "",
      start_date_type: "",
      credit_support_start_date: null,
      credit_support_start_point: "",
      end_date_type: "MD",
      credit_support_end_date: null,
      credit_support_end_point: "COD",
      letter_of_credit_fee: "",
      yearfrac_convention: "",
    }));
    setAddCreditSupportModalOpen(true);
  };

  const handleDeleteExpense = async (id: number) => {
    const expense = await deleteExpenseCallAPI(Number(selectedProject), id);

    if (expense) {
      getProjectDetailsFatCallAPI(Number(selectedProject));
    }
  };

  const onUpdateProjectEBITDAClick = () => {
    if (projectDetailsFat?.schedule_override) {
      const { created, created_by, id, modified, project, ...formDetails } =
        projectDetailsFat?.schedule_override;
      setProjectEBITDAForm({ ...formDetails });
      setUpdateEBITDAModalOpen(true);
    }
  };

  const onDeletProjectEbitda = async () => {
    const {
      id: projectId,
      schedule_override: { id: ebitdaId },
    } = projectDetailsFat!;
    // not calling the delete api. instead calling update api and sending ebitda as null
    const updatedEbitda = await updateProjectEBITDACallAPI(
      projectId,
      ebitdaId,
      {
        ebitda: null,
      },
    );
    updatedEbitda && getProjectDetailsFatCallAPI(projectId);
  };

  const onDeleteProjectEbitdaClick = () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete EBITDA",
        description: "Are you sure you want to delete?",
        onConfirm: onDeletProjectEbitda,
      }),
    );
  };

  const toggleShowMoreTaxCreditProjects = () => {
    if (dealDetailsFat?.transfer) {
      setVisibleTaxCreditProjects((prevState) =>
        prevState === 3 ? dealDetailsFat.transfer.length : 3,
      );
    }
  };

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleAddRevenueContractClick = async () => {
    if (projectDetailsFat) {
      await handleGetCurves(["PC"]);
      const { cod } = projectDetailsFat.timing;
      const defaultBaseYear = getDefaultBaseYear(cod);
      setProjectRevenueForm((prevState) => ({
        ...prevState,
        start_date: cod,
        base_year: defaultBaseYear,
        term_input_method: "YR",
        start_input_method: "SP",
        term_start_point: "COD",
        term_end_point: "PEOL",
        revenue_curve: new Array(projectDateSchedule.length).fill(0),
      }));
    }
    setAddRevenueContractModalOpen(true);
  };

  const handleAddMerchantRevenueClick = async () => {
    if (projectDetailsFat) {
      await handleGetCurves(["PC", "ELCC"]);
      const defaultBaseYear = getDefaultBaseYear(projectDetailsFat.timing.cod);
      setMerchantRevenueForm((prevState) => ({
        ...prevState,
        base_year: defaultBaseYear,
        revenue_curve: new Array(projectDateSchedule.length).fill(0),
      }));
    }
    setAddMerchantRevenueModalOpen(true);
  };

  const handleCloseAddRevenueContractModal = () => {
    setAddRevenueContractModalOpen(false);
  };

  const handleCloseEditProjectRevenueContractModal = () => {
    setSelectedProjectRevenueToEdit(undefined);
    setProjectEditRevenueContractModalOpen(false);
  };

  const handleCloseEditTimingModal = () => {
    setEditProjectTimingModalOpen(false);
  };

  const handleCloseUpdateProjectCostModal = () => {
    setUpdateProjectCostModalOpen(false);
  };

  const handleCloseUpdateProductionModal = () => {
    setUpdateProjectProductionModalOpen(false);
  };

  const handleCloseUpdateTaxCreditModal = () => {
    setUpdateProjectTaxCreditModalOpen(false);
  };

  const handleCloseAddMerchantRevenueModal = () => {
    setAddMerchantRevenueModalOpen(false);
  };

  const handleCloseUpdateMerchantRevenueModal = () => {
    setSelectedMerchantRevenueToEdit(undefined);
    setUpdateMerchantRevenueModalOpen(false);
  };

  const handleCloseAddExpenseModal = () => {
    setAddProjectExpenseModalOpen(false);
  };

  const handleCloseUpdateProjectExpenseModal = () => {
    setUpdateProjectExpenseModalOpen(false);
  };

  const handleCloseUpdateProjectEBITDAModal = () => {
    setUpdateEBITDAModalOpen(false);
  };

  const handleCloseAddDealDebtModal = () => {
    setAddDealDebtModalOpen(false);
  };

  const handleCloseEditDealDebtModal = () => {
    setEditDealDebtModalOpen(false);
  };

  const handleCloseDepreciationModal = () => {
    setEditDepreciationModalOpen(false);
  };

  const handleCloseEditDealTaxEquityModal = () => {
    setEditDealTaxEquityModalOpen(false);
  };

  const handleOpenAddCostModal = async () => {
    await getCostDateSchedule();
    setOpenAddCostModal(true);
  };

  const getCostDateSchedule = async () => {
    const { id: projectId } = projectDetailsFat!;
    getProjectTimingDateScheduleCallAPI(projectId).then((res) => {
      res && setCostDateSchedule(res.data.date_schedule);
    });
  };

  const { callAPI: getProjectTimingDateScheduleCallAPI } = useAPI<
    IProjectTimingDateScheduleResults,
    ICostItemFormErrors
  >((projectId) =>
    getProjectTimingDateSchedule({ period_type: "PRE_COD" }, projectId),
  );

  const { callAPI: deleteProjectDepreciationStreamCallAPI } = useAPI(
    (projectId: number, depreciationId: number, streamId: number) =>
      deleteProjectDepreciationStream(projectId, depreciationId, streamId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateProjectDepreciationStreamCallAPI,
    fieldErrors: updateProjectDepreciationStreamFormErrors,
    setFieldErrors: setUpdateProjectDepreciationStreamFormErrors,
    loading: updateDepreciationStreamLoading,
  } = useAPI<
    IProjectDepreciationStream,
    IUpdateProjectDepreciationStreamFormErrors
  >(
    (
      projectId: number,
      depreciationId: number,
      streamId: number,
      form: IUpdateProjectDepreciationStreamForm,
    ) =>
      updateProjectDepreciationStream(
        projectId,
        depreciationId,
        streamId,
        form,
      ),
  );

  const handleAddDepreciationStream = async (
    form: IUpdateProjectDepreciationStreamForm,
  ) => {
    const {
      id: projectId,
      depreciation: { id: depreciationId },
    } = projectDetailsFat!;

    const depreciationStream = await addProjectDepreciationStreamCallAPI(
      projectId,
      depreciationId,
      form,
    );
    depreciationStream && getProjectDetailsFatCallAPI(Number(projectId));
    return depreciationStream;
  };

  const handleUpdateDepreciationStream = async (
    form: IUpdateProjectDepreciationStreamForm,
  ) => {
    const { id: projectId } = projectDetailsFat!;

    const depreciationStream = await updateProjectDepreciationStreamCallAPI(
      projectId,
      Number(selectedStreamToUpdate?.depreciationId),
      Number(selectedStreamToUpdate?.streamId),
      form,
    );
    depreciationStream && getProjectDetailsFatCallAPI(projectId);
    return depreciationStream;
  };

  const handleCloseAddCostModal = () => {
    setOpenAddCostModal(false);
  };

  const onAddCost = async (form: ICostItemForm) => {
    if (projectDetailsFat) {
      const cost = await createCostItemCallAPI(
        form,
        projectDetailsFat?.id,
        projectDetailsFat?.cost.project_cost.id,
      );
      cost && getProjectDetailsFatCallAPI(Number(projectDetailsFat.id));
      return cost;
    }
    return undefined;
  };

  const handleAddExpenseClick = () => {
    if (projectDetailsFat) {
      const { cod } = projectDetailsFat.timing;
      const defaultBaseYear = getDefaultBaseYear(cod);
      setProjectExpenseForm((prevState) => ({
        ...prevState,
        start_date: cod,
        base_year: defaultBaseYear,
        expense_input_type: "NUMBER",
        expense_unit_type: "DOLPERKWYR",
        cash_basis_lag: 0,
        term_input_method: "EP",
        start_input_method: "SP",
        term_start_point: "COD",
        rate: 100,
        term_end_point: "PEOL",
        expense_strip: new Array(projectDateSchedule.length || 0).fill(0),
      }));
    }
    setAddProjectExpenseModalOpen(true);
  };

  const openDealTaxCreditPortfolioEditModal = () => {
    setUpdateDealTaxCreditTranferConstraintsModalOpen(true);
    setDealTaxCreditTranferConstraintsForm({
      transfer_maximum_payment:
        dealDetailsFat?.deal_transfer_config?.transfer_maximum_payment ?? "",
      transfer_minimum_roi:
        dealDetailsFat?.deal_transfer_config?.transfer_minimum_roi ?? "",
      transfer_discount_rate:
        dealDetailsFat?.deal_transfer_config?.transfer_discount_rate ?? "",
    });
  };

  const handleOpenUpdateSizingSponsorEquityFormModal = async () => {
    if (dealDetailsFat?.sponsor_equity_configuration) {
      const {
        after_tax_discount_rate,
        carryforward_credits_and_losses,
        federal_tax_rate,
        federal_tax_rate_change_year,
        new_federal_tax_rate,
        state_tax_rate,
        pre_tax_discount_rate,
        distribution_periodicity,
        is_partnership_deal,
      } = dealDetailsFat?.sponsor_equity_configuration;
      setUpdateDealSponsorEquityForm({
        after_tax_discount_rate,
        carryforward_credits_and_losses,
        federal_tax_rate,
        federal_tax_rate_change_year,
        new_federal_tax_rate,
        state_tax_rate,
        pre_tax_discount_rate,
        change_in_federal_tax_rate: isNumber(
          new_federal_tax_rate || federal_tax_rate_change_year,
        )
          ? "Y"
          : "N",
        distribution_periodicity,
        is_partnership_deal,
      });
      setUpdateDealSponsorEquityModalOpen(true);
    }
  };

  const handleOnEditDealSponsorEquity = async (
    form: IDealSponsorEquityForm,
  ) => {
    if (dealDetailsFat) {
      const {
        id: dealId,
        sponsor_equity_configuration: { id: sponsorEquityId },
      } = dealDetailsFat;
      const sponsorEquity = await updateDealSponsorEquityCallAPI(
        Number(dealId),
        Number(sponsorEquityId),
        form,
      );
      sponsorEquity && getDealDetailsFatCallAPI(dealId);
      return sponsorEquity;
    }
    return undefined;
  };

  const handleCloseUpdateSizingSponsorEquityFormModal = async () => {
    setUpdateDealSponsorEquityModalOpen(false);
  };

  const currentOgCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves.map((c) => ({
      label: c.name,
      value: String(c.id),
    }));
  }, [currentOrgCurves]);

  // Below two options are for merchant revenue forms
  const priceCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves
      .filter((c) => c.curve_type === "PC")
      .map((c) => ({
        label: c.name,
        value: String(c.id),
      }));
  }, [currentOrgCurves]);

  const elccCurvesOptions: ISelectOption[] = React.useMemo(() => {
    return currentOrgCurves
      .filter((c) => c.curve_type === "ELCC")
      .map((c) => ({
        label: c.name,
        value: String(c.id),
      }));
  }, [currentOrgCurves]);

  const handleOpenAddStreamModal = () => {
    setProjectOtherFlowForm((prevState) => ({
      ...prevState,
      stream_strip: new Array(projectDateSchedule.length).fill(0),
    }));
    setAddProjectStreamModalOpen(true);
  };

  const handleCloseAddStreamModal = () => {
    setAddProjectStreamModalOpen(false);
  };

  const handleOnDeleteDepreciationClick = async (
    stream: IProjectDepreciationStream,
  ) => {
    setSelectedStreamToUpdate({
      depreciationId: stream.project_depreciation,
      streamId: stream.id,
    });
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Depreciation Stream",
        description:
          "Are you sure you want to delete this depreciation stream?",
        onConfirm: () => handleOnDeleteDepreciationStream(stream),
      }),
    );
  };

  const handleOnDeleteDepreciationStream = async (
    stream: IProjectDepreciationStream,
  ) => {
    const response = await deleteProjectDepreciationStreamCallAPI(
      Number(projectDetailsFat?.id),
      stream.project_depreciation,
      stream.id,
    );
    response && getProjectDetailsFatCallAPI(Number(projectDetailsFat?.id));
  };

  const handleOnUpdateDealFee = async (form: IDealFeeForm) => {
    if (dealDetailsFat) {
      const { id: dealId } = dealDetailsFat;
      const fee = await updateDealFeeCallAPI(
        Number(dealId),
        Number(selectedDealFeeToEdit),
        form,
      );
      if (fee) {
        getDealDetailsFatCallAPI(Number(dealId));
      }
      return fee;
    }
    return undefined;
  };

  const handleOpenAddDealFeeModal = () => {
    setDealFeeForm({
      fee_type: "",
      name: "",
      amount: null,
      allocation_method: "",
      input_type: "DL",
      spend_method: "MD",
      milestone: "PNTP",
      fee_multiplier: 100,
      amortization_term: null,
      cost_weighting_method: "",
    });
    setAddDealFeeModalOpen(true);
  };

  const handleOnAddDealFee = async (form: IDealFeeForm) => {
    if (dealDetailsFat) {
      const { id: dealId } = dealDetailsFat;
      const fee = await addDealFeeCallAPI(Number(dealId), form);
      if (fee) {
        getDealDetailsFatCallAPI(Number(dealId));
      }
      return fee;
    }
    return undefined;
  };

  const debtFundingOptions = React.useMemo(() => {
    return {
      first_cod: dealDetailsFat?.detail.first_cod || "",
      last_cod: dealDetailsFat?.detail.last_cod || "",
    };
  }, [dealDetailsFat]);

  const handleOpenUpdateDealFeeModal = (fee: IDealFee) => {
    if (fee) {
      setSelectedDealFeeToEdit(fee.id);
      const formData: IDealFeeForm = {
        fee_type: fee.fee_type,
        name: fee.name,
        amount: fee.amount,
        allocation_method: fee.allocation_method,
        input_type: fee.input_type,
        spend_method: fee.spend_method,
        milestone: fee.milestone,
        fee_multiplier: fee.fee_multiplier,
        amortization_term: fee.amortization_term,
        cost_weighting_method: fee.cost_weighting_method,
      };
      setDealFeeForm(formData);
      setUpdateDealFeeModalOpen(true);
    }
  };

  const handleDeleteDealFee = async (id: number) => {
    if (dealDetailsFat) {
      const { id: dealId } = dealDetailsFat;
      const fee = await deleteDealFeeCallAPI(Number(dealId), id);
      if (fee) {
        getDealDetailsFatCallAPI(Number(dealId));
      }
    }
  };

  const handleOnDealFeeDeleteClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Fee",
        description: "Are you sure you want to delete this fee?",
        onConfirm: () => handleDeleteDealFee(id),
      }),
    );
  };

  const handleCloseAddDealFeeModal = () => {
    setAddDealFeeModalOpen(false);
  };

  const handleCloseUpdateDealFeeModal = () => {
    setUpdateDealFeeModalOpen(false);
  };

  const handleOpenAddDepreciationStreamModal = () => {
    setAddDepreciationStreamModalOpen(true);
  };

  const handleOpenEditDepreciationStreamModal = (
    stream: IProjectDepreciationStream,
  ) => {
    setSelectedStreamToUpdate({
      depreciationId: stream.project_depreciation,
      streamId: stream.id,
    });
    setProjectDepreciationForm({
      type: stream.type,
      percentage: stream.percentage,
      itc_eligibility: stream.itc_eligibility,
    });
    setUpdateDepreciationStreamModalOpen(true);
  };

  const handleCloseUpdateDepreciationStreamModal = () => {
    setSelectedStreamToUpdate(undefined);
    setUpdateDepreciationStreamModalOpen(false);
  };

  const handleCloseAddDepreciationStreamModal = () => {
    setAddDepreciationStreamModalOpen(false);
  };

  const projectDepreciationStreams: IProjectDepreciationStream[] =
    React.useMemo(() => {
      const streams =
        projectDetailsFat?.depreciation.streams.filter((d) =>
          PROJECT_DEPRECIATION_STREAM_TYPE[d.type].toLowerCase(),
        ) || [];

      // Move NONDEP steam type to the bottom of list
      const nonDepIndex = streams.findIndex(
        (stream) => stream.type === "NONDEP",
      );
      if (nonDepIndex !== -1) {
        const nonDepStream = streams.splice(nonDepIndex, 1)[0];
        streams.push(nonDepStream);
      }

      return streams;
    }, [projectDetailsFat]);

  const handleGetActionButton = () => {
    const actionButtons = [];
    if (!dealDetailsFat?.deal_construction_debt_general_sizing) {
      actionButtons.push(
        <MuiButton
          onClick={handleOpenAddGeneralAssumptionsModal}
          classes={{ root: styles.classes.muiButton }}
        >
          + Add General Assumption
        </MuiButton>,
      );
    } else {
      if (!dealDetailsFat?.deal_construction_debt) {
        actionButtons.push(
          <MuiButton
            onClick={handleOpenAddConstructionLoanModal}
            classes={{ root: styles.classes.muiButton }}
          >
            + Add Construction Loan
          </MuiButton>,
        );
      }
      if (!dealDetailsFat?.deal_investor_bridge_debt) {
        actionButtons.push(
          <MuiButton
            onClick={handleOpenAddInvestorBridgeLoanModal}
            classes={{ root: styles.classes.muiButton }}
          >
            + Add Investor Bridge Loan
          </MuiButton>,
        );
      }
      {
        /* If there is no transfer bridge loan and if the deal is transfer or direct pay only then show the add transfer bridge loan button */
      }
      if (
        !dealDetailsFat?.deal_transfer_bridge_debt &&
        ["TR", "DP"].includes(dealDetailsFat.tax_credit_structure)
      ) {
        actionButtons.push(
          <MuiButton
            onClick={handleOpenAddTransferBridgeLoanModal}
            classes={{ root: styles.classes.muiButton }}
            disabled // Disable transfer debt always (for now)
          >
            + Add Transfer Bridge Loan
          </MuiButton>,
        );
      }
    }
    return (
      <Box className={cn("flex flex-col gap-1 items-start")}>
        <ConditionalProtect type="deal">{actionButtons}</ConditionalProtect>
      </Box>
    );
  };

  return (
    <>
      <ViewWrapper
        error={getDealCaseDetailsErrored}
        loading={getDealCaseDetailsLoading}
      >
        <Box className={styles.classes.dealDetailsContainer}>
          <ViewWrapper
            error={getDealDetailsFatErrored}
            loading={getDealDetailsFatLoading}
          >
            {dealDetailsFat && projectDetailsFat && (
              <Box className={styles.classes.dealDetailsWrapper}>
                <Box className={styles.classes.container}>
                  <Box className={styles.classes.dealHeadingContainer}>
                    <SwapIcon fontSize="small" />
                    <Typography>Deal</Typography>
                  </Box>

                  <Box
                    className={styles.classes.blocksContainer}
                    marginBottom={1.5}
                  >
                    {dealDetailsFat.partnership_investor_configuration && (
                      <DetailsCard
                        heading="Investor"
                        onEditClick={
                          caseDetails?.type !== "SENS"
                            ? onUpdateDealTaxEquityClick
                            : undefined
                        }
                        sections={getCaseDealBlockDetails(
                          dealDetailsFat,
                          "investor",
                        )}
                        autoHeight
                      />
                    )}
                    <Box className={styles.classes.verticalAlignedBlocks}>
                      <Box>
                        <DetailsCard
                          heading="Term Debt"
                          onEditClick={
                            dealDetailsFat.debt && caseDetails?.type !== "SENS"
                              ? onUpdateDealDebtClick
                              : undefined
                          }
                          sections={getCaseDealBlockDetails(
                            dealDetailsFat,
                            "debt",
                          )}
                          actionButton={
                            caseDetails?.type !== "SENS" ? (
                              <MuiButton
                                onClick={onDealDebtActionBtnClick}
                                startIcon={
                                  dealDetailsFat.debt ? (
                                    <DeleteIcon />
                                  ) : (
                                    <AddIcon />
                                  )
                                }
                                classes={{
                                  root: [
                                    styles.classes.actionBtnOnCard,
                                    styles.classes[
                                      !dealDetailsFat.debt
                                        ? "addBtnOnCard"
                                        : "deleteBtnOnCard"
                                    ],
                                  ].join(" "),
                                }}
                              >
                                {!dealDetailsFat.debt ? "Add" : "Delete"} Debt
                              </MuiButton>
                            ) : undefined
                          }
                        />
                      </Box>
                      {dealDetailsFat.depreciation && (
                        <DetailsCard
                          heading="Depreciation Election"
                          sections={[
                            {
                              fields: Object.keys(
                                dealDetailsFat.depreciation,
                              ).map((depKey) => {
                                const text =
                                  DEAL_DEPRECIATION_ELECTION[
                                    dealDetailsFat.depreciation[depKey][0]
                                      .macrs_election as keyof typeof DEAL_DEPRECIATION_ELECTION
                                  ];
                                return {
                                  label: `${depKey} projects`,
                                  value: {
                                    text,
                                    onEditClick:
                                      caseDetails?.type !== "SENS"
                                        ? () =>
                                            onUpdateDealDepreciationClick(
                                              String(
                                                dealDetailsFat.depreciation[
                                                  depKey
                                                ][0].macrs_election,
                                              ),
                                              depKey,
                                            )
                                        : undefined,
                                  },
                                };
                              }),
                            },
                          ]}
                        />
                      )}
                      <DetailsCard
                        heading="Other"
                        sections={[
                          {
                            fields: [
                              {
                                label: "Expense Allocation Method",
                                value: {
                                  text: DEAL_OTHER_SIZING[
                                    dealOtherSizing?.expense_allocation_method as keyof typeof DEAL_OTHER_SIZING
                                  ],
                                  onEditClick:
                                    caseDetails?.type !== "SENS"
                                      ? onUpdateDealOtherSizingClick
                                      : undefined,
                                },
                              },
                            ],
                          },
                        ]}
                      />
                      <DetailsCard
                        heading="Construction Debt"
                        height="100%"
                        sections={[
                          ...getCaseDealBlockDetails(
                            dealDetailsFat,
                            "construction-debt",
                          ),
                        ]
                          .concat(
                            dealDetailsFat.deal_construction_debt
                              ? [
                                  {
                                    heading: "Construction Loan",
                                    onEditClick:
                                      caseDetails?.type !== "SENS"
                                        ? handleOpenEditConstructionLoanModal
                                        : undefined,
                                    onDeleteClick:
                                      caseDetails?.type !== "SENS"
                                        ? handleOnDeleteConstructionLoan
                                        : undefined,
                                    fields: [
                                      {
                                        label: "Base Rate Type",
                                        value: {
                                          text: CONSTRUCTION_DEBT_BASE_RATE_TYPE[
                                            dealDetailsFat
                                              .deal_construction_debt
                                              .base_rate_type
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ]
                              : [],
                          )
                          .concat(
                            dealDetailsFat.deal_investor_bridge_debt
                              ? [
                                  {
                                    heading: "Investor Bridge Loan",
                                    onEditClick:
                                      caseDetails?.type !== "SENS"
                                        ? handleOpenEditInvestorBridgeLoanModal
                                        : undefined,
                                    onDeleteClick:
                                      caseDetails?.type !== "SENS"
                                        ? handleOnDeleteInvestorBridge
                                        : undefined,
                                    fields: [
                                      {
                                        label: "Base Rate Type",
                                        value: {
                                          text: CONSTRUCTION_DEBT_BASE_RATE_TYPE[
                                            dealDetailsFat
                                              .deal_investor_bridge_debt
                                              .base_rate_type
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ]
                              : [],
                          )
                          .concat(
                            dealDetailsFat.deal_transfer_bridge_debt
                              ? [
                                  {
                                    heading: "Transfer Bridge Loan",
                                    onEditClick:
                                      caseDetails?.type !== "SENS"
                                        ? handleOpenEditTransferBridgeLoanModal
                                        : undefined,
                                    onDeleteClick:
                                      caseDetails?.type !== "SENS"
                                        ? handleOnDeleteTransferBridge
                                        : undefined,

                                    fields: [
                                      {
                                        label: "Base Rate Type",
                                        value: {
                                          text: CONSTRUCTION_DEBT_BASE_RATE_TYPE[
                                            dealDetailsFat
                                              .deal_transfer_bridge_debt
                                              .base_rate_type
                                          ],
                                        },
                                      },
                                    ],
                                  },
                                ]
                              : [],
                          )}
                        onEditClick={
                          dealDetailsFat.deal_construction_debt_general_sizing &&
                          caseDetails?.type !== "SENS"
                            ? handleOpenEditGeneralAssumptionsModal
                            : undefined
                        }
                        actionButton={
                          caseDetails?.type !== "SENS"
                            ? handleGetActionButton()
                            : undefined
                        }
                      />
                    </Box>
                    <Box className={styles.classes.verticalAlignedBlocks}>
                      {dealDetailsFat.transfer.length ? (
                        dealDetailsFat?.tax_credit_structure === "TR" ? (
                          <DetailsCard
                            actionButton={
                              dealDetailsFat.transfer.length > 3 ? (
                                <MuiButton
                                  onClick={toggleShowMoreTaxCreditProjects}
                                  classes={{
                                    root: styles.classes.seeMoreButton,
                                  }}
                                >
                                  {visibleTaxCreditProjects ===
                                  dealDetailsFat.transfer.length
                                    ? "Show Less"
                                    : "Show More"}
                                </MuiButton>
                              ) : undefined
                            }
                            heading="Transfer"
                            sections={dealDetailsFat.transfer
                              .slice(0, visibleTaxCreditProjects)
                              .map((transfer) => {
                                return {
                                  heading: transfer.project.name,
                                  onEditClick:
                                    caseDetails?.type !== "SENS"
                                      ? () =>
                                          onUpdateDealTaxCreditClick(transfer)
                                      : undefined,
                                  fields: [
                                    {
                                      label: "Transfer Portion",
                                      value: {
                                        text:
                                          transfer.transfer_sizing
                                            .transfer_portion + " %",
                                      },
                                    },
                                    {
                                      label: "Payment Rate",
                                      value: {
                                        text:
                                          Number(
                                            transfer.transfer_sizing
                                              .payment_rate ?? 0,
                                          ).toFixed(4) + " $/credit",
                                      },
                                    },
                                  ],
                                };
                              })}
                            onEditClick={
                              caseDetails?.type !== "SENS"
                                ? openDealTaxCreditPortfolioEditModal
                                : undefined
                            }
                          />
                        ) : (
                          <DetailsCard
                            actionButton={
                              dealDetailsFat.transfer.length > 3 ? (
                                <MuiButton
                                  onClick={toggleShowMoreTaxCreditProjects}
                                  classes={{
                                    root: styles.classes.seeMoreButton,
                                  }}
                                >
                                  {visibleTaxCreditProjects ===
                                  dealDetailsFat.transfer.length
                                    ? "Show Less"
                                    : "Show More"}
                                </MuiButton>
                              ) : undefined
                            }
                            heading="Direct Pay"
                            sections={dealDetailsFat.transfer
                              .slice(0, visibleTaxCreditProjects)
                              .map((transfer) => {
                                return {
                                  heading: transfer.project.name,
                                  onEditClick:
                                    caseDetails?.type !== "SENS"
                                      ? () =>
                                          onUpdateDealTaxCreditClick(transfer)
                                      : undefined,
                                  fields: [
                                    {
                                      label: "Direct Pay Portion",
                                      value: {
                                        text:
                                          transfer.transfer_sizing
                                            .transfer_portion + " %",
                                      },
                                    },
                                    {
                                      label: "Payment Rate",
                                      value: {
                                        text:
                                          Number(
                                            transfer.transfer_sizing
                                              .payment_rate ?? 0,
                                          ).toFixed(4) + " $/credit",
                                      },
                                    },
                                  ],
                                };
                              })}
                          />
                        )
                      ) : null}
                      {dealDetailsFat?.timing && (
                        <DetailsCard
                          heading="Timing"
                          sections={getCaseDealBlockDetails(
                            dealDetailsFat,
                            "timing",
                          )}
                          onEditClick={
                            caseDetails?.type !== "SENS"
                              ? onUpdateDealTimingClick
                              : undefined
                          }
                        />
                      )}
                      {dealDetailsFat?.sponsor_equity_configuration && (
                        <DetailsCard
                          heading="Sponsor Equity"
                          sections={getCaseDealBlockDetails(
                            dealDetailsFat,
                            "sponsor-equity",
                          )}
                          onEditClick={
                            caseDetails?.type !== "SENS"
                              ? handleOpenUpdateSizingSponsorEquityFormModal
                              : undefined
                          }
                        />
                      )}

                      <CaseDetailsTable
                        title="Fees"
                        columnHeaders={getTableColumnAccordingToStatus(
                          DEAL_FEES_TABLE_COLUMN_HEADERS,
                          caseDetails?.parent_deal?.status as string,
                        )}
                        addButtonProps={{
                          label: "+ ADD Deal Fee",
                          onClick: handleOpenAddDealFeeModal,
                        }}
                      >
                        {dealDetailsFat.deal_fees.map((fee, idx) => {
                          return (
                            <TableRow
                              key={idx}
                              classes={{
                                root: styles.classes.tableRow,
                              }}
                            >
                              <TableCell align="left">
                                {DEAL_FEE_TYPES[fee.fee_type]}
                              </TableCell>
                              <TableCell align="left">
                                {fee.name || "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                {
                                  DEAL_FEE_ALLOCATION_METHODS[
                                    fee.allocation_method
                                  ]
                                }
                              </TableCell>
                              <TableCell align="left">
                                {numberToUSD.format(fee?.amount ?? 0)}
                              </TableCell>
                              <TableCell align="right" style={{ padding: 0 }}>
                                <ConditionalProtect type="deal">
                                  <PopoverMenu
                                    uniqueId={idx}
                                    canOpenUpgrade
                                    items={[
                                      {
                                        label: "Edit",
                                        onClick: () =>
                                          handleOpenUpdateDealFeeModal(fee),
                                      },
                                      {
                                        label: "Delete",
                                        onClick: () =>
                                          handleOnDealFeeDeleteClick(fee.id),
                                      },
                                    ]}
                                  />
                                </ConditionalProtect>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </CaseDetailsTable>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
          </ViewWrapper>
        </Box>

        {caseDetails?.child_deal_projects.length ? (
          <>
            <Typography variant="h6">Projects</Typography>

            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Tabs
                tabs={caseDetails.child_deal_projects.slice(0, 10).map((p) => ({
                  label: String(trimString(p.name, 16)),
                  value: String(p.id),
                }))}
                onClick={handleProjectChange}
                selectedTab={String(selectedProject)}
              />
              {caseDetails.child_deal_projects.length > 10 && (
                <PopoverMenu
                  uniqueId={999}
                  icon={<DoubleArrowRightIcon />}
                  items={caseDetails.child_deal_projects.slice(10).map((p) => ({
                    label: String(trimString(p.name, 20)),
                    onClick: () => hadleProjectChangeFromPopover(p.id),
                  }))}
                />
              )}
            </Box>

            <Box className={styles.classes.projectDetailsContainer}>
              <ViewWrapper
                error={getProjectDetailsFatErrored}
                loading={getProjectDetailsFatLoading}
              >
                {projectDetailsFat && (
                  <Box className={styles.classes.container}>
                    <Box className={styles.classes.dealHeadingContainer}>
                      <BulbIcon fontSize="small" />
                      <Typography>{projectDetailsFat.name}</Typography>
                    </Box>
                    <Box className={styles.classes.projectGrid}>
                      <Box className={styles.classes.verticalAlignedBlocks}>
                        <DetailsCard
                          heading="Timing"
                          onEditClick={onUpdateProjectTimingClick}
                          height="100%"
                          sections={getCaseProjectBlockDetails(
                            projectDetailsFat,
                            "timing",
                          )}
                        />
                        <DetailsCard
                          heading="Production"
                          onEditClick={onUpdateProjectProductionClick}
                          sections={getCaseProjectBlockDetails(
                            projectDetailsFat,
                            "production",
                            {
                              degradationCurveToggle,
                              setDegradationCurveToggle,
                              availabilityCurveToggle,
                              setAvailabilityCurveToggle,
                              curtailmentCurveToggle,
                              setCurtailmentCurveToggle,
                              lossCurveToggle,
                              setLossCurveToggle,
                            },
                          )}
                          autoHeight
                        />
                        <DetailsCard
                          heading="Tax Credit"
                          onEditClick={onUpdateProjectTaxCreditClick}
                          sections={getCaseProjectBlockDetails(
                            projectDetailsFat,
                            "tax-credit",
                          )}
                          autoHeight
                        />
                      </Box>
                      <Box>
                        <Paper>
                          <ProjectCosts
                            viewType="all"
                            title="Costs"
                            costItems={projectDetailsFat.cost.cost_items}
                            handleOpenAddCostModal={handleOpenAddCostModal}
                            handleUpdate={() =>
                              getProjectDetailsFatCallAPI(projectDetailsFat?.id)
                            }
                            projectId={projectDetailsFat.id.toString()}
                            projectCostId={projectDetailsFat.cost.project_cost.id.toString()}
                            withPadding
                          />
                          <DetailsCard
                            heading=""
                            sections={[
                              {
                                fields: [
                                  {
                                    label: "Total Project Cost",
                                    value: {
                                      text: numberWithCommas.format(
                                        projectDetailsFat.cost.project_cost
                                          .total_cost,
                                      ),
                                    },
                                  },
                                  {
                                    label: "FMV Step Up %",
                                    value: {
                                      text:
                                        projectDetailsFat.cost.project_cost
                                          .profit_percent + "%",
                                      onEditClick: onUpdateProjectCostClick,
                                    },
                                  },
                                  {
                                    label: "Unlevered FMV",
                                    value: {
                                      text: numberWithCommas.format(
                                        projectDetailsFat.cost.project_cost.fmv,
                                      ),
                                    },
                                  },
                                ],
                              },
                            ]}
                          />
                        </Paper>

                        <Box
                          className={styles.classes.verticalAlignedBlocks}
                          marginTop={1.5}
                        >
                          <CaseDetailsTable
                            title="Credit Support (During Construction)"
                            columnHeaders={getTableColumnAccordingToStatus(
                              DEAL_CREDIT_SUPPORTS_TABLE_COLUMN_HEADERS,
                              caseDetails?.parent_deal?.status as string,
                            )}
                            addButtonProps={{
                              label: "+ ADD CREDIT SUPPORT",
                              onClick: handleOpenAddCreditSupportModal,
                            }}
                          >
                            {projectDetailsFat.credit_supports.map((e, idx) => {
                              return (
                                <TableRow
                                  key={idx}
                                  classes={{
                                    root: styles.classes.tableRow,
                                  }}
                                >
                                  <TableCell align="left">
                                    {PROJECT_OBLICATION_TYPE[
                                      e?.obligation_type
                                    ] || ""}
                                  </TableCell>
                                  <TableCell align="left">
                                    {e.input_type == "DL"
                                      ? numberToUSD.format(
                                          e?.credit_support_amount,
                                        )
                                      : e?.credit_support_amount + " $/kWac"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {DATE_SELECTION_TYPES[e?.start_date_type]}
                                  </TableCell>
                                  <TableCell align="left">
                                    {e?.credit_support_start_date ?? "N/A"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {e?.start_date_type === "SPD"
                                      ? "N/A"
                                      : e?.credit_support_start_point}
                                  </TableCell>
                                  <TableCell align="left">
                                    {DATE_SELECTION_TYPES[e?.end_date_type]}
                                  </TableCell>
                                  <TableCell align="left">
                                    {e?.credit_support_end_date ?? "N/A"}
                                  </TableCell>
                                  <TableCell align="left">
                                    {e?.end_date_type === "SPD"
                                      ? "N/A"
                                      : e?.credit_support_end_point}
                                  </TableCell>
                                  <TableCell align="left">
                                    {
                                      PROJECT_CREDIT_SUPPORT_COST_METHOD[
                                        e?.credit_support_method
                                      ]
                                    }
                                  </TableCell>
                                  <TableCell align="left">
                                    {e?.credit_support_method === "LC"
                                      ? e?.letter_of_credit_fee + "%"
                                      : "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ padding: 0 }}
                                  >
                                    <ConditionalProtect type="deal">
                                      <PopoverMenu
                                        uniqueId={idx}
                                        canOpenUpgrade
                                        items={[
                                          {
                                            label: "Edit",
                                            onClick: () =>
                                              handleOpenUpdateCreditSupportModal(
                                                e.id,
                                              ),
                                          },
                                          {
                                            label: "Delete",
                                            onClick: () =>
                                              onDeleteCreditSupportClick(e.id),
                                          },
                                        ]}
                                      />
                                    </ConditionalProtect>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </CaseDetailsTable>
                        </Box>

                        <Box marginTop={1.5}>
                          <CaseDetailsTable
                            title="Depreciation"
                            columnHeaders={getTableColumnAccordingToStatus(
                              DEAL_DEPRECIATION_STREAMS_TABLE_COLUMN_HEADERS,
                              caseDetails?.parent_deal?.status as string,
                            )}
                            addButtonProps={{
                              label: "+ ADD DEPRECIATION",
                              onClick: handleOpenAddDepreciationStreamModal,
                            }}
                          >
                            {projectDepreciationStreams.map((stream, idx) => {
                              return (
                                <TableRow
                                  key={idx}
                                  classes={{
                                    root: styles.classes.tableRow,
                                  }}
                                >
                                  <TableCell>
                                    {
                                      PROJECT_DEPRECIATION_STREAM_TYPE[
                                        stream.type
                                      ]
                                    }
                                  </TableCell>
                                  <TableCell>
                                    {numberToUSD.format(stream.amount)}
                                  </TableCell>
                                  <TableCell>{stream.percentage}%</TableCell>
                                  <TableCell>
                                    {stream.is_itc_eligible
                                      ? (stream.itc_eligibility ?? 0) + "%"
                                      : ""}
                                  </TableCell>
                                  <TableCell>
                                    {stream.is_itc_eligible
                                      ? numberToUSD.format(
                                          stream.itc_eligible_amount ?? 0,
                                        )
                                      : ""}
                                  </TableCell>
                                  <TableCell align="right">
                                    <ConditionalProtect type="deal">
                                      <PopoverMenu
                                        uniqueId={idx}
                                        canOpenUpgrade
                                        items={[
                                          {
                                            label: "Edit",
                                            onClick: () =>
                                              handleOpenEditDepreciationStreamModal(
                                                stream,
                                              ),
                                          },
                                          {
                                            label: "Delete",
                                            onClick: () =>
                                              handleOnDeleteDepreciationClick(
                                                stream,
                                              ),
                                          },
                                        ]}
                                      />
                                    </ConditionalProtect>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </CaseDetailsTable>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      className={styles.classes.projectTableDetailsContainer}
                    >
                      <Box className={styles.classes.verticalAlignedBlocks}>
                        <CaseDetailsTable
                          title="Revenue Contract"
                          columnHeaders={getTableColumnAccordingToStatus(
                            DEAL_REVENUES_TABLE_COLUMN_HEADERS,
                            caseDetails?.parent_deal?.status as string,
                          )}
                          addButtonProps={{
                            label: "+ ADD REVENUE CONTRACT",
                            onClick: handleAddRevenueContractClick,
                          }}
                        >
                          {projectDetailsFat.revenue_contracts.map(
                            (rC, idx) => {
                              return (
                                <TableRow
                                  key={idx}
                                  classes={{
                                    root: styles.classes.tableRow,
                                  }}
                                >
                                  <TableCell>
                                    {rC.type === "CAPACITY" &&
                                    projectDetailsFat.energy_type === "BAST"
                                      ? "Tolling Agreement"
                                      : PROJECT_CONTRACTED_REVENUE_TYPE[
                                          rC.type
                                        ]}
                                  </TableCell>
                                  <TableCell>{rC.start_date}</TableCell>
                                  <TableCell>{rC.term_years}</TableCell>
                                  <TableCell>
                                    {numberToUSD.format(rC.price)}
                                  </TableCell>
                                  <TableCell>{rC.escalator}%</TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ padding: 0 }}
                                  >
                                    <ConditionalProtect type="deal">
                                      <PopoverMenu
                                        uniqueId={idx}
                                        canOpenUpgrade
                                        items={[
                                          {
                                            label: "Edit",
                                            onClick: () =>
                                              onUpdateProjectRevenueClick(
                                                rC.id,
                                              ),
                                          },
                                          {
                                            label: "Delete",
                                            onClick: () =>
                                              onDeleteRevenueClick(rC.id),
                                          },
                                        ]}
                                      />
                                    </ConditionalProtect>
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                        </CaseDetailsTable>
                        <CaseDetailsTable
                          title="Merchant Revenue"
                          columnHeaders={getTableColumnAccordingToStatus(
                            DEAL_MERCHANT_REVENUE_TABLE_COLUMN_HEADERS,
                            caseDetails?.parent_deal?.status as string,
                          )}
                          addButtonProps={{
                            label: "+ ADD MERCHANT REVENUE",
                            onClick: handleAddMerchantRevenueClick,
                          }}
                        >
                          {projectDetailsFat.merchant_revenues.map(
                            (mrC, idx) => {
                              return (
                                <TableRow
                                  key={idx}
                                  classes={{
                                    root: styles.classes.tableRow,
                                  }}
                                >
                                  <TableCell>
                                    {mrC.type === "CAPACITY" &&
                                    projectDetailsFat.energy_type === "BAST"
                                      ? "Merchant Capacity"
                                      : PROJECT_MERCHANT_REVENUE_TYPE[mrC.type]}
                                  </TableCell>
                                  <TableCell>{mrC.name}</TableCell>
                                  <TableCell>
                                    {mrC.price_curve != null ||
                                    mrC.type === "OTHER" ||
                                    mrC.price_input_type === "OC"
                                      ? "N/A"
                                      : mrC.price !== null
                                        ? numberToUSD.format(mrC.price) +
                                          (mrC.type === "CAPACITY"
                                            ? " / kW-Month"
                                            : " / MWh")
                                        : null}
                                  </TableCell>
                                  <TableCell>
                                    {mrC.type === "OTHER"
                                      ? "N/A"
                                      : mrC.escalator + "%"}
                                  </TableCell>
                                  <TableCell>
                                    {mrC.cash_basis_lag ?? "N/A"}
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ padding: 0 }}
                                  >
                                    <ConditionalProtect type="deal">
                                      <PopoverMenu
                                        uniqueId={idx}
                                        canOpenUpgrade
                                        items={[
                                          {
                                            label: "Edit",
                                            onClick: () =>
                                              onUpdateProjectMerchantRevenueClick(
                                                mrC.id,
                                              ),
                                          },
                                          {
                                            label: "Delete",
                                            onClick: () =>
                                              onDeleteMerchantRevenueClick(
                                                mrC.id,
                                              ),
                                          },
                                        ]}
                                      />
                                    </ConditionalProtect>
                                  </TableCell>
                                </TableRow>
                              );
                            },
                          )}
                        </CaseDetailsTable>
                        <DetailsCard
                          heading="Override EBITDA"
                          sections={[
                            {
                              fields: [
                                {
                                  label: "Override EBITDA",
                                  value: {
                                    text:
                                      projectDetailsFat.schedule_override
                                        .is_ebitda_active &&
                                      projectDetailsFat.schedule_override.ebitda
                                        ? "Yes"
                                        : "No",
                                    onEditClick: onUpdateProjectEBITDAClick,
                                    onDeleteClick: projectDetailsFat
                                      .schedule_override.ebitda
                                      ? onDeleteProjectEbitdaClick
                                      : undefined,
                                  },
                                },
                              ],
                            },
                          ]}
                        />

                        <CaseDetailsTable
                          title="Other Flows"
                          columnHeaders={getTableColumnAccordingToStatus(
                            DEAL_OTHER_FLOWS_TABLE_COLUMN_HEADERS,
                            caseDetails?.parent_deal?.status as string,
                          )}
                          addButtonProps={{
                            label: "+ ADD OTHER FLOW",
                            onClick: handleOpenAddStreamModal,
                          }}
                        >
                          {projectDetailsFat.other_flows.map((r, idx) => {
                            return (
                              <TableRow
                                key={idx}
                                classes={{
                                  root: styles.classes.tableRow,
                                }}
                              >
                                <TableCell>{r.name}</TableCell>
                                <TableCell>
                                  {PROJECT_OTHER_STREAM_TYPE[r.type]}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(r.created), "M/d/yyyy")}
                                </TableCell>
                                <TableCell>
                                  {format(new Date(r.modified), "M/d/yyyy")}
                                </TableCell>
                                <TableCell align="right" style={{ padding: 0 }}>
                                  <ConditionalProtect type="deal">
                                    <PopoverMenu
                                      uniqueId={idx}
                                      canOpenUpgrade
                                      items={[
                                        {
                                          label: "Edit",
                                          onClick: () =>
                                            handleOpenEditStreamModal(r.id),
                                        },
                                        {
                                          label: "Delete",
                                          onClick: () => onDeleteStream(r.id),
                                        },
                                      ]}
                                    />
                                  </ConditionalProtect>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </CaseDetailsTable>
                      </Box>

                      <Box className={styles.classes.verticalAlignedBlocks}>
                        <CaseDetailsTable
                          title="Expenses"
                          columnHeaders={getTableColumnAccordingToStatus(
                            DEAL_EXPENSES_TABLE_COLUMN_HEADERS,
                            caseDetails?.parent_deal?.status as string,
                          )}
                          addButtonProps={{
                            label: "+ ADD EXPENSE",
                            onClick: handleAddExpenseClick,
                          }}
                        >
                          {projectDetailsFat.expenses.map((e, idx) => {
                            return (
                              <TableRow
                                key={idx}
                                classes={{
                                  root: styles.classes.tableRow,
                                }}
                              >
                                <TableCell>
                                  {PROJECT_EXPENSE_TYPE[e.type]}
                                </TableCell>
                                <TableCell>
                                  {trimString(e?.name || "", 20)}
                                </TableCell>
                                <TableCell>
                                  {e.expense_input_type === "STRIP"
                                    ? "Schedule"
                                    : `${e.expense} ${
                                        PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT[
                                          e.expense_unit_type as keyof typeof PROJECT_EXPENSE_UNIT_TYPE_END_ADORNMENT
                                        ]
                                      }`}
                                </TableCell>
                                <TableCell>
                                  {e?.escalator !== null
                                    ? `${e?.escalator}%`
                                    : ""}
                                </TableCell>
                                <TableCell>{e.start_date}</TableCell>
                                <TableCell>{e.term}</TableCell>
                                <TableCell align="right" style={{ padding: 0 }}>
                                  <ConditionalProtect type="deal">
                                    <PopoverMenu
                                      uniqueId={idx}
                                      canOpenUpgrade
                                      items={[
                                        {
                                          label: "Edit",
                                          onClick: () =>
                                            onUpdateProjectExpenseClick(e.id),
                                        },
                                        {
                                          label: "Delete",
                                          onClick: () =>
                                            onDeleteExpenseClick(e.id),
                                        },
                                      ]}
                                    />
                                  </ConditionalProtect>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </CaseDetailsTable>
                      </Box>
                    </Box>
                  </Box>
                )}
              </ViewWrapper>
            </Box>
          </>
        ) : null}
      </ViewWrapper>

      <DealPartnershipInvestorConfigurationFormModal
        open={editDealTaxEquityModalOpen}
        onClose={handleCloseEditDealTaxEquityModal}
        loading={updateDealTaxEquityLoading}
        formErrors={updateDealTaxEquityFormErrors}
        setFormErrors={setUpdateDealTaxEquityFormErrors}
        form={dealTaxEquityForm}
        setForm={setDealTaxEquityForm}
        headerLabel={
          dealDetailsFat?.structure === "CEP"
            ? "Edit Cash Equity"
            : "Edit Tax Equity"
        }
        onConfirm={handleOnEditDealTaxEquity}
      />

      <DealTermDebtFormModal
        open={addDealDebtModalOpen}
        onClose={handleCloseAddDealDebtModal}
        formErrors={addDealTermDebtFormErrors}
        loading={addDealDebtLoading}
        setFormErrors={setAddDealTermDebtFormErrors}
        form={dealTermDebtForm}
        setForm={setDealTermDebtForm}
        headerLabel="Add Deal Term Debt"
        onConfirm={handleOnAddDealDebt}
        ircCurves={currentOgCurvesOptions}
        debtFundingDate={debtFundingOptions}
      />

      <DealTermDebtFormModal
        open={editDealDebtModalOpen}
        onClose={handleCloseEditDealDebtModal}
        form={dealTermDebtForm}
        loading={updateDealDebtLoading}
        formErrors={updateDealTermDebtFormErrors}
        setFormErrors={setUpdateDealTermDebtFormErrors}
        setForm={setDealTermDebtForm}
        headerLabel="Edit Deal Term Debt"
        onConfirm={handleOnEditDealDebt}
        ircCurves={currentOgCurvesOptions}
        debtFundingDate={debtFundingOptions}
      />

      <DealDepreciationElectionEditModal
        open={editDepreciationModalOpen}
        headerLabel="Edit Depreciation Election"
        loading={editDealDepreciationLoading}
        formErrors={editDealDepreciationFormErrors}
        setFormErrors={setEditDealDepreciationFormErrors}
        form={dealDepreciationForm}
        setForm={setDealDepreciationForm}
        onClose={handleCloseDepreciationModal}
        onConfirm={handleOnEditDealDepreciation}
      />

      <EditTaxCreditTransfer
        headerLabel="Edit Tax Credit Transfer"
        loading={loadingUpdateDealTaxCreditDetails}
        form={dealTaxCreditForm}
        setForm={setDealTaxCreditForm}
        onClose={() => setEditDealTaxCreditModal(false)}
        open={
          editDealTaxCreditModal &&
          dealDetailsFat?.tax_credit_structure === "TR"
        }
        formErrors={dealTaxCreditFormErrors}
        setFormErrors={setDealTaxCreditFormErrors}
        onConfirm={handleEditDealTaxCreditTransfer}
      />

      <EditTaxCreditDirectPay
        headerLabel="Edit Tax Credit Direct Pay"
        loading={loadingUpdateDealTaxCreditDetails}
        form={dealTaxCreditForm}
        setForm={setDealTaxCreditForm}
        onClose={() => setEditDealTaxCreditModal(false)}
        open={
          editDealTaxCreditModal &&
          dealDetailsFat?.tax_credit_structure === "DP"
        }
        formErrors={dealTaxCreditFormErrors}
        setFormErrors={setDealTaxCreditFormErrors}
        onConfirm={handleEditDealTaxCreditTransfer}
      />

      <DealTimingFormModal
        headerLabel="Edit Deal Timing"
        open={updateDealTimingModalOpen}
        loading={updateDealTimingLoading}
        onClose={handleCloseUpdateDealTimingModal}
        onConfirm={handleUpdateDealTiming}
        formErrors={updateDealTimingFormErrors}
        setFormErrors={setUpdateDealTimingFormErrors}
        form={updateDealTimingForm}
        setForm={setUpdateDealTimingForm}
        isTransfer={dealDetailsFat?.tax_credit_structure === "TR"}
      />

      <EditDealTaxCreditTransfer
        headerLabel="Edit Portfolio Tax Credit Transfer"
        loading={loadingUpdateTaxCreditTransfer}
        form={dealTaxCreditTranferConstraintsForm}
        setForm={setDealTaxCreditTranferConstraintsForm}
        onClose={() => setUpdateDealTaxCreditTranferConstraintsModalOpen(false)}
        open={updateDealTaxCreditTranferConstraintsModalOpen}
        formErrors={updateTaxCreditTransferFormErrors}
        setFormErrors={setUpdateTaxCreditTransferFormErrors}
        onConfirm={handleEditDealTaxCreditTransferConstraint}
      />

      <ProjectTimingFormModal
        open={editProjectTimingModalOpen}
        headerLabel="Edit Project Timing"
        loading={editProjectTimingLoading}
        formErrors={editProjectTimingFormErrors}
        setFormErrors={setEditProjectTimingFormErrors}
        form={projectTimingForm}
        setForm={setProjectTimingForm}
        onClose={handleCloseEditTimingModal}
        onConfirm={handleOnEditProjectTiming}
      />

      <ProjectCostFormModal
        headerLabel="Edit FMV Step Up"
        open={updateProjectCostModalOpen}
        loading={updateProjectCostLoading}
        formErrors={updateProjectCostFormErrors}
        setFormErrors={setUpdateProjectCostFormErrors}
        form={projectCostForm}
        setForm={setProjectCostForm}
        onClose={handleCloseUpdateProjectCostModal}
        onConfirm={handleUpdateProjectCost}
      />

      <ProjectProductionFormModal
        open={updateProjectProductionModalOpen}
        headerLabel="Edit Production Details"
        loading={updatingProduction}
        dateSchedule={projectDateSchedule}
        formErrors={updateProjectProductionFormErrors}
        setFormErrors={setUpdateProjectProductionFormErrors}
        seasonalityAdjustmentFactors={projectSeasonalityAdjustmentFactors}
        setSeasonalityAdjustmentFactors={setProjectSeasonalityAdjustmentFactors}
        productionInputValues={projectProductionInputValues}
        setProductionInputValues={setProjectProductionInputValues}
        form={projectProductionForm}
        setForm={setProjectProductionForm}
        onClose={handleCloseUpdateProductionModal}
        onConfirm={handleUpdateProduction}
        projectEnergyType={projectDetailsFat?.energy_type}
      />

      <ProjectTaxCreditFormModal
        open={updateProjectTaxCreditModalOpen}
        headerLabel="Edit Tax Credit"
        loading={updateProjectTaxCreditLoading}
        formErrors={updateProjectTaxCreditFormErrors}
        setFormErrors={setUpdateProjectTaxCreditFormErrors}
        form={projectTaxCreditForm}
        setForm={setProjectTaxCreditForm}
        onClose={handleCloseUpdateTaxCreditModal}
        onConfirm={handleOnUpdateProjectTaxCredit}
        projectEnergyType={projectDetailsFat?.energy_type}
      />

      <ProjectRevenueContractFormModal
        headerLabel="Add Revenue Contract"
        open={addRevenueContractModalOpen}
        loading={addingRevenueContract}
        dateSchedule={projectDateSchedule}
        formErrors={addProjectRevenueContractFormErrors}
        setFormErrors={setAddProjectRevenueContractFormErrors}
        form={projectRevenueForm}
        setForm={setProjectRevenueForm}
        onClose={handleCloseAddRevenueContractModal}
        onConfirm={handleAddRevenueContract}
        priceCurves={currentOgCurvesOptions}
      />

      <ProjectRevenueContractFormModal
        headerLabel="Edit Revenue"
        open={editProjectRevenueContractModalOpen}
        loading={editProjectRevenueContractLoading}
        dateSchedule={projectDateSchedule}
        formErrors={editProjectRevenueContractFormErrors}
        setFormErrors={setEditProjectRevenueContractFormErrors}
        form={projectRevenueForm}
        setForm={setProjectRevenueForm}
        onClose={handleCloseEditProjectRevenueContractModal}
        onConfirm={handleEditProjectRevenueContract}
        priceCurves={currentOgCurvesOptions}
      />

      <ProjectMerchantRevenueFormModal
        headerLabel="Add Merchant Revenue"
        open={addMerchantRevenueModalOpen}
        loading={addMerchantRevenueLoading}
        formErrors={addMerchantRevenueFormErrors}
        setFormErrors={setAddMerchantRevenueFormErrors}
        form={merchantRevenueForm}
        dateSchedule={projectDateSchedule}
        setForm={setMerchantRevenueForm}
        onClose={handleCloseAddMerchantRevenueModal}
        onConfirm={handleAddMerchantRevenue}
        priceCurves={priceCurvesOptions}
        elccCurves={elccCurvesOptions}
      />

      <ProjectMerchantRevenueFormModal
        headerLabel="Edit Merchant Revenue"
        open={updateMerchantRevenueModalOpen}
        loading={updateMerchantRevenueLoading}
        formErrors={updateMerchantRevenueFormErrors}
        setFormErrors={setUpdateMerchantRevenueFormErrors}
        form={merchantRevenueForm}
        dateSchedule={projectDateSchedule}
        setForm={setMerchantRevenueForm}
        onClose={handleCloseUpdateMerchantRevenueModal}
        onConfirm={handleUpdateMerchantRevenue}
        priceCurves={priceCurvesOptions}
        elccCurves={elccCurvesOptions}
      />

      <ProjectExpensesFormModal
        open={addProjectExpenseModalOpen}
        dateSchedule={projectDateSchedule}
        headerLabel="Add Expense"
        loading={addProjectExpenseLoading}
        formErrors={addProjectExpenseFormErrors}
        setFormErrors={setAddProjectExpenseFormErrors}
        form={projectExpenseForm}
        setForm={setProjectExpenseForm}
        onClose={handleCloseAddExpenseModal}
        onConfirm={handleOnAddProjectExpense}
      />

      <ProjectExpensesFormModal
        open={updateProjectExpenseModalOpen}
        dateSchedule={projectDateSchedule}
        loading={updateProjectExpenseLoading}
        headerLabel="Update Expense"
        formErrors={updateProjectExpenseFormErrors}
        setFormErrors={setUpdateProjectExpenseFormErrors}
        form={projectExpenseForm}
        setForm={setProjectExpenseForm}
        onClose={handleCloseUpdateProjectExpenseModal}
        onConfirm={handleOnUpdateProjectExpense}
      />
      <GeneralAssumptionFormModal
        open={addGeneralAssumptionsModalOpen}
        form={generalAssumptionForm}
        setForm={setGeneralAssumptionForm}
        headerLabel="Add General Assumptions"
        loading={addGeneralAssumptionsLoading}
        onClose={handleCloseAddGeneralAssumptionsModal}
        onConfirm={handleOnAddGeneralAssumption}
        setFormErrors={setAddGeneralAssumptionFieldErrors}
        formErrors={addGeneralAssumptionFieldErrors}
        ircCurves={currentOgCurvesOptions}
      />

      <GeneralAssumptionFormModal
        open={editGeneralAssumptionsModalOpen}
        form={generalAssumptionForm}
        setForm={setGeneralAssumptionForm}
        headerLabel="Edit General Assumptions"
        loading={updateGeneralAssumptionsLoading}
        onClose={handleCloseEditGeneralAssumptionsModal}
        onConfirm={handleOnEditGeneralAssumption}
        setFormErrors={setUpdateGeneralAssumptionFormErrors}
        formErrors={updateGeneralAssumptionFormErrors}
        ircCurves={currentOgCurvesOptions}
      />

      <ProjectEBITDAFormModal
        open={updateEBITDAModalOpen}
        form={projectEBITDAForm}
        setForm={setProjectEBITDAForm}
        loading={updatingEBITDA}
        formErrors={updateProjectEBITDAFormErrors}
        setFormErrors={setUpdateProjectEBITDAFormErrors}
        dateSchedule={projectDateSchedule}
        headerLabel="Override EBITDA"
        onClose={handleCloseUpdateProjectEBITDAModal}
        onConfirm={handleOnUpdateProjectEBITDA}
      />

      <UpdateDealOtherSizingFormModal
        open={updateDealOtherSizingModalOpen}
        loading={loadingUpdateDealOtherSizing}
        form={updateDealSizingForm}
        formErrors={updateDealOtherSizingFormErrors}
        setFormErrors={setUpdateDealOtherSizingFormErrors}
        setForm={setUpdateDealSizingForm}
        onClose={onUpdateDealOtherSizingClose}
        onUpdate={onUpdateDealSizing}
      />

      <DealSponsorEquityFormModal
        open={updateDealSponsorEquityModalOpen}
        loading={updateDealSponsorEquityLoading}
        form={updateDealSponsorEquityForm}
        formErrors={updateDealSponsorEquityFormErrors}
        setFormErrors={setUpdateDealSponsorEquityFormErrors}
        setForm={setUpdateDealSponsorEquityForm}
        onClose={handleCloseUpdateSizingSponsorEquityFormModal}
        onConfirm={handleOnEditDealSponsorEquity}
        headerLabel="Edit Sponsor Equity"
      />

      <ProjectOtherFlowFormModal
        open={addProjectOtherFlowModalOpen}
        headerLabel="Add Other Flow"
        form={projectOtherFlowForm}
        loading={loadingAddProjectOtherFlow}
        formErrors={addProjectOtherFlowFormErrors}
        setFormErrors={setAddProjectOtherFlowFormErrors}
        setForm={setProjectOtherFlowForm}
        onClose={handleCloseAddStreamModal}
        onConfirm={onAddStream}
        dateSchedule={projectDateSchedule}
      />

      <DealFeeFormModal
        open={addDealFeeModalOpen}
        headerLabel="Add Fee"
        loading={addDealFeeLoading}
        formErrors={addDealFeeFormErrors}
        setFormErrors={setAddDealFeeFormErrors}
        form={dealFeeForm}
        setForm={setDealFeeForm}
        onClose={handleCloseAddDealFeeModal}
        onConfirm={handleOnAddDealFee}
      />

      <DealFeeFormModal
        open={updateDealFeeModalOpen}
        loading={updateDealFeeLoading}
        headerLabel="Update Fee"
        formErrors={updateDealFeeFormErrors}
        setFormErrors={setUpdateDealFeeFormErrors}
        form={dealFeeForm}
        setForm={setDealFeeForm}
        onClose={handleCloseUpdateDealFeeModal}
        onConfirm={handleOnUpdateDealFee}
      />

      <ProjectOtherFlowFormModal
        open={updateProjectOtherFlowModalOpen}
        headerLabel="Edit Other Flow"
        form={projectOtherFlowForm}
        loading={loadingEditProjectOtherFlow}
        formErrors={editProjectOtherFlowFormErrors}
        setFormErrors={setEditProjectOtherFlowFormErrors}
        setForm={setProjectOtherFlowForm}
        onClose={handleCloseEditStreamModal}
        onConfirm={onEditStream}
        dateSchedule={projectDateSchedule}
      />

      <ProjectCreditSupportFormModal
        open={addCreditSupportModalOpen}
        headerLabel="Add Credit Support"
        loading={addProjectCreditSupportLoading}
        formErrors={addProjectCreditSupportFormErrors}
        setFormErrors={setAddProjectCreditSupportFormErrors}
        form={creditSupportForm}
        setForm={setCreditSupportForm}
        onClose={handleCloseAddCreditSupportModal}
        onConfirm={handleOnAddCreditSupport}
      />

      <ProjectCreditSupportFormModal
        open={updateCreditSupportModalOpen}
        loading={updateProjectCreditSupportLoading}
        headerLabel="Update Credit Support"
        formErrors={updateProjectCreditSupportFormErrors}
        setFormErrors={setUpdateProjectCreditSupportFormErrors}
        form={creditSupportForm}
        setForm={setCreditSupportForm}
        onClose={handleCloseUpdateCreditSupportModal}
        onConfirm={handleOnUpdateCreditSupport}
      />

      <CostItemFormModal
        dateSchedule={costDateSchedule}
        form={addCostForm}
        headerLabel="Add Project Cost"
        loading={createCostItemLoading}
        onClose={handleCloseAddCostModal}
        onConfirm={onAddCost}
        open={openAddCostModal}
        setForm={setAddCostForm}
        setFormErrors={setCreateCostItemFormErrors}
        formErrors={createCostItemFormErrors}
      />

      <ConstructionLoanModal
        open={addConstructionLoanModalOpen}
        form={consstructionLoanForm}
        setForm={setConsstructionLoanForm}
        headerLabel="Add Construction Loan"
        loading={addConstructionLoanLoading}
        onClose={handleCloseAddConstructionLoanModal}
        onConfirm={handleOnAddConstructionLoan}
        setFormErrors={setAddConstructionLoanFieldErrors}
        formErrors={addConstructionLoanFieldErrors}
      />

      <ConstructionLoanModal
        open={editConstructionLoanModalOpen}
        form={consstructionLoanForm}
        setForm={setConsstructionLoanForm}
        headerLabel="Edit Construction Loan"
        loading={updateConstructionLoanLoading}
        onClose={handleCloseEditConstructionLoanModal}
        onConfirm={handleOnEditConstructionLoan}
        setFormErrors={setUpdateConstructionLoanFormErrors}
        formErrors={updateConstructionLoanFormErrors}
      />

      <TransferBridgeFormModal
        open={addTransferBridgeLoanModalOpen}
        form={tranferBridgeForm}
        setForm={setTranferBridgeForm}
        headerLabel="Add Transfer Bridge Loan"
        loading={addTransferBridgeLoanLoading}
        formErrors={addTransferBridgeLoanFieldErrors}
        setFormErrors={setAddTransferBridgeLoanFieldErrors}
        onClose={handleCloseAddTransferBridgeLoanModal}
        onConfirm={handleOnAddTransferBridgeLoan}
      />

      <TransferBridgeFormModal
        open={editTransferBridgeLoanModalOpen}
        form={tranferBridgeForm}
        setForm={setTranferBridgeForm}
        headerLabel="Edit Transfer Bridge Loan"
        loading={updateTransferBridgeLoanLoading}
        formErrors={updateTransferrBridgeLoanFormErrors}
        setFormErrors={setUpdateTransferBridgeLoanFormErrors}
        onClose={handleCloseEditTransferBridgeLoanModal}
        onConfirm={handleOnEditTransferBridgeLoan}
      />

      <InvestorBridgeFormModal
        open={addInvestorBridgeLoanModalOpen}
        form={investorBridgeForm}
        setForm={setInvestorBridgeForm}
        headerLabel="Add Investor Bridge Loan"
        loading={addInvestorBridgeLoanLoading}
        formErrors={addInvestorBridgeLoanFieldErrors}
        setFormErrors={setAddInvestorBridgeLoanFieldErrors}
        onClose={handleCloseAddInvestorBridgeLoanModal}
        onConfirm={handleOnAddInvestorBridgeLoan}
      />

      <InvestorBridgeFormModal
        open={editInvestorBridgeLoanModalOpen}
        form={investorBridgeForm}
        setForm={setInvestorBridgeForm}
        headerLabel="Edit Investor Bridge Loan"
        loading={updateInvestorBridgeLoanLoading}
        formErrors={updateInvestorBridgeLoanFormErrors}
        setFormErrors={setUpdateInvestorBridgeLoanFormErrors}
        onClose={handleCloseEditInvestorBridgeLoanModal}
        onConfirm={handleOnEditInvestorBridgeLoan}
      />

      <ProjectDepreciationStreamFormModal
        headerLabel="Add Depreciation"
        open={addDepreciationStreamModalOpen}
        formErrors={addProjectDepreciationStreamFormErrors}
        loading={addDepreciationStreamLoading}
        setFormErrors={setAddProjectDepreciationStreamFormErrors}
        form={projectDepreciationForm}
        setForm={setProjectDepreciationForm}
        onClose={handleCloseAddDepreciationStreamModal}
        onConfirm={handleAddDepreciationStream}
      />

      <ProjectDepreciationStreamFormModal
        headerLabel="Edit Depreciation"
        open={updateDepreciationStreamModalOpen}
        loading={updateDepreciationStreamLoading}
        formErrors={updateProjectDepreciationStreamFormErrors}
        setFormErrors={setUpdateProjectDepreciationStreamFormErrors}
        form={projectDepreciationForm}
        setForm={setProjectDepreciationForm}
        onClose={handleCloseUpdateDepreciationStreamModal}
        onConfirm={handleUpdateDepreciationStream}
      />
    </>
  );
}
