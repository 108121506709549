import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ReportTable from "../../../../../components/report-table";
import ViewWrapper from "../../../../../components/view-wrapper";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { DEVELOPER_TAX_REPORT_TABLE_COLUMNS } from "../../../../../constants";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  IDeveloperTaxReport,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";
import {
  getDealReportingUrl,
  generateDeveloperTaxReportRows,
} from "../../../../../utils/helpers";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IDeveloperTaxReport>;
}

export default function DealOutputDeveloperTaxView({
  getDealReporting,
}: IProps): JSX.Element {
  const dispatch = useDispatch();

  const { dealId, caseDealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IDeveloperTaxReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      periodicity: OutputReportPeriodicityType,
    ) => getDealReporting(dealId, "developer", "taxdetail", term, periodicity),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDealReportingCallAPI(
        Number(dealIdToUse),
        buyout,
        periodicityType,
      ).then((response) => {
        if (response?.data) {
          setReport(response);
        }
      });
    }
  }, [dealIdToUse, buyout, periodicityType]);

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(dealIdToUse),
        "developer",
        "taxdetail",
        buyout,
        periodicityType,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const taxTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateDeveloperTaxReportRows(report) : [];
  }, [report]);

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(
            Number(dealIdToUse),
            "developer",
            "taxdetail",
            buyout,
            periodicityType,
          )
        ]
      }
    >
      <DealOutputFilterFields
        buyout={buyout}
        setBuyout={setBuyout}
        periodicityType={periodicityType}
        setPeriodicityType={setPeriodicityType}
        downloadReport={handleDownloadReport}
        disableDownload={isDownloading}
        showPeriodicity
      />

      <ReportTable
        columns={DEVELOPER_TAX_REPORT_TABLE_COLUMNS}
        rows={taxTableRows}
        boldTotal
        stickyTotal
        bigHeader
      />
    </ViewWrapper>
  );
}
