import React from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import EditOutlinedIcon from "../logos-and-icons/edit-icon";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Tooltip from "@mui/material/Tooltip";
import { Property as CssProperty } from "csstype";

import useStyles from "./styles";
import ConditionalProtect from "../conditional-protect";
import { cn } from "../../utils/helpers";

export interface IDetailsCardSection {
  heading?: string;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  fields: {
    textWeight?: number;
    textColor?: string;
    label: string | JSX.Element | JSX.Element[];
    fieldName?: string;
    value: {
      text: React.ReactNode;
      tooltip?: string;
      textWeight?: number;
      textColor?: string;
      onEditClick?: () => void;
      onDeleteClick?: () => void;
    };
  }[];
}

interface IProps {
  heading: string | JSX.Element;
  autoHeight?: boolean;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  secondaryButton?: JSX.Element;
  sections: IDetailsCardSection[];
  actionButton?: JSX.Element;
  postActionButtonSections?: IDetailsCardSection[];
  height?: CssProperty.Height;
  dataPw?: string;
  tooltip?: string;
}

export default function DetailsCard({
  heading,
  autoHeight,
  onEditClick,
  onDeleteClick,
  secondaryButton: SecondaryButton,
  sections,
  height = "auto",
  actionButton: ActionButton,
  postActionButtonSections,
  dataPw,
  tooltip,
}: IProps): JSX.Element {
  const styles = useStyles({ autoHeight });

  const renderSections = (sections: IDetailsCardSection[]) => {
    return sections.map((section, idx) => {
      return (
        <Box
          key={idx}
          data-pw={`${dataPw || heading}-section-${idx + 1}`}
          className={styles.classes.sectionContainer}
        >
          {section.heading && (
            <>
              <Box className={styles.classes.headingContainer}>
                <Typography
                  data-pw={`${dataPw || heading}-section-${idx + 1}-heading`}
                  classes={{ root: styles.classes.sectionHeaing }}
                >
                  {section.heading}
                </Typography>
                <Box className={cn("flex gap-4 items-center")}>
                  {section.onEditClick && (
                    <ConditionalProtect type="deal">
                      <IconButton
                        onClick={section.onEditClick}
                        data-pw={`${dataPw || heading}-section-${idx + 1}-edit-button`}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </ConditionalProtect>
                  )}
                  {section.onDeleteClick && (
                    <ConditionalProtect type="deal">
                      <IconButton
                        onClick={section.onDeleteClick}
                        data-pw={`${dataPw || heading}-section-${idx + 1}-delete-button`}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </ConditionalProtect>
                  )}
                </Box>
              </Box>
              <Divider />
            </>
          )}
          {section.fields.map((field, innerIdx) => {
            return (
              <React.Fragment key={innerIdx}>
                <Box className={styles.classes.fieldContainer}>
                  <Box className={cn("flex items-start")}>
                    <Typography
                      fontWeight={field.textWeight}
                      data-pw={`${dataPw || heading}-section-${idx + 1}-field-${
                        innerIdx + 1
                      }-label`}
                    >
                      {field.label}
                    </Typography>
                  </Box>
                  <Box className={styles.classes.fieldValueContainer}>
                    <Typography
                      title={field.value.tooltip}
                      data-pw={`${dataPw || heading}-section-${idx + 1}-field-${
                        innerIdx + 1
                      }-value`}
                      fontWeight={field.textWeight || field.value.textWeight}
                    >
                      {field.value.text}
                    </Typography>
                    <Box className={cn("flex gap-2 items-center")}>
                      {field.value.onEditClick && (
                        <ConditionalProtect type="deal">
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={field.value.onEditClick}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </ConditionalProtect>
                      )}
                      {field.value.onDeleteClick && (
                        <ConditionalProtect type="deal">
                          <IconButton
                            style={{ padding: 0 }}
                            onClick={field.value.onDeleteClick}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </ConditionalProtect>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Divider />
              </React.Fragment>
            );
          })}
        </Box>
      );
    });
  };

  return (
    <Tooltip
      title={tooltip}
      placement="top-end"
      classes={{ tooltip: styles.classes.tooltip }}
    >
      <Box data-pw={dataPw} style={{ width: "100%", height }}>
        <Paper classes={{ root: styles.classes.paperRoot }}>
          <Box className={styles.classes.headingContainer}>
            <Typography
              fontWeight={600}
              data-pw={`${dataPw || heading}-heading`}
            >
              {heading}
            </Typography>
            <Box className={cn("flex gap-2 items-center")}>
              {SecondaryButton && SecondaryButton}
              {onDeleteClick && (
                <ConditionalProtect type="deal">
                  <IconButton
                    data-pw={`${dataPw || heading}-delete-button`}
                    onClick={onDeleteClick}
                  >
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                </ConditionalProtect>
              )}
              {onEditClick && (
                <ConditionalProtect type="deal">
                  <IconButton
                    data-pw={`${dataPw || heading}-edit-button`}
                    onClick={onEditClick}
                  >
                    <EditOutlinedIcon fillColor="#5F51F4" />
                  </IconButton>
                </ConditionalProtect>
              )}
            </Box>
          </Box>

          {renderSections(sections)}
          <ConditionalProtect type="deal">
            {ActionButton && ActionButton}
          </ConditionalProtect>
          {postActionButtonSections && renderSections(postActionButtonSections)}
        </Paper>
      </Box>
    </Tooltip>
  );
}
