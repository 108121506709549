import React from "react";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useOrganization } from "@clerk/clerk-react";

import Header from "../header";
import Sidebar from "../sidebar";
import useStyles from "./styles";
import { TAppEnvironment } from "../../interfaces";
import { useAPI, useAppSelector } from "../../utils/hooks";
import { getProductUsageDetails } from "../../apis/configuration/curves";
import {
  APP_ENVIRONMENTS,
  DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
} from "../../constants";
import {
  setCasesOfDealAction,
  setCurrentDealAction,
  setCurrentDealTermDebtAction,
  setCurrentProjectAction,
  setProductUsageDetailsAction,
  updateCtrlPressedAction,
  updateDealOutputLoadedAction,
  updateSelectedDealOutputPageTabAction,
  updateShiftPressedAction,
} from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
  title?: string;
}

export default function Layout({
  children,
  title,
}: IProps): JSX.Element | null {
  const styles = useStyles();

  const { organization } = useOrganization();

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const { productUsageDetails } = useAppSelector((s) => s.org);

  const [mobileSidebarOpen, setMobileSidebarOpen] =
    React.useState<boolean>(false);

  const environment = process.env.REACT_APP_NODE_ENV as TAppEnvironment;

  let environmentLabel = "";
  if (environment) {
    environmentLabel = APP_ENVIRONMENTS[environment];
  }

  const helmetTitle: string = title
    ? `${title} ${
        environment !== "prod" ? `(${environmentLabel}) ` : ""
      }- CapeZero`
    : `CapeZero ${environment !== "prod" ? `(${environmentLabel})` : ""}`;

  React.useEffect(() => {
    if (
      organization &&
      !productUsageDetails &&
      !erroredGetProductUsageDetails
    ) {
      hadnleGetProductUsageDetails();
    }

    if (!["/deal/", "/project/"].some((i) => pathname.includes(i))) {
      dispatch(setCurrentProjectAction(undefined));
      dispatch(setCurrentDealAction(undefined));
      dispatch(setCurrentDealTermDebtAction(undefined));
      dispatch(setCasesOfDealAction([]));
      dispatch(updateDealOutputLoadedAction(false));
      dispatch(
        updateSelectedDealOutputPageTabAction({
          ...DEFAULT_SELECTED_DEAL_OUTPUT_PAGE_TABS,
          dealId: "",
        }),
      );
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleFocusBlur);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
      window.addEventListener("blur", handleFocusBlur);
    };
  }, []);

  const {
    callAPI: getProductUsageDetailsCallAPI,
    errored: erroredGetProductUsageDetails,
  } = useAPI(getProductUsageDetails, {
    initialLoading: true,
  });

  const hadnleGetProductUsageDetails = async () => {
    const response = await getProductUsageDetailsCallAPI();
    if (response) {
      dispatch(setProductUsageDetailsAction(response));
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      dispatch(updateCtrlPressedAction(true));
    }
    if (e.key === "Shift") {
      dispatch(updateShiftPressedAction(true));
    }
  };

  const handleKeyUp = (e: KeyboardEvent) => {
    if (e.key === "Control" || e.key === "Meta") {
      dispatch(updateCtrlPressedAction(false));
    }
    if (e.key === "Shift") {
      dispatch(updateShiftPressedAction(false));
    }
  };

  const handleFocusBlur = () => {
    dispatch(updateCtrlPressedAction(false));
  };

  const toggleSidebar = () => {
    setMobileSidebarOpen((s) => !s);
  };

  return (
    <Box className={styles.classes.root}>
      <Helmet title={helmetTitle} />
      <Header toggleSidebar={toggleSidebar} />

      <Box className={styles.classes.container}>
        <Sidebar
          mobileSidebarOpen={mobileSidebarOpen}
          toggleSidebar={toggleSidebar}
        />
        <Box className={styles.classes.content}>{children}</Box>
      </Box>
    </Box>
  );
}
