import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import DealOutputFilterFields from "../../../../../components/deal-output-filter-fields";
import ReportTable from "../../../../../components/report-table";
import { useAxios } from "../../../../../components/axios-provider";
import { useAPI, useSessionStorage } from "../../../../../utils/hooks";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  generateSlbCashReportTableRows,
  getDealReportingUrl,
  generateSlbCashReportTableHeaders,
} from "../../../../../utils/helpers";
import {
  IReport,
  ITableRow,
  ITableColumn,
  OutputReportPeriodicityType,
  ReportPerspective,
  ReportTerm,
  ReportType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IReport>;
}

export default function DealOutputLessorCashView({ getDealReporting }: IProps) {
  const dispatch = useDispatch();

  const { dealId, caseDealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IReport>();

  const [buyout, setBuyout] = useSessionStorage<ReportTerm>(
    `deal-${dealId}-output-buyout`,
    "default",
  );
  const [periodicityType, setPeriodicityType] =
    useSessionStorage<OutputReportPeriodicityType>(
      `deal-${dealId}-output-periodicity`,
      "MO",
    );

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDealReportingCallAPI(
        Number(dealIdToUse),
        buyout,
        periodicityType,
      ).then((response) => {
        if (response?.["leasecash"]) {
          setReport(response);
        }
      });
    }
  }, [dealIdToUse, buyout, periodicityType]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "lessor",
        "leasecash",
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const cashTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateSlbCashReportTableRows(report) : [];
  }, [report]);

  const columnHeaders: ITableColumn[] = React.useMemo(() => {
    return report ? generateSlbCashReportTableHeaders(report) : [];
  }, [report]);

  return (
    <>
      <ViewWrapper
        loading={loadingReport}
        error={getReportFailed}
        errorHeading={getReportErrorObj?.heading}
        errorDescription={getReportErrorObj?.description}
        showLimitHitView={showUsageLimitHitScreen}
        loadingPercentage={
          urlLoadingPercentages[
            getDealReportingUrl(
              Number(dealIdToUse),
              "lessor",
              "leasecash",
              buyout,
              periodicityType,
            )
          ]
        }
      >
        <DealOutputFilterFields
          buyout={buyout}
          periodicityType={periodicityType}
          setBuyout={setBuyout}
          setPeriodicityType={setPeriodicityType}
          showBuyout={report?.has_investor_buyout}
          showPeriodicity
          showEbo
        />
        <ReportTable columns={columnHeaders} rows={cashTableRows} boldTotal />
      </ViewWrapper>
    </>
  );
}
