import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import useStyles from "./styles";
import ViewWrapper from "../../../components/view-wrapper";
import Button from "../../../components/button";
import Searchbar from "../../../components/search-bar";
import AddCaseFormModal from "../../../components/add-case-form-modal";
import DealCaseDuplicateFormModal from "../../../components/deal-case-duplicate-form-modal";
import UpdateCaseFormModal from "../../../components/update-case-form-modal";
import PopoverMenu from "../../../components/popover-menu";
import StickyTableCell from "../../../components/sticky-table-cell";
import ConditionalProtect from "../../../components/conditional-protect";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import {
  getTableColumnAccordingToStatus,
  sortArrayOfObjects,
  trimString,
} from "../../../utils/helpers";
import {
  setCasesOfDealAction,
  setDeleteModalPropsAction,
} from "../../../utils/redux/slices";
import {
  DEAL_CASE_TYPE,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_TYPE,
  DUPLICATE_CASE_FORM_DEFAULT_STATE,
  UPDATE_CASE_FORM_DEFAULT_STATE,
} from "../../../constants";
import {
  IAddDealCaseForm,
  IAddDealCaseFormErrors,
  IDealCase,
  IDuplicateDealCaseForm,
  IDuplicateDealCaseFormErrors,
  IUpdateDealCaseForm,
  IUpdateDealCaseFormErrors,
  ITableColumn,
  ITableSort,
  ServerPaginatedResponse,
} from "../../../interfaces";

interface IProps {
  getDealCases: (
    dealId: number,
  ) => Promise<ServerPaginatedResponse<IDealCase[]>>;
  addCase: (dealId: number, form: IAddDealCaseForm) => Promise<IDealCase>;
  duplicateDealCase: (
    dealId: number,
    caseId: number,
    form: IDuplicateDealCaseForm,
  ) => Promise<IDealCase>;
  deleteDealCase: (dealId: number, caseId: number) => Promise<boolean>;
  updateDealCase: (
    dealId: number,
    caseId: number,
    form: IUpdateDealCaseForm,
  ) => Promise<IDealCase>;
}

const columns: ITableColumn[] = [
  { align: "left", id: "name", label: "Case Name", minWidth: 200 },
  { align: "left", id: "type", label: "Type", minWidth: 200 },
  {
    align: "left",
    id: "child_deal.structure",
    label: "Structure",
    minWidth: 200,
  },
  {
    align: "left",
    id: "child_deal.tax_credit_structure",
    label: "Tax Credit Structure",
    minWidth: 200,
  },
  {
    id: "child_deal.tax_credit_type",
    label: "Tax Credit Type",
    minWidth: 200,
    align: "left",
  },
  { align: "left", id: "modified", label: "Modified", minWidth: 100 },
];

export default function DealCaseView({
  getDealCases,
  addCase,
  duplicateDealCase,
  deleteDealCase,
  updateDealCase,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { currentDeal, casesOfDeal } = useAppSelector((s) => s.deal);
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const [addCaseModalOpen, setAddCaseModalOpen] =
    React.useState<boolean>(false);
  const [duplicateCaseModalOpen, setDuplicateCaseModalOpen] =
    React.useState<boolean>(false);
  const [editCaseModalOpen, setEditCaseModalOpen] =
    React.useState<boolean>(false);
  const [addCaseForm, setAddCaseForm] = React.useState<IAddDealCaseForm>({
    name: "",
    type: "",
    description: "",
    is_synced_with_base_case: false,
    project_tax_credit_type: "",
    deal_structure: "",
    deal_tax_credit_structure: "",
  });
  const [duplicateCaseForm, setDuplicateCaseForm] =
    React.useState<IDuplicateDealCaseForm>(DUPLICATE_CASE_FORM_DEFAULT_STATE);
  const [editDealCaseForm, setEditDealCaseForm] =
    React.useState<IUpdateDealCaseForm>(UPDATE_CASE_FORM_DEFAULT_STATE);
  const [dealCases, setDealCases] = React.useState<IDealCase[]>([]);
  const [searchString, setSearchString] = React.useState<string>("");
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = React.useMemo(() => {
    return dealCases.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [dealCases, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleOpenAddCaseModal = () => {
    setAddCaseForm({
      name: "",
      type: "SCEN",
      description: "",
      is_synced_with_base_case: true,
      project_tax_credit_type: "NC",
      deal_structure:
        currentDeal?.structure as keyof typeof DEAL_STRUCTURE_TYPE,
      deal_tax_credit_structure:
        currentDeal?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE,
    });
    setAddCaseModalOpen(true);
  };

  const handleCloseAddCaseModal = () => {
    setAddCaseModalOpen(false);
  };

  const handleOpenDuplicateModal = (caseId: number) => {
    setDuplicateCaseModalOpen(true);
    setDuplicateCaseForm((prev) => ({ ...prev, id: caseId }));
  };

  const handleCloseDuplicateModal = () => {
    setDuplicateCaseModalOpen(false);
  };

  const handleOnDelete = (id: number | undefined) => {
    if (id === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Case",
        description: "Are you sure you want to delete Case?",
        onConfirm: () => handleDeleteCase(id),
      }),
    );
  };

  const handleOpenEditModal = (id: number) => {
    setEditCaseModalOpen(true);
    const dealCase = dealCases.find((dc) => dc.id === id);
    if (dealCase) {
      setEditDealCaseForm({
        id: id,
        name: dealCase.name,
        description: dealCase.description,
      });
    }
  };

  const handleCloseEditModal = () => {
    setEditCaseModalOpen(false);
  };

  const {
    callAPI: getDealCasesCallAPI,
    loading: casesLoading,
    errored: casesLoadingFailed,
  } = useAPI((dealId) => getDealCases(Number(dealId)), {
    initialLoading: true,
  });

  const {
    callAPI: addCaseCallAPI,
    fieldErrors: addCaseFormErrors,
    setFieldErrors: setAddCaseFormErrors,
    loading: addCaseLoading,
  } = useAPI<IDealCase, IAddDealCaseFormErrors>((form: IAddDealCaseForm) =>
    addCase(Number(dealId), form),
  );

  const {
    callAPI: duplicateCaseCallAPI,
    fieldErrors: duplicateCaseFormErrors,
    setFieldErrors: setDuplicateCaseFormErrors,
    loading: duplicateCaseLoading,
  } = useAPI<IDealCase, IDuplicateDealCaseFormErrors>(
    (caseId: number, form: IDuplicateDealCaseForm) =>
      duplicateDealCase(Number(dealId), caseId, form),
  );

  const { callAPI: deleteCaseCallAPI } = useAPI(
    (caseId: number) => deleteDealCase(Number(dealId), caseId),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: editDealCaseCallAPI,
    fieldErrors: editDealCaseFormErrors,
    setFieldErrors: setEditDealCaseFormErrors,
    loading: editDealCaseLoading,
  } = useAPI<IDealCase, IUpdateDealCaseFormErrors>(
    (caseId: number, form: IUpdateDealCaseForm) =>
      updateDealCase(Number(dealId), caseId, form),
  );

  const handleAddCase = async (form: IAddDealCaseForm) => {
    const dealCase = await addCaseCallAPI(form);
    if (dealCase) {
      setDealCases((prevCases) => [...prevCases, dealCase]);
      refetchCasesOfDeal();
      goToCase(dealCase.id);
    }
    return dealCase;
  };

  const handleDuplicateCase = async (form: IDuplicateDealCaseForm) => {
    const { id: caseId } = form;
    const dealCase = await duplicateCaseCallAPI(caseId, form);
    dealCase && setDealCases((prevCases) => [...prevCases, dealCase]);
    dealCase && refetchCasesOfDeal();
    dealCase && goToCase(dealCase?.id);
    return dealCase;
  };

  const handleDeleteCase = async (id: number) => {
    const response = await deleteCaseCallAPI(id);

    if (response) {
      setDealCases((dealCases) => dealCases.filter((d) => d.id !== id));
      setCasesOfDealAction(casesOfDeal.filter((d) => d.id !== id));
    }
  };

  const handleEditCase = async (form: IUpdateDealCaseForm) => {
    const { id: caseId } = form;
    const dealCase = await editDealCaseCallAPI(caseId, form);
    if (dealCase) {
      setDealCases((prev) => [
        ...prev.map((d) => {
          if (d?.id === caseId) {
            return {
              ...d,
              name: dealCase?.name,
              description: dealCase?.description,
            };
          }
          return d;
        }),
      ]);
      refetchCasesOfDeal();
    }
    return dealCase;
  };

  const goToCase = (id: number) => {
    if (ctrlPressed) {
      window.open(`/deal/${dealId}/case/${id}`);
      return;
    }
    navigate(`/deal/${dealId}/case/${id}`);
  };

  const refetchCasesOfDeal = async () => {
    const dealCases = await getDealCasesCallAPI(Number(dealId));
    dealCases && dispatch(setCasesOfDealAction(dealCases.results));
  };

  React.useEffect(() => {
    getDealCasesCallAPI(Number(dealId)).then((res) => {
      res && setDealCases(res.results);
    });
  }, [dealId]);

  return (
    <>
      <ViewWrapper loading={casesLoading} error={casesLoadingFailed}>
        {dealCases?.length > 0 ? (
          <Box>
            <Box className={styles.classes.topSection}>
              <Box className={styles.classes.searchAndFilterContainer}>
                <Searchbar
                  searchString={searchString}
                  onTextChange={onSearchStringChange}
                />
              </Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Case"
                  onClick={handleOpenAddCaseModal}
                />
              </ConditionalProtect>
            </Box>
            {/* <Typography variant="h6">Case List</Typography> */}
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer classes={{ root: styles.classes.tableContainer }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {getTableColumnAccordingToStatus(
                        columns,
                        currentDeal?.status as string,
                      ).map((column, idx) => {
                        if (column.id === "action") {
                          return (
                            <StickyTableCell
                              key={idx}
                              direction="right"
                              fixedColumnWidth={100}
                              align="center"
                              highZIndex
                            >
                              {column.label}
                            </StickyTableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={idx}
                            align={column.align as "left"}
                            style={{ maxWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={sortTable.orderBy === column.id}
                              direction={
                                sortTable.orderBy === column.id
                                  ? sortTable.order
                                  : "asc"
                              }
                              onClick={() => sortRows(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.length === 0 && (
                      <TableRow>
                        <TableCell
                          colSpan={
                            getTableColumnAccordingToStatus(
                              columns,
                              currentDeal?.status as string,
                            ).length
                          }
                          align="center"
                        >
                          No Cases.
                        </TableCell>
                      </TableRow>
                    )}
                    {visibleRows.map((c, idx) => {
                      return (
                        <TableRow
                          hover
                          key={idx}
                          tabIndex={-1}
                          className={styles.classes.dataRow}
                          onClick={() => goToCase(c?.id)}
                        >
                          <TableCell align="left">
                            {trimString(c?.name, 40)}
                          </TableCell>
                          <TableCell align="left">
                            {
                              DEAL_CASE_TYPE[
                                c?.type as keyof typeof DEAL_CASE_TYPE
                              ]
                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              DEAL_STRUCTURE_TYPE[
                                c?.child_deal
                                  ?.structure as keyof typeof DEAL_STRUCTURE_TYPE
                              ]
                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                                c?.child_deal
                                  ?.tax_credit_structure as keyof typeof DEAL_TAX_CREDIT_STRUCTURE_TYPE
                              ]
                            }
                          </TableCell>
                          <TableCell align="left">
                            {
                              DEAL_TAX_CREDIT_TYPE[
                                c?.child_deal
                                  ?.tax_credit_type as keyof typeof DEAL_TAX_CREDIT_TYPE
                              ]
                            }
                          </TableCell>
                          <TableCell>
                            {format(new Date(c?.modified), "M/d/yyyy")}
                          </TableCell>
                          <ConditionalProtect type="deal">
                            <StickyTableCell
                              direction="right"
                              fixedColumnWidth={100}
                              align="center"
                            >
                              <PopoverMenu
                                uniqueId={idx}
                                canOpenUpgrade
                                items={[
                                  {
                                    label: "Duplicate",
                                    onClick: () =>
                                      handleOpenDuplicateModal(c?.id),
                                  },
                                  {
                                    label: "Edit",
                                    onClick: () => handleOpenEditModal(c?.id),
                                  },
                                  {
                                    label: "Delete",
                                    onClick: () => handleOnDelete(c?.id),
                                  },
                                ]}
                              />
                            </StickyTableCell>
                          </ConditionalProtect>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={dealCases.length}
                className={styles.classes.paginationRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        ) : (
          <Box className={styles.classes.emptyContainer}>
            <Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add Case"
                  onClick={handleOpenAddCaseModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>

              <Typography variant="body1" className={styles.classes.createInfo}>
                Cases allow you to run scenarios and test assumptions against
                the base case deal. It looks like you do not have any cases yet.
              </Typography>
            </Box>
          </Box>
        )}

        <AddCaseFormModal
          headerLabel="Add Case"
          open={addCaseModalOpen}
          loading={addCaseLoading}
          form={addCaseForm}
          setForm={setAddCaseForm}
          formErrors={addCaseFormErrors}
          setFormErrors={setAddCaseFormErrors}
          onClose={handleCloseAddCaseModal}
          onConfirm={handleAddCase}
        />

        <DealCaseDuplicateFormModal
          headerLabel="Duplicate Case"
          open={duplicateCaseModalOpen}
          loading={duplicateCaseLoading}
          form={duplicateCaseForm}
          setForm={setDuplicateCaseForm}
          formErrors={duplicateCaseFormErrors}
          setFormErrors={setDuplicateCaseFormErrors}
          onClose={handleCloseDuplicateModal}
          onConfirm={handleDuplicateCase}
        />

        <UpdateCaseFormModal
          headerLabel="Edit Case"
          open={editCaseModalOpen}
          loading={editDealCaseLoading}
          form={editDealCaseForm}
          setForm={setEditDealCaseForm}
          formErrors={editDealCaseFormErrors}
          setFormErrors={setEditDealCaseFormErrors}
          onClose={handleCloseEditModal}
          onConfirm={handleEditCase}
        />
      </ViewWrapper>
    </>
  );
}
