import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { cloneDeep } from "lodash";
import { Protect } from "@clerk/clerk-react";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import useStyles from "./styles";
import ThemeContext from "../../utils/context/theme-context";
import { useAppSelector } from "../../utils/hooks";
import { ISidebarLink, IMenu } from "../../interfaces";
import { filterSidebarLinks, cn } from "../../utils/helpers";
import { FloatingNavItems, PathToABC } from "./common";
import {
  DISABLE_DEAL_SIDEBAR_OPTIONS_ON,
  DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON,
  PRIMARY_SIDEBAR_NAVIGATION,
  USER_PERMISSIONS,
} from "../../constants";

interface IProps {
  mobileSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const sidebarWidth = 220;

export default function PrimarySidebar({
  mobileSidebarOpen,
  toggleSidebar,
}: IProps): JSX.Element {
  const styles = useStyles({ sidebarWidth });

  const navigate = useNavigate();
  const { pathname: urlPath } = useLocation();
  const { dealId, caseDealId, projectId, collaborationUuid } = useParams();

  const { currentDeal, currentDealTermDebt, casesOfDeal } = useAppSelector(
    (s) => s.deal,
  );
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const { darkMode } = React.useContext(ThemeContext);

  const [floatingNavAnchorEl, setFloatingNavAnchorEl] =
    React.useState<null | IMenu>(null);

  const dealIdToUse = React.useMemo(() => {
    return DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON.some((path) =>
      urlPath.includes(path),
    )
      ? dealId
      : caseDealId;
  }, [dealId, caseDealId]);

  const filteredSidebarLinks: ISidebarLink[] = React.useMemo(() => {
    if (urlPath.includes("/deal/") && currentDeal) {
      return filterSidebarLinks(
        cloneDeep(PRIMARY_SIDEBAR_NAVIGATION),
        {
          structure: currentDeal.structure,
          tax_credit_structure: currentDeal.tax_credit_structure,
          debt: currentDealTermDebt ? "1" : "",
          is_construction_debt_added: currentDeal.is_construction_debt_added,
        },
        currentDeal,
      );
    }
    return PRIMARY_SIDEBAR_NAVIGATION;
  }, [currentDeal, casesOfDeal, currentDealTermDebt]);

  const replaceIds = (path: string) => {
    const caseDealIdToUse = caseDealId ? caseDealId : dealId;
    return path
      .replace(":dealId", String(dealId))
      .replace(":caseDealId", String(caseDealIdToUse))
      .replace(":projectId", String(projectId))
      .replace(":collaborationUuid", String(collaborationUuid));
  };

  const onLinkClick = (
    e: React.MouseEvent<HTMLElement>,
    page: ISidebarLink,
    type: "base" | "sub",
  ) => {
    e.stopPropagation();
    const { path } = page;
    let naviagteToUrl = path;

    if (type === "sub") {
      const pathToUse = page.subLinks?.length ? page.subLinks[0].path : path;
      naviagteToUrl = replaceIds(pathToUse);
    }

    if (ctrlPressed) {
      window.open(naviagteToUrl, "_blank");
      return;
    }

    navigate(naviagteToUrl);
  };

  const isLinkActive = (link: ISidebarLink, type: "base" | "sub") => {
    let updatedPath = link.path;
    let pathToMatch = link.pathToMatch || "";

    updatedPath = replaceIds(updatedPath);
    pathToMatch = replaceIds(pathToMatch);

    if (urlPath !== "/" && link.path !== "/") {
      if (
        urlPath.includes("/deal/") &&
        link.label === "Deals" &&
        type === "base"
      ) {
        return true;
      }
      if (
        urlPath.includes("/project/") &&
        link.label === "Projects" &&
        type === "base"
      ) {
        return true;
      }
      if (urlPath.includes("/case-deal") && link.label === "Cases") {
        return false;
      }

      const pathToUse = link.pathToMatch ? pathToMatch : updatedPath;
      return Boolean(urlPath.includes(pathToUse));
    }

    if (urlPath === "/recent" && link.path === "/") {
      return true;
    }

    return urlPath === "/" && link.path === "/";
  };

  const subLinksToKeepOpen = () => {
    if (urlPath.includes("/deal/")) {
      return "Deals";
    } else if (urlPath.includes("/project/")) {
      return "Projects";
    } else if (urlPath.includes("/collaboration/")) {
      return "Collaboration";
    } else {
      return "";
    }
  };

  const onHoverOfSubLink = (
    e: React.MouseEvent<HTMLElement>,
    link: ISidebarLink,
    idx: number,
  ) => {
    if (link.subLinks?.length) {
      setFloatingNavAnchorEl({ id: idx, element: e.currentTarget });
    }
  };

  const onMouseOutOfFloatingLinks = () => {
    setFloatingNavAnchorEl(null);
  };

  const renderDrawer = (type: "mobile" | "desktop") => (
    <>
      <List classes={{ root: styles.classes.linkList }}>
        {filteredSidebarLinks.map((page, idx) => {
          const selected = isLinkActive(page, "base");
          const { icon: Icon } = page;

          return (
            <React.Fragment key={idx}>
              <Protect
                condition={(has) => {
                  if (page.label === "CoZe") {
                    return (
                      has({ permission: USER_PERMISSIONS.PROJECTS_CRUD }) &&
                      has({ permission: USER_PERMISSIONS.DEALS_CRUD })
                    );
                  }
                  return true;
                }}
              >
                <ListItemButton
                  selected={selected}
                  data-pw={`${type}-${page.label}`}
                  onClick={(e) => onLinkClick(e, page, "base")}
                  classes={{
                    selected: styles.classes.selectedOption,
                    root: styles.classes.listItemRoot,
                  }}
                >
                  {Icon && (
                    <div className="w-8">
                      <Icon
                        fillColor={selected || darkMode ? "white" : "black"}
                      />
                    </div>
                  )}
                  <ListItemText primary={page.label} />
                </ListItemButton>

                {subLinksToKeepOpen() === page.label ? (
                  <List>
                    {urlPath.includes("/deal/") &&
                    (!currentDeal || currentDeal.id !== Number(dealIdToUse))
                      ? new Array(4)
                          .fill(0)
                          .map((_, idx) => <Skeleton key={idx} height={30} />)
                      : page.subLinks?.map((subPage, subIdx) => {
                          const selectedSubPage = isLinkActive(subPage, "sub");

                          if (subPage.label === "divider") {
                            return <hr key={subIdx} className={cn("my-2")} />;
                          }

                          let disabled = false;
                          if (subPage.path.includes("/deal/")) {
                            const isOnBelowDealPage =
                              DISABLE_DEAL_SIDEBAR_OPTIONS_ON.some((p) =>
                                urlPath.includes(p),
                              );
                            const isUpperDealPage =
                              !DEAL_PATHS_TO_HIDE_CASE_DROPDOWN_ON.some((p) =>
                                subPage.path.includes(p),
                              );
                            if (isOnBelowDealPage && isUpperDealPage) {
                              disabled = true;
                            }
                          }

                          return (
                            <Box
                              key={subIdx}
                              onMouseLeave={onMouseOutOfFloatingLinks}
                            >
                              <ListItemButton
                                selected={selectedSubPage}
                                data-pw={`${type}-${page.label}-${subPage.label}`}
                                onClick={(e) => onLinkClick(e, subPage, "sub")}
                                classes={{
                                  selected: styles.classes.activesubLink,
                                  root: styles.classes.subLink,
                                }}
                                onMouseEnter={(e) =>
                                  onHoverOfSubLink(e, subPage, subIdx)
                                }
                                disabled={disabled}
                              >
                                <ListItemText primary={subPage.label} />
                                {subPage.subLinks?.length && <ArrowRightIcon />}
                              </ListItemButton>
                              <FloatingNavItems
                                idx={subIdx}
                                links={subPage.subLinks}
                                onLinkClick={onLinkClick}
                                floatingNavAnchorEl={floatingNavAnchorEl}
                                setFloatingNavAnchorEl={setFloatingNavAnchorEl}
                              />
                            </Box>
                          );
                        })}
                  </List>
                ) : null}
              </Protect>
            </React.Fragment>
          );
        })}
      </List>

      <PathToABC usedOn="org-deal" />
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileSidebarOpen}
        onClose={toggleSidebar}
        component="nav"
        classes={{ paper: styles.classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          display: { sm: "block", md: "none" },
        }}
      >
        {renderDrawer("mobile")}
      </Drawer>

      <Drawer
        open
        variant="permanent"
        component="nav"
        classes={{ paper: styles.classes.drawerPaper }}
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          display: { xs: "none", sm: "none", md: "block" },
          height: "calc(100vh - 68px)",
          ".MuiPaper-root": {
            top: 68,
          },
        }}
      >
        {renderDrawer("desktop")}
      </Drawer>
    </>
  );
}
