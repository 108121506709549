import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useOrganization } from "@clerk/clerk-react";
import { Link } from "react-router-dom";
import { format } from "date-fns";

import DetailsCard from "../../components/details-card";
import { useAppSelector } from "../../utils/hooks";
import { cn } from "../../utils/helpers";
import {
  DEAL_STAGES,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
} from "../../constants";

export default function CollaborationGeneralView(): JSX.Element {
  const { currentCollaboration } = useAppSelector((s) => s.collaboration);
  const { organization } = useOrganization();

  const collaborationDetails = React.useMemo(() => {
    return currentCollaboration
      ? [
          {
            fields: [
              {
                label: "Deal Name",
                value: {
                  text: !organization ? (
                    currentCollaboration.deal_details.name
                  ) : (
                    <Link
                      to={`/deal/${currentCollaboration.deal_details.id}/case-deal/${currentCollaboration.deal_details.id}/general`}
                      target="_blank"
                    >
                      <OpenInNewIcon fontSize="small" className="mr-1" />
                      {currentCollaboration.deal_details.name}
                    </Link>
                  ),
                },
              },
              {
                label: "Deal Stage",
                value: {
                  text: DEAL_STAGES[currentCollaboration?.deal_details.stage],
                },
              },
              {
                label: "Deal Structure",
                value: {
                  text: DEAL_STRUCTURE_TYPE[
                    currentCollaboration?.deal_details.structure
                  ],
                },
              },
              {
                label: "Tax Credit Structure",
                value: {
                  text: DEAL_TAX_CREDIT_STRUCTURE_TYPE[
                    currentCollaboration?.deal_details.tax_credit_structure
                  ],
                },
              },
            ],
          },
        ]
      : [];
  }, [currentCollaboration]);

  const casesDetails = React.useMemo(() => {
    return currentCollaboration
      ? currentCollaboration.shared_cases.map((caseItem) => ({
          fields: [
            {
              label: caseItem.deal_details.name,
              value: { text: format(new Date(caseItem.created), "MM/dd/yyyy") },
            },
          ],
        }))
      : [];
  }, [currentCollaboration]);

  const accessDetails = React.useMemo(() => {
    return currentCollaboration
      ? currentCollaboration.counterparty_access.map((accessItem) => ({
          fields: [
            {
              label: accessItem.name,
              value: {
                text: format(new Date(accessItem.created), "MM/dd/yyyy"),
              },
            },
          ],
        }))
      : [];
  }, [currentCollaboration]);

  return (
    <>
      {currentCollaboration?.description?.length ? (
        <Box className={cn("!grid !grid-cols-3 !gap-4 mb-4")}>
          <Paper classes={{ root: cn("!p-2") }}>
            <Typography className="!font-semibold !mb-2">
              Description
            </Typography>
            <p>{currentCollaboration?.description}</p>
          </Paper>
        </Box>
      ) : null}
      <Box className={cn("!grid !grid-cols-3 !gap-4")}>
        {currentCollaboration && (
          <>
            <DetailsCard heading="Summary" sections={collaborationDetails} />
            {currentCollaboration.shared_cases.length ? (
              <DetailsCard heading="Cases" sections={casesDetails} />
            ) : null}
            {currentCollaboration.counterparty_access.length ? (
              <DetailsCard heading="Access" sections={accessDetails} />
            ) : null}
          </>
        )}
      </Box>
    </>
  );
}
