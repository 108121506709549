import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { cloneDeep } from "lodash";
import { useLocation, useParams, useNavigate } from "react-router-dom";

import useStyles from "./styles";
import ThemeContext from "../../utils/context/theme-context";
import { useAppSelector } from "../../utils/hooks";
import { ISidebarLink, IMenu } from "../../interfaces";
import { filterSidebarLinks } from "../../utils/helpers";
import { PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION } from "../../constants";
import { FloatingNavItems, PathToABC } from "./common";

interface IProps {
  mobileSidebarOpen: boolean;
  toggleSidebar: () => void;
}

const sidebarWidth = 220;

export default function PersonalAccountSidebar({
  mobileSidebarOpen,
  toggleSidebar,
}: IProps): JSX.Element {
  const styles = useStyles({ sidebarWidth });

  const navigate = useNavigate();
  const { pathname: urlPath } = useLocation();
  const { dealId, projectId, collaborationUuid } = useParams();

  const { currentDeal, currentDealTermDebt } = useAppSelector((s) => s.deal);
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const { darkMode } = React.useContext(ThemeContext);

  const [floatingNavAnchorEl, setFloatingNavAnchorEl] =
    React.useState<null | IMenu>(null);

  const filteredSidebarLinks: ISidebarLink[] = React.useMemo(() => {
    let navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION;
    if (urlPath.includes("/shared-deal/")) {
      navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.filter(
        (n) => n.label === "Collaboration",
      );
    }
    if (urlPath.includes("/project/")) {
      navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.filter(
        (n) => n.label === "Project",
      );
    }
    if (urlPath.includes("/deal/")) {
      navLinks = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.filter(
        (n) => n.label === "Case",
      );
    }
    if (currentDeal) {
      return filterSidebarLinks(
        cloneDeep(navLinks),
        {
          structure: currentDeal.structure,
          tax_credit_structure: currentDeal.tax_credit_structure,
          debt: currentDealTermDebt ? "1" : "",
          is_construction_debt_added: currentDeal.is_construction_debt_added,
        },
        currentDeal,
      );
    }
    return navLinks;
  }, [currentDeal, currentDealTermDebt]);

  const replaceIds = (path: string) => {
    return path
      .replace(":dealId", String(dealId))
      .replace(":projectId", String(projectId))
      .replace(":collaborationUuid", String(collaborationUuid));
  };

  const onLinkClick = (
    e: React.MouseEvent<HTMLElement>,
    page: ISidebarLink,
    type: "base" | "sub",
  ) => {
    e.stopPropagation();
    const { path } = page;
    let naviagteToUrl = replaceIds(path);

    if (type === "sub") {
      const pathToUse = page.subLinks?.length ? page.subLinks[0].path : path;
      naviagteToUrl = replaceIds(pathToUse);
    }

    if (ctrlPressed) {
      window.open(naviagteToUrl, "_blank");
      return;
    }

    navigate(naviagteToUrl);
  };

  const isOnLink = (
    link: ISidebarLink,
    urlSubStr: string,
    label: string,
    type: "base" | "sub",
  ) => {
    return (
      urlPath.includes(urlSubStr) && link.label === label && type === "base"
    );
  };

  const isLinkActive = (link: ISidebarLink, type: "base" | "sub") => {
    let updatedPath = link.path;
    let pathToMatch = link.pathToMatch || "";

    updatedPath = replaceIds(updatedPath);
    pathToMatch = replaceIds(pathToMatch);

    if (
      isOnLink(link, "/deal/", "Case", type) ||
      isOnLink(link, "/project/", "Project", type) ||
      isOnLink(link, "/shared-deal/", "Collaboration", type)
    ) {
      return true;
    }

    const pathToUse = link.pathToMatch ? pathToMatch : updatedPath;
    return Boolean(urlPath.includes(pathToUse));
  };

  const subLinksToKeepOpen = () => {
    if (urlPath.includes("/shared-deal/")) {
      return "Collaboration";
    } else if (urlPath.includes("/deal/")) {
      return "Case";
    } else if (urlPath.includes("/project/")) {
      return "Project";
    } else {
      return "";
    }
  };

  const onHoverOfSubLink = (
    e: React.MouseEvent<HTMLElement>,
    link: ISidebarLink,
    idx: number,
  ) => {
    if (link.subLinks?.length) {
      setFloatingNavAnchorEl({ id: idx, element: e.currentTarget });
    }
  };

  const onMouseOutOfFloatingLinks = () => {
    setFloatingNavAnchorEl(null);
  };

  const renderDrawer = (type: "mobile" | "desktop") => (
    <>
      <List classes={{ root: styles.classes.linkList }}>
        {filteredSidebarLinks.map((page, idx) => {
          const selected = isLinkActive(page, "base");
          const { icon: Icon } = page;

          return (
            <React.Fragment key={idx}>
              <ListItemButton
                selected={selected}
                data-pw={`${type}-${page.label}`}
                onClick={(e) => onLinkClick(e, page, "base")}
                classes={{
                  selected: styles.classes.selectedOption,
                  root: styles.classes.listItemRoot,
                }}
              >
                {Icon && (
                  <div className="w-8">
                    <Icon
                      fillColor={selected || darkMode ? "white" : "black"}
                    />
                  </div>
                )}
                <ListItemText primary={page.label} />
              </ListItemButton>

              {subLinksToKeepOpen() === page.label ? (
                <List>
                  {urlPath.includes("/deal/") &&
                  (!currentDeal || currentDeal.id !== Number(dealId))
                    ? new Array(4)
                        .fill(0)
                        .map((_, idx) => <Skeleton key={idx} height={30} />)
                    : page.subLinks?.map((subPage, subIdx) => {
                        const selectedSubPage = isLinkActive(subPage, "sub");

                        return (
                          <Box
                            key={subIdx}
                            onMouseLeave={onMouseOutOfFloatingLinks}
                          >
                            <ListItemButton
                              selected={selectedSubPage}
                              data-pw={`${type}-${page.label}-${subPage.label}`}
                              onClick={(e) => onLinkClick(e, subPage, "sub")}
                              classes={{
                                selected: styles.classes.activesubLink,
                                root: styles.classes.subLink,
                              }}
                              onMouseEnter={(e) =>
                                onHoverOfSubLink(e, subPage, subIdx)
                              }
                            >
                              <ListItemText primary={subPage.label} />
                              {subPage.subLinks?.length && <ArrowRightIcon />}
                            </ListItemButton>
                            <FloatingNavItems
                              idx={subIdx}
                              links={subPage.subLinks}
                              onLinkClick={onLinkClick}
                              floatingNavAnchorEl={floatingNavAnchorEl}
                              setFloatingNavAnchorEl={setFloatingNavAnchorEl}
                            />
                          </Box>
                        );
                      })}
                </List>
              ) : null}
            </React.Fragment>
          );
        })}
      </List>

      <PathToABC usedOn="shared-deal" />
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileSidebarOpen}
        onClose={toggleSidebar}
        component="nav"
        classes={{ paper: styles.classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          display: { sm: "block", md: "none" },
        }}
      >
        {renderDrawer("mobile")}
      </Drawer>

      <Drawer
        open
        variant="permanent"
        component="nav"
        classes={{ paper: styles.classes.drawerPaper }}
        sx={{
          width: { md: sidebarWidth },
          flexShrink: { md: 0 },
          display: { xs: "none", sm: "none", md: "block" },
          height: "calc(100vh - 68px)",
          ".MuiPaper-root": {
            top: 68,
          },
        }}
      >
        {renderDrawer("desktop")}
      </Drawer>
    </>
  );
}
