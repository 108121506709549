import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Button from "../../../components/button";
import ViewWrapper from "../../../components/view-wrapper";
import EditOutlinedIcon from "../../../components/logos-and-icons/edit-icon";
import CounterpartyFormModal from "../../../components/counterparty-form-modal";
import IconButton from "../../../components/icon-button";
import CounterpartyUserFormModal from "../../../components/counterparty-user-form-modal";
import GroupedTable, { IGroupedData } from "../../../components/grouped-table";
import ConditionalProtect from "../../../components/conditional-protect";
import PopoverMenu from "../../../components/popover-menu";
import { cn } from "../../../utils/helpers";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import { COUNTERPARTY_TYPE } from "../../../constants";
import { setDeleteModalPropsAction } from "../../../utils/redux/slices";
import {
  ICounterparty,
  ICounterpartyForm,
  ICounterpartyFormErrors,
  ICounterpartyUserForm,
  ICounterpartyUser,
  ServerPaginatedResponse,
  ICounterpartyUserFormErrors,
} from "../../../interfaces";

interface IProps {
  getCounterparties: () => Promise<ServerPaginatedResponse<ICounterparty[]>>;
  createCounterparty: (counterparty: ICounterparty) => Promise<ICounterparty>;
  updateCounterparty: (
    id: number,
    counterparty: ICounterparty,
  ) => Promise<ICounterparty>;
  addCounterpartyUser: (
    form: ICounterpartyUserForm,
  ) => Promise<ICounterpartyUser>;
  deleteCounterpartyUser: (userId: number) => Promise<boolean>;
  deleteCounterparty: (id: number) => Promise<boolean>;
}

export default function CounterPartiesView({
  getCounterparties,
  createCounterparty,
  updateCounterparty,
  addCounterpartyUser,
  deleteCounterpartyUser,
  deleteCounterparty,
}: IProps): JSX.Element {
  const dispatch = useDispatch();
  const [addCounterpartyForm, setAddCounterpartyForm] =
    React.useState<ICounterpartyForm>({
      name: "",
      type: "",
    });
  const [editCounterpartyForm, setEditCounterpartyForm] =
    React.useState<ICounterpartyForm>({
      name: "",
      type: "",
    });
  const [isAddCounterpartyModalOpen, setIsAddCounterpartyModalOpen] =
    React.useState(false);
  const [isEditCounterpartyModalOpen, setIsEditCounterpartyModalOpen] =
    React.useState(false);
  const [selectedCounterparty, setSelectedCounterparty] =
    React.useState<ICounterparty | null>(null);
  const [selectedUser, setSelectedUser] =
    React.useState<ICounterpartyUser | null>(null);
  const [addCounterpartyUserForm, setAddCounterpartyUserForm] =
    React.useState<ICounterpartyUserForm>({
      counterparty: null,
      emails: [],
      email: null,
    });
  const [editCounterpartyUserForm, setEditCounterpartyUserForm] =
    React.useState<ICounterpartyUserForm>({
      counterparty: null,
      emails: null,
      email: null,
    });
  const [isAddCounterpartyUserModalOpen, setIsAddCounterpartyUserModalOpen] =
    React.useState(false);
  const [isEditCounterpartyUserModalOpen, setIsEditCounterpartyUserModalOpen] =
    React.useState(false);

  const handleOpenAddCounterpartyModal = () => {
    setIsAddCounterpartyModalOpen(true);
  };

  const handleCloseAddCounterpartyModal = () => {
    setIsAddCounterpartyModalOpen(false);
  };

  const { currentOrg } = useAppSelector((s) => s.org);

  const handleOpenEditCounterpartyModal = (id: number) => {
    setIsEditCounterpartyModalOpen(true);
    const counterparty = counterparties?.results.find((c) => c.id === id);
    counterparty && setEditCounterpartyForm(counterparty);
    counterparty && setSelectedCounterparty(counterparty);
  };

  const handleCloseEditCounterpartyModal = () => {
    setIsEditCounterpartyModalOpen(false);
    setSelectedCounterparty(null);
  };

  const handleCreateCounterparty = async (form: ICounterpartyForm) => {
    const counterparty = await createCounterpartyCallAPI(form);
    counterparty && getCounterpartiesCallAPI();
    counterparty &&
      setAddCounterpartyUserForm({
        counterparty: counterparty.id,
        emails: [],
        email: null,
      });
    counterparty && handleOpenAddCounterpartyUserModal(counterparty.id);
    return counterparty;
  };

  const handleUpdateCounterparty = async (form: ICounterpartyForm) => {
    const counterparty = await updateCounterpartyCallAPI(
      selectedCounterparty?.id,
      form,
    );
    counterparty && getCounterpartiesCallAPI();
    return counterparty;
  };

  const handleOpenAddCounterpartyUserModal = (id: number) => {
    setIsAddCounterpartyUserModalOpen(true);
    const counterparty = counterparties?.results.find((c) => c.id === id);
    counterparty && setSelectedCounterparty(counterparty);
    counterparty &&
      setAddCounterpartyUserForm({
        counterparty: counterparty.id,
        emails: [],
        email: null,
      });
  };

  const handleCloseAddCounterpartyUserModal = () => {
    setIsAddCounterpartyUserModalOpen(false);
    setSelectedCounterparty(null);
  };

  const handleOpenEditCounterpartyUserModal = async (
    counterpartyId: number,
    userId: number,
  ) => {
    const counterparty = counterparties?.results.find(
      (cp) => cp.id === counterpartyId,
    );
    counterparty && setSelectedCounterparty(counterparty);
    const user = counterparty?.counterparty_user_list.find(
      (user) => user.id === userId,
    );
    user && setSelectedUser(user);
    user &&
      counterparty &&
      setEditCounterpartyUserForm({
        counterparty: counterparty.id,
        email: user.email,
        emails: null,
      });
    setIsEditCounterpartyUserModalOpen(true);
  };

  const handleCreateCounterpartyUser = async (form: ICounterpartyUserForm) => {
    delete form.email;
    const counterpartyUser = await addCounterpartyUserCallAPI(form);
    counterpartyUser && getCounterpartiesCallAPI();
    return counterpartyUser;
  };

  const {
    callAPI: getCounterpartiesCallAPI,
    loading: counterpartiesLoading,
    errored: counterpartiesErrored,
    response: counterparties,
  } = useAPI(getCounterparties, { initialLoading: true });

  const {
    callAPI: createCounterpartyCallAPI,
    loading: createCounterpartyLoading,
    fieldErrors: createCounterpartyFieldErrors,
    setFieldErrors: createCounterpartySetFieldErrors,
  } = useAPI<ICounterparty, ICounterpartyFormErrors>(createCounterparty);

  const {
    callAPI: updateCounterpartyCallAPI,
    loading: updateCounterpartyLoading,
    fieldErrors: updateCounterpartyFormErrors,
    setFieldErrors: setUpdateCounterpartyFormErrors,
  } = useAPI<ICounterparty, ICounterpartyFormErrors>(updateCounterparty);

  const {
    callAPI: addCounterpartyUserCallAPI,
    loading: addCounterpartyUserLoading,
    fieldErrors: addCounterpartyUserFieldErrors,
    setFieldErrors: addCounterpartyUserSetFieldErrors,
  } = useAPI<ICounterpartyUser, ICounterpartyUserFormErrors>(
    addCounterpartyUser,
  );

  const {
    callAPI: deleteCounterpartyUserCallAPI,
    loading: isDeletingCounterpartyUser,
    loading: deleteCounterpartyUserLoading,
  } = useAPI(deleteCounterpartyUser);

  const {
    callAPI: deleteCounterpartyCallAPI,
    loading: isDeletingCounterparty,
    loading: deleteCounterpartyLoading,
  } = useAPI(deleteCounterparty);

  const onDeleteCounterpartyUser = async (userId: number) => {
    const deleted = await deleteCounterpartyUserCallAPI(userId);
    if (deleted) {
      getCounterpartiesCallAPI();
    }
  };

  function transformToGroupedData(
    counterparties: ICounterparty[],
  ): IGroupedData<ICounterpartyUser>[] {
    return counterparties.map((counterparty) => ({
      id: counterparty.id,
      name: counterparty.name,
      type: counterparty.type,
      items: counterparty.counterparty_user_list.map(
        (user: ICounterpartyUser) => ({
          id: user.id,
          email: user.email,
          created: user.created,
        }),
      ),
    }));
  }

  const handleDeleteCounterpartyUser = (userId: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Counterparty User",
        description: `Are you sure you want to delete?`,
        onConfirm: () => onDeleteCounterpartyUser(userId),
        loading: deleteCounterpartyUserLoading,
      }),
    );
  };

  React.useEffect(() => {
    if (currentOrg?.is_feature_external_sharing_enabled) {
      getCounterpartiesCallAPI();
    }
  }, []);

  const counterPartyData = React.useMemo(
    () => transformToGroupedData(counterparties?.results || []),
    [counterparties?.results],
  );

  const onDeleteCounterparty = async (counterpartyId: number) => {
    const deleted = await deleteCounterpartyCallAPI(counterpartyId);
    if (deleted) {
      getCounterpartiesCallAPI();
    }
  };

  const handleDeleteCounterparty = (counterpartyId: number) => {
    if (counterpartyId === undefined) {
      return;
    }

    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Counterparty",
        description:
          "Are you sure you want to delete Counterparty? All their users will be deleted and they will lose access to deals/cases they have access to (if any), via external sharing",
        onConfirm: () => onDeleteCounterparty(counterpartyId),
        loading: deleteCounterpartyLoading,
      }),
    );
  };
  console.log(currentOrg?.is_feature_external_sharing_enabled);

  return (
    <ViewWrapper
      loading={
        currentOrg?.is_feature_external_sharing_enabled
          ? counterpartiesLoading
          : false
      }
      error={
        currentOrg?.is_feature_external_sharing_enabled
          ? counterpartiesErrored
          : true
      }
      errorHeading={
        !currentOrg?.is_feature_external_sharing_enabled
          ? "You are not authorized to view this page."
          : ""
      }
      errorDescription={
        !currentOrg?.is_feature_external_sharing_enabled ? (
          <div>
            <p>
              This feature is not currently active for your organization. Please{" "}
              <Link
                className="underline"
                target="_blank"
                to="https://www.capezero.com/contact"
              >
                contact us
              </Link>{" "}
              at{" "}
              <Link className="underline" to="mailto:support@capezero.com">
                support@capezero.com
              </Link>{" "}
              !
            </p>
          </div>
        ) : (
          ""
        )
      }
    >
      <Box className={cn("!mt-4 mb-14")}>
        <Box className={cn("flex justify-end mb-4")}>
          <ConditionalProtect type="counterparty">
            <Button
              startIcon={<AddIcon />}
              onClick={handleOpenAddCounterpartyModal}
              btnType="primary"
              label="Add Counterparty"
            />
          </ConditionalProtect>
        </Box>

        <Paper>
          <GroupedTable<IGroupedData<ICounterpartyUser>, ICounterpartyUser>
            data={counterPartyData}
            onAction={handleOpenAddCounterpartyUserModal}
            onDelete={handleDeleteCounterpartyUser}
            actionLabel="+ Add"
            userPermission="counterparty"
            hideActionColumn
            renderGroupHeader={(group, toggleRow, isOpen) => (
              <Box className={cn("flex justify-between items-center gap-4")}>
                <Box className={cn("flex items-center gap-2")}>
                  <IconButton size="small" onClick={toggleRow}>
                    {isOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                  <Typography>{group.name}</Typography>
                  <Chip
                    label={
                      COUNTERPARTY_TYPE[
                        group.type as keyof typeof COUNTERPARTY_TYPE
                      ]
                    }
                  />
                  <ConditionalProtect type="counterparty">
                    <IconButton
                      onClick={() => handleOpenEditCounterpartyModal(group.id)}
                    >
                      <EditOutlinedIcon fillColor="#5F51F4" />
                    </IconButton>
                    <IconButton
                      className="!text-danger !-ml-4"
                      size="small"
                      onClick={() => handleDeleteCounterparty(group.id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </ConditionalProtect>
                </Box>
              </Box>
            )}
            renderColumns={() => [
              <TableCell key="email">Email</TableCell>,
              <TableCell key="created">Created</TableCell>,
              <TableCell align="right" key="actions">
                Actions
              </TableCell>,
            ]}
            renderItem={(item) => (
              <>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  {format(new Date(item.created), "dd/MM/yyyy")}
                </TableCell>
                <TableCell align="right">
                  <PopoverMenu
                    uniqueId={item.id}
                    canOpenUpgrade
                    items={[
                      {
                        label: "Delete",
                        onClick: () => {
                          handleDeleteCounterpartyUser(item.id);
                        },
                      },
                    ]}
                  />
                </TableCell>
              </>
            )}
          />
        </Paper>

        <CounterpartyFormModal
          open={isAddCounterpartyModalOpen}
          headerLabel="Add Counterparty"
          loading={createCounterpartyLoading}
          form={addCounterpartyForm}
          setForm={setAddCounterpartyForm}
          formErrors={createCounterpartyFieldErrors}
          setFormErrors={createCounterpartySetFieldErrors}
          onClose={handleCloseAddCounterpartyModal}
          onConfirm={handleCreateCounterparty}
        />

        <CounterpartyFormModal
          open={isEditCounterpartyModalOpen}
          headerLabel="Edit Deal Counterparty"
          loading={updateCounterpartyLoading}
          form={editCounterpartyForm}
          setForm={setEditCounterpartyForm}
          formErrors={updateCounterpartyFormErrors}
          setFormErrors={setUpdateCounterpartyFormErrors}
          onClose={handleCloseEditCounterpartyModal}
          onConfirm={handleUpdateCounterparty}
        />

        <CounterpartyUserFormModal
          open={isAddCounterpartyUserModalOpen}
          loading={addCounterpartyUserLoading}
          headerLabel="Add user emails"
          form={addCounterpartyUserForm}
          setForm={setAddCounterpartyUserForm}
          formErrors={addCounterpartyUserFieldErrors}
          setFormErrors={addCounterpartyUserSetFieldErrors}
          onConfirm={handleCreateCounterpartyUser}
          onClose={handleCloseAddCounterpartyUserModal}
          hideFields={["email"]}
        />
      </Box>
    </ViewWrapper>
  );
}
