import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import Charts from "./charts";
import useStyles from "./styles";
import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  ITEMS_AND_TOTAL_TABLE_COLUMN,
  DEAL_STRUCTURE_TYPE,
} from "../../../../../constants";
import {
  generateShortTaxEquityReportSummary,
  generateIrrTaxEquityReportSummary,
  getTaxEquityReportSummaryUrl,
  cn,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  ITaxEquityReportSummary,
  ITaxEquityReportSummaryChart,
  ITaxEquityReportSummaryMaster,
} from "../../../../../interfaces";

interface IProps {
  getTaxEquityReportSummary: (
    dealId: number,
  ) => Promise<ITaxEquityReportSummaryMaster>;
}

export default function DealOutputTaxEquitySummaryView({
  getTaxEquityReportSummary,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const { caseDealId, dealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const [taxEquityReportSummary, setTaxEquityReportSummary] =
    React.useState<ITaxEquityReportSummary>();
  const [taxEquityReportSummaryChart, setTaxEquityReportSummaryChart] =
    React.useState<ITaxEquityReportSummaryChart>();

  const {
    callAPI: getTaxEquityReportSummaryCallAPI,
    errored: getTaxEquityReportSummaryFailed,
    loading: loadingTaxEquityReportSummary,
    errorObj: getTaxEquityReportSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI((dealId: number) => getTaxEquityReportSummary(dealId), {
    initialLoading: true,
  });

  React.useEffect(() => {
    !loadingTaxEquityReportSummary &&
      dispatch(updateDealOutputLoadedAction(!getTaxEquityReportSummaryFailed));
  }, [getTaxEquityReportSummaryFailed, loadingTaxEquityReportSummary]);

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getTaxEquityReportSummaryCallAPI(Number(dealIdToUse)).then((response) => {
        if (response) {
          setTaxEquityReportSummary(response.data);
          setTaxEquityReportSummaryChart(response.data.chart);
        }
      });
    }
  }, [dealIdToUse]);

  const cashShortTableRows: ITableRow[] = React.useMemo(() => {
    if (taxEquityReportSummary) {
      return generateShortTaxEquityReportSummary(
        taxEquityReportSummary.metrics,
      );
    }
    return [];
  }, [taxEquityReportSummary]);

  const tableIrrRows: ITableRow[] = React.useMemo(() => {
    if (taxEquityReportSummary) {
      return generateIrrTaxEquityReportSummary(
        taxEquityReportSummary.metrics,
        taxEquityReportSummary.metrics_warnings,
      );
    }
    return [];
  }, [taxEquityReportSummary]);

  const currentCaseDealStructure = React.useMemo(
    () => currentDeal?.structure,
    [caseDealId],
  );

  return (
    <ViewWrapper
      loading={loadingTaxEquityReportSummary}
      error={getTaxEquityReportSummaryFailed}
      errorHeading={getTaxEquityReportSummaryErrorObj?.heading}
      errorDescription={getTaxEquityReportSummaryErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[getTaxEquityReportSummaryUrl(Number(dealIdToUse))]
      }
    >
      <Box className={cn("flex justify-end my-4")}>
        <ToggleSizingOutputButton />
      </Box>
      <Box className={styles.classes.dealSection}>
        <Box>
          <Box className={styles.classes.innerChartContainer}>
            <ReportTable
              columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
              rows={tableIrrRows}
            />
          </Box>

          <ReportTable
            columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
            rows={cashShortTableRows}
          />
        </Box>
        <Box className={styles.classes.chartContainer}>
          <Box className={styles.classes.innerChartContainer}>
            <ChartWrapper
              title={
                DEAL_STRUCTURE_TYPE[
                  currentCaseDealStructure as keyof typeof DEAL_STRUCTURE_TYPE
                ] === DEAL_STRUCTURE_TYPE.TEP
                  ? "Tax Equity Value Components (NPV)"
                  : "Cash Equity Value Components (NPV)"
              }
            >
              <Charts taxEquityChart={taxEquityReportSummaryChart} />
            </ChartWrapper>
          </Box>
        </Box>
      </Box>
    </ViewWrapper>
  );
}
