import CommonCollaborationCases from "./common-cases";
import {
  IDealCase,
  ServerPaginatedResponse,
  ICollaborationCase,
  ICollaborationCaseForm,
} from "../../../interfaces";

interface IProps {
  createCollaborationSharedCase: (
    uuid: string,
    form: ICollaborationCaseForm,
  ) => Promise<ICollaborationCase>;
  getCollaborationSharedCases: (
    uuId: string,
  ) => Promise<ServerPaginatedResponse<ICollaborationCase[]>>;
  updateCollaborationSharedCase: (
    uuid: string,
    caseId: number,
    form: ICollaborationCaseForm,
  ) => Promise<ICollaborationCase>;
  getDealCases: (
    dealId: number,
  ) => Promise<ServerPaginatedResponse<IDealCase[]>>;
  deleteDeal: (dealId: number) => Promise<boolean>;
}

export default function CollaborationCasesSharedView({
  createCollaborationSharedCase,
  getCollaborationSharedCases,
  updateCollaborationSharedCase,
  deleteDeal,
  getDealCases,
}: IProps) {
  return (
    <CommonCollaborationCases
      createCollaborationSharedCase={createCollaborationSharedCase}
      updateCollaborationSharedCase={updateCollaborationSharedCase}
      getCollaborationSharedCases={getCollaborationSharedCases}
      getDealCases={getDealCases}
      deleteDeal={deleteDeal}
    />
  );
}
