import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import Charts from "./charts";
import useStyles from "./styles";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAxios } from "../../../../../components/axios-provider";
import { useAPI } from "../../../../../utils/hooks";
import { ITEMS_AND_TOTAL_TABLE_COLUMN } from "../../../../../constants";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  generateDebtReportSummary,
  generateShortDebtReportSummary,
  generateIrrDebtReportSummary,
  getDebtReportSummaryUrl,
  cn,
} from "../../../../../utils/helpers";
import {
  ITableRow,
  IDebtReportSummary,
  IDebtReportSummaryMaster,
  IDebtReportSummaryChart,
} from "../../../../../interfaces";

interface IProps {
  getDebtReportSummary: (dealId: number) => Promise<IDebtReportSummaryMaster>;
}
export default function DealOutputDebtSummaryView({
  getDebtReportSummary,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const { caseDealId, dealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const [debtReportSummary, setDebtReportSummary] =
    React.useState<IDebtReportSummary>();
  const [debtReportSummaryChart, setDebtReportSummaryChart] =
    React.useState<IDebtReportSummaryChart | null>();

  const {
    callAPI: getDebtReportSummaryCallAPI,
    errored: getDebtReportSummaryFailed,
    loading: loadingDebtReportSummary,
    errorObj: getDebtReportSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI((dealId: number) => getDebtReportSummary(dealId), {
    initialLoading: true,
  });

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDebtReportSummaryCallAPI(Number(dealIdToUse)).then((response) => {
        if (response) {
          setDebtReportSummary(response.data);
          setDebtReportSummaryChart(response.data.chart);
        }
      });
    }
  }, [dealIdToUse]);

  React.useEffect(() => {
    !loadingDebtReportSummary &&
      dispatch(updateDealOutputLoadedAction(!getDebtReportSummaryFailed));
  }, [getDebtReportSummaryFailed, loadingDebtReportSummary]);

  const cashTableRows: ITableRow[] | null = React.useMemo(() => {
    if (debtReportSummary) {
      return generateDebtReportSummary(debtReportSummary.metrics);
    }
    return [];
  }, [debtReportSummary]);

  const cashShortTableRows: ITableRow[] | null = React.useMemo(() => {
    if (debtReportSummary) {
      return generateShortDebtReportSummary(debtReportSummary.metrics);
    }
    return [];
  }, [debtReportSummary]);

  const tableIrrRows: ITableRow[] | null = React.useMemo(() => {
    if (debtReportSummary) {
      return generateIrrDebtReportSummary(debtReportSummary.metrics);
    }
    return [];
  }, [debtReportSummary]);

  return (
    <ViewWrapper
      loading={loadingDebtReportSummary}
      error={getDebtReportSummaryFailed}
      errorHeading={getDebtReportSummaryErrorObj?.heading}
      errorDescription={getDebtReportSummaryErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[getDebtReportSummaryUrl(Number(dealIdToUse))]
      }
    >
      <Box className={cn("flex justify-end my-4")}>
        <ToggleSizingOutputButton />
      </Box>
      {debtReportSummaryChart &&
      cashShortTableRows &&
      cashTableRows &&
      tableIrrRows ? (
        <Box className={styles.classes.dealSection}>
          <Box>
            <Box className={styles.classes.innerChartContainer}>
              <ReportTable
                columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
                rows={tableIrrRows}
              />
            </Box>
            <ReportTable
              columns={ITEMS_AND_TOTAL_TABLE_COLUMN}
              rows={cashShortTableRows}
            />
          </Box>

          <Box className={styles.classes.chartContainer}>
            <Box className={styles.classes.innerChartContainer}>
              <ChartWrapper title="Debt Value Components (NPV)">
                <Charts debtChart={debtReportSummaryChart} />
              </ChartWrapper>
            </Box>
          </Box>
        </Box>
      ) : (
        <p className={styles.classes.text404}>Debt Summary Not Available!</p>
      )}
    </ViewWrapper>
  );
}
