import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import Charts from "./charts";
import useStyles from "./styles";
import CapitalSourcesChart from "./charts-capital";
import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ChartWrapper from "../../../../../components/chart-wrapper";
import { useAPI, useAppSelector } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import {
  cn,
  getOutputSplitTableRows,
  getOutputPartnershipSplitUrl,
  generatePartnershipAllocRows,
  getOutputPartnershipSummaryUrl,
  generateIrrPartnershipSummaryRows,
} from "../../../../../utils/helpers";
import {
  IRR_PARTNERSHIP_SUMMARY_COLUMN,
  PORTFOLIO_SUMMARY_TABLE_COLUMNS,
} from "../../../../../constants";
import {
  ITableRow,
  ITableColumn,
  ICapitalChart,
  IPartnershipReportSplit,
  IPartnershipReportSummary,
  IPartnershipReportSummaryChart,
  IPartnershipReportSummaryMaster,
} from "../../../../../interfaces";

interface IProps {
  getOutputPartnershipSplit: (
    dealId: number,
  ) => Promise<IPartnershipReportSplit>;
  getOutputPartnershipSummary: (
    dealId: number,
  ) => Promise<IPartnershipReportSummaryMaster>;
  getCapitalChartData: (dealId: number) => Promise<ICapitalChart>;
}

export default function DealOutputPartnershipSummaryView({
  getOutputPartnershipSplit,
  getOutputPartnershipSummary,
  getCapitalChartData,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { dealId, caseDealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const dispatch = useDispatch();
  const { currentDeal, casesOfDeal } = useAppSelector((s) => s.deal);

  const { urlLoadingPercentages } = useAxios();

  const [reportSummary, setReportSummary] =
    React.useState<IPartnershipReportSummary>();
  const [chartData, setChartData] =
    React.useState<IPartnershipReportSummaryChart>();
  const [capitalChartData, setCapitalChartData] =
    React.useState<ICapitalChart>();
  const [report, setReport] = React.useState<IPartnershipReportSplit>();

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getPartnershipSummaryCallAPI(Number(dealIdToUse)).then((response) => {
        if (response) {
          setReportSummary(response.data);
          setChartData(response.data.chart);
        }
      });
      if (activeDeal?.structure !== "EO") {
        getOutputPartnershipSplitCallAPI(Number(dealIdToUse)).then(
          (response) => {
            if (response) {
              setReport(response);
            }
          },
        );
      }
      if (activeDeal?.structure !== "EO") {
        getCapitalChartDataCallAPI(Number(dealIdToUse)).then((response) => {
          if (response) {
            setCapitalChartData(response);
          }
        });
      }
    }
  }, [dealIdToUse]);

  const {
    callAPI: getPartnershipSummaryCallAPI,
    errored: getPartnershipSummaryFailed,
    loading: loadingPartnershipSummary,
    errorObj: partnershipSummaryErrorObj,
    showUsageLimitHitScreen,
  } = useAPI((dealId: number) => getOutputPartnershipSummary(dealId), {
    initialLoading: true,
  });

  React.useEffect(() => {
    !loadingPartnershipSummary &&
      dispatch(updateDealOutputLoadedAction(!getPartnershipSummaryFailed));
  }, [getPartnershipSummaryFailed, loadingPartnershipSummary]);

  const { callAPI: getCapitalChartDataCallAPI } = useAPI(
    (dealId: number) => getCapitalChartData(dealId),
    {
      initialLoading: true,
    },
  );

  const {
    callAPI: getOutputPartnershipSplitCallAPI,
    errored: getSplitReportFailed,
    loading: loadingSplitReport,
    errorObj: getSplitReportErrorObj,
  } = useAPI((dealId: number) => getOutputPartnershipSplit(dealId), {
    initialLoading: true,
  });

  const tableIrrRows: ITableRow[] = React.useMemo(() => {
    return reportSummary
      ? generateIrrPartnershipSummaryRows(reportSummary?.metrics)
      : [];
  }, [reportSummary]);

  const activeDeal = React.useMemo(() => {
    if (caseDealId === dealId || !organization) {
      return currentDeal;
    }
    return casesOfDeal.find((deal) => deal.child_deal.id === Number(caseDealId))
      ?.child_deal;
  }, [caseDealId, casesOfDeal]);

  const allocTableRows: { columns: ITableColumn[]; rows: ITableRow[] } =
    React.useMemo(() => {
      return reportSummary
        ? generatePartnershipAllocRows(
            reportSummary.alloc,
            activeDeal?.structure,
          )
        : { columns: [], rows: [] };
    }, [reportSummary]);

  const taxTableRows: ITableRow[] = React.useMemo(() => {
    return report ? getOutputSplitTableRows(report) : [];
  }, [report]);

  const portfolioSummaryTableColuimns = PORTFOLIO_SUMMARY_TABLE_COLUMNS.map(
    (tc) => {
      if (activeDeal?.structure === "CEP" && tc.id === "tax_equity") {
        return {
          ...tc,
          label: "Cash Equity",
        };
      }
      return tc;
    },
  );

  return (
    <ViewWrapper
      loading={loadingPartnershipSummary}
      error={getPartnershipSummaryFailed}
      errorHeading={partnershipSummaryErrorObj?.heading}
      errorDescription={partnershipSummaryErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getOutputPartnershipSummaryUrl(Number(dealIdToUse))
        ]
      }
    >
      <Box className={styles.classes.dealSection}>
        <Box>
          <ReportTable
            columns={IRR_PARTNERSHIP_SUMMARY_COLUMN}
            rows={tableIrrRows}
          />
          {activeDeal?.structure !== "EO" && (
            <ViewWrapper
              loading={loadingPartnershipSummary}
              error={getPartnershipSummaryFailed}
            >
              <ReportTable
                columns={portfolioSummaryTableColuimns}
                rows={taxTableRows}
              />
            </ViewWrapper>
          )}
        </Box>

        <Box className={cn("flex flex-col gap-4")}>
          <ChartWrapper
            title={`${
              activeDeal?.structure === "EO" ? "Portfolio" : "Partnership"
            } Value Components (NPV)`}
          >
            <Charts data={chartData} />
          </ChartWrapper>

          {capitalChartData && activeDeal?.structure !== "EO" && (
            <ChartWrapper title="Capital Sources">
              <CapitalSourcesChart
                data={capitalChartData}
                dealStructureType={
                  activeDeal?.structure === "CEP" ? "cash-equity" : "tax-equity"
                }
              />
            </ChartWrapper>
          )}
        </Box>
      </Box>

      {/* Show only if its TE or CE structure */}
      {activeDeal?.structure !== "EO" && (
        <ViewWrapper
          loading={loadingSplitReport}
          error={getSplitReportFailed}
          errorHeading={getSplitReportErrorObj?.heading}
          errorDescription={getSplitReportErrorObj?.description}
          loadingPercentage={
            urlLoadingPercentages[
              getOutputPartnershipSplitUrl(Number(dealIdToUse))
            ]
          }
        >
          <Box title="Partnership Allocation">
            <ReportTable
              columns={allocTableRows.columns}
              rows={allocTableRows.rows}
            />
          </Box>
        </ViewWrapper>
      )}
    </ViewWrapper>
  );
}
