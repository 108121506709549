import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Alert from "@mui/material/Alert";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import useStyles from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import LogsButton from "../../../../../components/logs-button";
import PopoverMenu from "../../../../../components/popover-menu";
import ViewWrapper from "../../../../../components/view-wrapper";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ProjectDepreciationStreamFormModal from "../../../../../components/project-depreciation-stream-form-modal";
import StickyTableCell from "../../../../../components/sticky-table-cell";
import ConditionalProtect from "../../../../../components/conditional-protect";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import {
  useAPI,
  useLogs,
  useDrawer,
  useAppSelector,
} from "../../../../../utils/hooks";
import {
  getTableColumnAccordingToStatus,
  numberToUSD,
  replacePagePathForPersonalAccount,
} from "../../../../../utils/helpers";
import {
  DEFAULT_PROJECT_DEPRECIATION_STREAM_FORM,
  PROJECT_DEPRECIATION_STREAM_TYPE,
} from "../../../../../constants";
import {
  ITableColumn,
  IProjectDepreciation,
  IProjectDepreciationStream,
  IAddProjectDepreciationStreamForm,
  IAddProjectDepreciationStreamFormErrors,
  IUpdateProjectDepreciationStreamFormErrors,
  IUpdateProjectDepreciationStreamForm,
  ILogsConfiguration,
} from "../../../../../interfaces";

export const DEPRECIATION_STREAM_TABLE_COLUMNS: ITableColumn[] = [
  { id: "type", label: "Type", minWidth: 180, align: "left" },
  { id: "amount", label: "Amount", minWidth: 80, align: "right" },
  {
    id: "percentage",
    label: "Percentage of FMV",
    minWidth: 80,
    align: "right",
  },
  {
    id: "itc_eligibility",
    label: "ITC Eligibility",
    minWidth: 80,
    align: "right",
  },
  {
    id: "itc_eligible_amount",
    label: "ITC Eligibile Amount",
    minWidth: 80,
    align: "right",
  },
];

interface IProps {
  getProjectDepreciation: (
    projectId: number,
  ) => Promise<IProjectDepreciation[]>;
  addProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    form: IAddProjectDepreciationStreamForm,
  ) => Promise<IProjectDepreciationStream>;
  updateProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
    form: IUpdateProjectDepreciationStreamForm,
  ) => Promise<IProjectDepreciationStream>;
  deleteProjectDepreciationStream: (
    projectId: number,
    depreciationId: number,
    streamId: number,
  ) => Promise<boolean>;
}

export default function ProjectDepreciationStreams({
  getProjectDepreciation,
  addProjectDepreciationStream,
  updateProjectDepreciationStream,
  deleteProjectDepreciationStream,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { organization } = useOrganization();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { currentProject } = useAppSelector((s) => s.project);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [currentdepreciationId, setCurrentdepreciationId] =
    React.useState<number>();
  const [total_amount, setTotalAmount] = React.useState<number>(0);
  const [total_percentage, setTotalPercentage] = React.useState<number | 0>();
  const [total_itc_eligible_amount, setTotalITCEligibleAmount] =
    React.useState<number>(0);
  const [selectedStreamToUpdate, setSelectedStreamToUpdate] = React.useState<{
    depreciationId: number;
    streamId: number;
  }>();
  const [addDepreciationStreamModalOpen, setAddDepreciationStreamModalOpen] =
    React.useState<boolean>(false);
  const [
    UpdateDepreciationStreamModalOpen,
    setUpdateDepreciationStreamModalOpen,
  ] = React.useState<boolean>(false);
  const [depreciationStreams, setDepreciationStreams] = React.useState<
    IProjectDepreciationStream[]
  >([]);
  const [form, setForm] = React.useState<
    IAddProjectDepreciationStreamForm | IUpdateProjectDepreciationStreamForm
  >(DEFAULT_PROJECT_DEPRECIATION_STREAM_FORM);

  React.useEffect(() => {
    handleGetProjectDepreciation(Number(projectId));
  }, [projectId]);

  const {
    callAPI: getProjectDepreciationCallAPI,
    loading: loadingDepreciations,
    errored: getDepreciationsFailed,
  } = useAPI((projectId: number) => getProjectDepreciation(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: addProjectDepreciationStreamCallAPI,
    fieldErrors: addProjectDepreciationStreamFormErrors,
    setFieldErrors: setAddProjectDepreciationStreamFormErrors,
    loading: addDepreciationStreamLoading,
  } = useAPI<
    IProjectDepreciationStream,
    IAddProjectDepreciationStreamFormErrors
  >(
    (
      projectId: number,
      depreciationId: number,
      form: IAddProjectDepreciationStreamForm,
    ) => addProjectDepreciationStream(projectId, depreciationId, form),
  );

  const {
    callAPI: updateProjectDepreciationStreamCallAPI,
    fieldErrors: updateProjectDepreciationStreamFormErrors,
    setFieldErrors: setUpdateProjectDepreciationStreamFormErrors,
    loading: updateDepreciationStreamLoading,
  } = useAPI<
    IProjectDepreciationStream,
    IUpdateProjectDepreciationStreamFormErrors
  >(
    (
      projectId: number,
      depreciationId: number,
      streamId: number,
      form: IUpdateProjectDepreciationStreamForm,
    ) =>
      updateProjectDepreciationStream(
        projectId,
        depreciationId,
        streamId,
        form,
      ),
  );

  const { callAPI: deleteProjectDepreciationStreamCallAPI } = useAPI(
    (projectId: number, depreciationId: number, streamId: number) =>
      deleteProjectDepreciationStream(projectId, depreciationId, streamId),
    { setConfirmModalLoading: true },
  );

  const handleGetProjectDepreciation = async (projectId: number) => {
    const depreciations = await getProjectDepreciationCallAPI(projectId);

    if (depreciations) {
      setTotalAmount(depreciations[0]?.total_amount);
      setTotalPercentage(depreciations[0]?.total_percentage);
      setTotalITCEligibleAmount(depreciations[0]?.total_itc_eligible_amount);
      setCurrentdepreciationId(depreciations[0]?.id);
      setDepreciationStreams(depreciations[0]?.streams || []);
    }
  };

  const handleAddDepreciationStream = async (
    form: IAddProjectDepreciationStreamForm,
  ) => {
    const depreciationStream = await addProjectDepreciationStreamCallAPI(
      Number(projectId),
      Number(currentdepreciationId),
      form,
    );

    if (depreciationStream) {
      setDepreciationStreams([...depreciationStreams, depreciationStream]);
      await handleGetProjectDepreciation(Number(projectId));
    }

    return depreciationStream;
  };

  const handleUpdateDepreciationStream = async (
    form: IUpdateProjectDepreciationStreamForm,
  ) => {
    const depreciationStream = await updateProjectDepreciationStreamCallAPI(
      Number(projectId),
      Number(selectedStreamToUpdate?.depreciationId),
      Number(selectedStreamToUpdate?.streamId),
      form,
    );

    if (depreciationStream) {
      const updatedDepreciationStreams = depreciationStreams.map((d) => {
        if (d.id === depreciationStream.id) {
          return depreciationStream;
        }

        return d;
      });

      setDepreciationStreams(updatedDepreciationStreams);
      await handleGetProjectDepreciation(Number(projectId));
    }

    return depreciationStream;
  };

  const filteredBySearchRows: IProjectDepreciationStream[] =
    React.useMemo(() => {
      const streams = depreciationStreams.filter((d) =>
        PROJECT_DEPRECIATION_STREAM_TYPE[d.type].toLowerCase(),
      );

      // Move NONDEP steam type to the bottom of list
      const nonDepIndex = streams.findIndex(
        (stream) => stream.type === "NONDEP",
      );
      if (nonDepIndex !== -1) {
        const nonDepStream = streams.splice(nonDepIndex, 1)[0];
        streams.push(nonDepStream);
      }

      return streams;
    }, [depreciationStreams]);

  const handleOpenAddDepreciationStreamModal = () => {
    setAddDepreciationStreamModalOpen(true);
  };

  const handleOpenEditDepreciationStreamModal = (
    stream: IProjectDepreciationStream,
  ) => {
    setForm({
      type: stream.type,
      percentage: stream.percentage,
      itc_eligibility: stream.itc_eligibility,
    });
    setSelectedStreamToUpdate({
      depreciationId: stream.project_depreciation,
      streamId: stream.id,
    });
    setUpdateDepreciationStreamModalOpen(true);
  };

  const handleCloseUpdateDepreciationStreamModal = () => {
    setSelectedStreamToUpdate(undefined);
    setUpdateDepreciationStreamModalOpen(false);
  };

  const handleCloseAddDepreciationStreamModal = () => {
    setAddDepreciationStreamModalOpen(false);
  };

  const goToStream = (stream: IProjectDepreciationStream) => {
    let pathToUse = `/project/${projectId}/tax/depreciation/${stream.project_depreciation}/stream/${stream.id}`;

    if (!organization) {
      pathToUse = replacePagePathForPersonalAccount(
        pathToUse,
        "project",
      ) as string;
    }

    if (ctrlPressed) {
      window.open(pathToUse, "_blank");
      return;
    }
    navigate(pathToUse);
  };

  const handleOnDeleteDepreciationClick = async (
    stream: IProjectDepreciationStream,
  ) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Depreciation Stream",
        description:
          "Are you sure you want to delete this depreciation stream?",
        onConfirm: () => handleOnDeleteDepreciationStream(stream),
      }),
    );
  };

  const handleOnDeleteDepreciationStream = async (
    stream: IProjectDepreciationStream,
  ) => {
    const response = await deleteProjectDepreciationStreamCallAPI(
      Number(projectId),
      stream.project_depreciation,
      stream.id,
    );

    if (response) {
      setDepreciationStreams((depreciationStreams) => {
        return depreciationStreams.filter((d) => d.id !== stream.id);
      });
      await handleGetProjectDepreciation(Number(projectId));
    }
  };

  const depreciationLogConfiguration: ILogsConfiguration = {
    id: Number(currentdepreciationId),
    type: "projectdepreciation",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      depreciationLogConfiguration.type,
      depreciationLogConfiguration.id,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingDepreciations}
        error={getDepreciationsFailed}
      >
        <Box>
          {total_percentage && Number(total_percentage) < 99.99 && (
            <Alert className="my-3" variant="outlined" severity="error">
              Total deprecation is less than 100%
            </Alert>
          )}
          <Box className={styles.classes.topSection}>
            <ConditionalProtect type="project">
              <LogsButton onClick={handleOnOpenLogs} />

              <Button
                canOpenUpgrade
                startIcon={<AddIcon />}
                btnType="primary"
                label="Add Depreciation"
                onClick={handleOpenAddDepreciationStreamModal}
              />
            </ConditionalProtect>
          </Box>

          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer classes={{ root: styles.classes.tableContainer }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {getTableColumnAccordingToStatus(
                      DEPRECIATION_STREAM_TABLE_COLUMNS,
                      currentProject?.status as string,
                    ).map((column, idx) => {
                      if (column.id === "action") {
                        return (
                          <StickyTableCell
                            key={idx}
                            direction="right"
                            fixedColumnWidth={80}
                            align="center"
                            highZIndex
                          >
                            {column.label}
                          </StickyTableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left"}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredBySearchRows.map((stream, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={styles.classes.dataRow}
                        onClick={() => goToStream(stream)}
                      >
                        <TableCell align="left">
                          {PROJECT_DEPRECIATION_STREAM_TYPE[stream.type]}
                        </TableCell>
                        <TableCell align="right">
                          {numberToUSD.format(stream.amount)}
                        </TableCell>
                        <TableCell align="right">
                          {stream.percentage}%
                        </TableCell>
                        <TableCell align="right">
                          {stream.is_itc_eligible
                            ? (stream.itc_eligibility ?? 0) + "%"
                            : ""}
                        </TableCell>
                        <TableCell align="right">
                          {stream.is_itc_eligible
                            ? numberToUSD.format(
                                stream.itc_eligible_amount ?? 0,
                              )
                            : ""}
                        </TableCell>
                        <ConditionalProtect type="project">
                          <StickyTableCell
                            direction="right"
                            fixedColumnWidth={80}
                            align="center"
                          >
                            <PopoverMenu
                              uniqueId={idx}
                              canOpenUpgrade
                              items={[
                                {
                                  label: "Edit",
                                  onClick: () =>
                                    handleOpenEditDepreciationStreamModal(
                                      stream,
                                    ),
                                },
                                {
                                  label: "Delete",
                                  onClick: () =>
                                    handleOnDeleteDepreciationClick(stream),
                                },
                              ]}
                            />
                          </StickyTableCell>
                        </ConditionalProtect>
                      </TableRow>
                    );
                  })}
                  <TableRow tabIndex={-1} className={styles.classes.dataRow}>
                    <TableCell align="left">
                      <b>Total</b>
                    </TableCell>
                    <TableCell align="right">
                      <b>{numberToUSD.format(total_amount)}</b>
                    </TableCell>
                    <TableCell align="right">
                      <b
                        className={
                          total_percentage && Number(total_percentage) < 99.99
                            ? "text-red-500"
                            : ""
                        }
                      >
                        {total_percentage}%
                      </b>
                    </TableCell>
                    {/** empty cell to make the row span all columns */}
                    <TableCell align="left"></TableCell>
                    <TableCell align="right">
                      <b>{numberToUSD.format(total_itc_eligible_amount)}</b>
                    </TableCell>
                    {/** empty cell to make the row span all columns */}
                    <StickyTableCell
                      direction="right"
                      fixedColumnWidth={80}
                    ></StickyTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </ViewWrapper>

      <ProjectDepreciationStreamFormModal
        headerLabel="Add Depreciation"
        open={addDepreciationStreamModalOpen}
        formErrors={addProjectDepreciationStreamFormErrors}
        loading={addDepreciationStreamLoading}
        setFormErrors={setAddProjectDepreciationStreamFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseAddDepreciationStreamModal}
        onConfirm={handleAddDepreciationStream}
      />

      <ProjectDepreciationStreamFormModal
        headerLabel="Edit Depreciation"
        open={UpdateDepreciationStreamModalOpen}
        loading={updateDepreciationStreamLoading}
        formErrors={updateProjectDepreciationStreamFormErrors}
        setFormErrors={setUpdateProjectDepreciationStreamFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateDepreciationStreamModal}
        onConfirm={handleUpdateDepreciationStream}
      />
      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={depreciationLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={depreciationLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}
