import Layout from "../../../components/layout";
import SharedDealAnalysisView from "../../../views/personal-account/shared-deal/analysis";
import CollaborationPageLayout from "../../../components/collaboration-page-layout";
import { getDealComparison } from "../../../apis/deal-comparison/base";

export default function SharedDealAnalysisPage(): JSX.Element {
  return (
    <Layout title="Shared Deal Analysis">
      <CollaborationPageLayout>
        <SharedDealAnalysisView getDealComparison={getDealComparison} />
      </CollaborationPageLayout>
    </Layout>
  );
}
