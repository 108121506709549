import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import FormHelperText from "@mui/material/FormHelperText";
import Switch from "@mui/material/Switch";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

import Modal from "../modal";
import CheckboxInput from "../checkbox-input";
import {
  ISelectOption,
  ICollaborationCounterparty,
  ICollaborationCounterpartyForm,
  ICollaborationCounterpartyFormErrors,
} from "../../interfaces";

interface IProps {
  open: boolean;
  headerLabel: string;
  loading: boolean;
  formErrors?: ICollaborationCounterpartyFormErrors;
  setFormErrors: React.Dispatch<
    React.SetStateAction<ICollaborationCounterpartyFormErrors | undefined>
  >;
  form: ICollaborationCounterpartyForm;
  setForm: React.Dispatch<React.SetStateAction<ICollaborationCounterpartyForm>>;
  onClose: () => void;
  onConfirm: (
    form: ICollaborationCounterpartyForm,
  ) => Promise<ICollaborationCounterparty | undefined>;
  userOptions: ISelectOption[];
}

export default function CollaborationCounterpartyFormModal({
  open,
  headerLabel,
  loading,
  formErrors,
  setFormErrors,
  form,
  setForm,
  onClose,
  onConfirm,
  userOptions,
}: IProps): JSX.Element {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userId = Number(e.target.name);
    setForm((prev) => ({
      ...prev,
      counterparty_users: e.target.checked
        ? [...prev.counterparty_users, userId]
        : prev.counterparty_users.filter((id) => id !== userId),
    }));
    setFormErrors((prev) => ({
      ...prev,
      counterparty_users: "",
    }));
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((prev) => {
      const counterparty_users = e.target.checked
        ? userOptions.map((user) => Number(user.value))
        : [];
      return {
        ...prev,
        counterparty_users,
      };
    });
  };

  const handleOnConfirm = async () => {
    const collaboration = await onConfirm(form);
    collaboration && onClose();
    collaboration && handleResetForm();
  };

  const handleResetForm = () => {
    setForm({
      counterparty: null,
      counterparty_users: [],
    });
    setFormErrors({});
  };

  return (
    <Modal
      maxWidth="xs"
      open={open}
      form={form}
      loading={loading}
      heading={headerLabel}
      onClose={onClose}
      onConfirm={handleOnConfirm}
      resetForm={handleResetForm}
      classes={{ paper: "!max-w-[600px]" }}
    >
      <Box>
        <Alert severity="info">
          You can add users for this counterparty in the{" "}
          <Link to="/configuration/data/counterparties" className="underline">
            Configuration
          </Link>
          .
        </Alert>
        <Box className="flex flex-col">
          <Box className="flex items-center">
            <span>Select All Users</span>
            <Switch
              checked={form.counterparty_users.length === userOptions.length}
              onChange={handleSwitchChange}
              name="selectAll"
              color="primary"
            />
          </Box>
          <Divider className="my-4" />
          {userOptions.length === 0 && <p className="my-4">No users found</p>}
          {userOptions.map((user, idx) => {
            return (
              <CheckboxInput
                key={idx}
                onChange={handleCheckboxChange}
                name={user.value}
                checked={form.counterparty_users.includes(Number(user.value))}
                label={user.label}
              />
            );
          })}
        </Box>
        <FormHelperText error={Boolean(formErrors?.counterparty_users)}>
          {formErrors?.counterparty_users}
        </FormHelperText>
      </Box>
    </Modal>
  );
}
