import { CONFIGURATION_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IAddCurveGroupForm,
  ICurveGroup,
  IDateScheduleParams,
  IGetCurvesParams,
  IOrganization,
  IOrganizationCurve,
  IProductUsageDetails,
  ICurveForm,
  IUpdateCurveGroupForm,
  ServerPaginatedResponse,
  IUserData,
  IArrayDataModifyPayload,
  IArrayDataModifyResponse,
} from "../../interfaces";

export const getOrganization = async () => {
  return await AxiosHelper.get<IOrganization>(CONFIGURATION_ROUTES.base.get);
};

export const getProductUsageDetails = async () => {
  return await AxiosHelper.get<IProductUsageDetails>(
    CONFIGURATION_ROUTES.base.usage,
  );
};

export const getCurveGroups = async () => {
  return await AxiosHelper.get<ServerPaginatedResponse<ICurveGroup[]>>(
    CONFIGURATION_ROUTES.data.curves.curveGroups,
  );
};

export const createCurveGroup = async (form: IAddCurveGroupForm) => {
  return await AxiosHelper.post<IAddCurveGroupForm, ICurveGroup>(
    CONFIGURATION_ROUTES.data.curves.curveGroups,
    form,
  );
};

export const updateCurveGroup = async (
  id: number,
  form: IUpdateCurveGroupForm,
) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.curves.editCurveGroup, {
    id,
  });
  return await AxiosHelper.put<IUpdateCurveGroupForm, ICurveGroup>(url, form);
};

export const deleteCurveGroup = async (id: number) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.curves.deleteCurveGroup, {
    id,
  });
  return await AxiosHelper.delete(url);
};

export const getCurves = async (params: IGetCurvesParams) => {
  const { curve_group, curve_type } = params;
  return await AxiosHelper.get<ServerPaginatedResponse<IOrganizationCurve[]>>(
    CONFIGURATION_ROUTES.data.curves.curves,
    {
      params: {
        curve_group__in: curve_group,
        curve_type__in: curve_type?.join(","),
      },
    },
  );
};

export const addCurve = async (form: ICurveForm) => {
  return await AxiosHelper.post<ICurveForm, IOrganizationCurve>(
    CONFIGURATION_ROUTES.data.curves.curves,
    form,
  );
};

export const getCurve = async (id: number) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.curves.getCurve, {
    id,
  });
  return await AxiosHelper.get<IOrganizationCurve>(url);
};

export const editCurve = async (
  form: ICurveForm,
  id: number,
  method: "put" | "patch" = "put",
) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.curves.editCurve, {
    id,
  });
  return await AxiosHelper[method]<ICurveForm, IOrganizationCurve>(url, form);
};

export const deleteCurve = async (id: number) => {
  const url = replaceParams(CONFIGURATION_ROUTES.data.curves.deleteCurve, {
    id,
  });
  return await AxiosHelper.delete(url);
};

export const getDateSchedule = async (params: IDateScheduleParams) => {
  const { periodicity, start_date, term_years } = params;
  return await AxiosHelper.get<{ schedule_dates: string[] }>(
    CONFIGURATION_ROUTES.dates_schedule.get,
    {
      params: {
        periodicity,
        start_date,
        term_years,
      },
    },
  );
};

export const getUser = async () => {
  return await AxiosHelper.get<IUserData>(CONFIGURATION_ROUTES.user.getUser);
};

export const modifyArrayData = async (payload: IArrayDataModifyPayload) => {
  return await AxiosHelper.post<
    IArrayDataModifyPayload,
    IArrayDataModifyResponse
  >(CONFIGURATION_ROUTES.data.curves.modifyCurve, payload);
};
