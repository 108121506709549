import React from "react";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";

import useStyles from "./styles";
import PopoverMenu from "../popover-menu";
import { cn } from "../../utils/helpers";
import { UserPermissionType } from "../../interfaces";
import ConditionalProtect from "../conditional-protect";

interface IGroupedTableProps<T, U> {
  data: T[];
  onEdit?: (...args: number[]) => void;
  onDelete?: (...args: number[]) => void;
  onAction: (...args: number[]) => void;
  renderGroupHeader: (
    group: T,
    toggleRow: () => void,
    isOpen: boolean,
  ) => JSX.Element;
  renderItem: (item: U) => JSX.Element;
  renderColumns: () => JSX.Element[];
  actionLabel: string;
  disableActions?: boolean;
  hideActionColumn?: boolean;
  userPermission: UserPermissionType;
}

export interface IGroupedData<U> {
  id: number;
  name: string;
  type: string;
  items: U[];
}

export default function GroupedTable<
  T extends IGroupedData<U>,
  U extends { id: number },
>({
  data,
  onEdit,
  onAction,
  onDelete,
  renderGroupHeader,
  renderItem,
  renderColumns,
  actionLabel,
  userPermission,
  disableActions = false,
  hideActionColumn = false,
}: IGroupedTableProps<T, U>): JSX.Element {
  const styles = useStyles();

  const [openRows, setOpenRows] = React.useState<{ [key: number]: boolean }>(
    () => {
      const initialState: { [key: number]: boolean } = {};
      data.forEach((_, index) => {
        initialState[index] = true; // make it expanded by default
      });
      return initialState;
    },
  );

  const toggleRow = (index: number) => {
    setOpenRows((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Table>
      <TableHead classes={{ root: styles.classes.tableHeader }}>
        <TableRow>
          {renderColumns()}
          <ConditionalProtect type={userPermission}>
            {!hideActionColumn && <TableCell align="right">Action</TableCell>}
          </ConditionalProtect>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.length === 0 ? (
          <TableRow>
            <TableCell
              colSpan={
                hideActionColumn
                  ? renderColumns().length
                  : renderColumns().length + 1
              }
              align="center"
            >
              No data found
            </TableCell>
          </TableRow>
        ) : (
          data.map((group, index) => (
            <React.Fragment key={group.id}>
              <TableRow>
                <TableCell
                  colSpan={
                    hideActionColumn
                      ? renderColumns().length
                      : renderColumns().length + 1
                  }
                >
                  {renderGroupHeader(
                    group,
                    () => toggleRow(index),
                    openRows[index],
                  )}
                </TableCell>
              </TableRow>
              {openRows[index] &&
                group.items.map((item: U, itemIndex) => (
                  <TableRow key={`${group.id}-${itemIndex}`}>
                    {renderItem(item)}
                    <ConditionalProtect type={userPermission}>
                      {!hideActionColumn && (
                        <TableCell align="right">
                          <PopoverMenu
                            uniqueId={itemIndex}
                            canOpenUpgrade
                            items={[
                              {
                                label: "Edit",
                                onClick: () =>
                                  onEdit && onEdit(group.id, item.id),
                              },
                              {
                                label: "Delete",
                                onClick: () =>
                                  onDelete && onDelete(group.id, item.id),
                              },
                            ]}
                          />
                        </TableCell>
                      )}
                    </ConditionalProtect>
                  </TableRow>
                ))}
              <ConditionalProtect type={userPermission}>
                {openRows[index] && (
                  <TableRow>
                    <TableCell
                      colSpan={
                        hideActionColumn
                          ? renderColumns().length
                          : renderColumns().length + 1
                      }
                      align="left"
                    >
                      <Button
                        size="small"
                        variant="text"
                        classes={{ root: cn("!my-2 !text-secondary") }}
                        onClick={() => onAction(group.id)}
                      >
                        {actionLabel}
                      </Button>
                    </TableCell>
                  </TableRow>
                )}
              </ConditionalProtect>
            </React.Fragment>
          ))
        )}
      </TableBody>
    </Table>
  );
}
