import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../../components/view-wrapper";
import ReportTable from "../../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../../utils/hooks";
import { DEBT_FACILITY_REPORT_TABLE_COLUMNS } from "../../../../../constants";
import { downloadUserDealReport } from "../../../../../apis/report/deal";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { useAxios } from "../../../../../components/axios-provider";
import {
  cn,
  getDealReportingUrl,
  generateDebtFacilityReportTableRows,
} from "../../../../../utils/helpers";
import {
  IReport,
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  OutputReportPeriodicityType,
} from "../../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IReport>;
}

export default function DealOutputDebtFacilityView({
  getDealReporting,
}: IProps): JSX.Element {
  const { caseDealId, dealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const dispatch = useDispatch();

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDealReportingCallAPI(Number(dealIdToUse), "default").then(
        (response) => {
          if (response?.["loan/combined(all)"]) {
            setReport(response);
          }
        },
      );
    }
  }, [dealIdToUse]);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) => getDealReporting(dealId, "debt", "loans", term, reportPeriodicityType),
    { initialLoading: true },
  );

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const facilityTableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateDebtFacilityReportTableRows(report) : [];
  }, [report]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(dealIdToUse),
        "debt",
        "loans",
        "default",
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealReportingUrl(Number(dealIdToUse), "debt", "loans", "default")
        ]
      }
    >
      <Box>
        <Box className={cn("flex justify-end gap-2 my-4")}>
          <ToggleSizingOutputButton />
          <IconButton
            title="Download Report"
            onClick={handleDownloadReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        <ReportTable
          columns={DEBT_FACILITY_REPORT_TABLE_COLUMNS}
          rows={facilityTableRows}
          boldTotal
          stickyTotal
          bigHeader
        />
      </Box>
    </ViewWrapper>
  );
}
