import React from "react";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import Charts from "./charts";
import DebtChart from "./debt-balance-charts";
import useStyles from "./styles";
import ChartWrapper from "../../../../../components/chart-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import ToggleSizingOutputButton from "../../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../../utils/hooks";
import { useAxios } from "../../../../../components/axios-provider";
import { updateDealOutputLoadedAction } from "../../../../../utils/redux/slices";
import { cn, getDealDebtServiceChartUrl } from "../../../../../utils/helpers";
import {
  ICashReportChart,
  IDealDebtBalanceChart,
} from "../../../../../interfaces";

interface IProps {
  getDealDebtServiceChart: (
    dealId: number,
    is_percent: string,
  ) => Promise<ICashReportChart | null>;
  getDealDebtBalanceChart: (
    dealId: number,
    is_percent: string,
  ) => Promise<IDealDebtBalanceChart | null>;
}

export default function DealOutputDebtChartsView({
  getDealDebtServiceChart,
  getDealDebtBalanceChart,
}: IProps): JSX.Element {
  const styles = useStyles();

  const { caseDealId, dealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const dispatch = useDispatch();

  const { urlLoadingPercentages } = useAxios();

  const [debtServiceChart, setDebtServiceChartView] =
    React.useState<ICashReportChart>();
  const [debtServiceChartPercent, setDebtServiceChartViewPercent] =
    React.useState<ICashReportChart>();
  const [debtBalanceChart, setDebtBalanceChartView] =
    React.useState<IDealDebtBalanceChart>();

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDealDebtServiceChartCallAPI(Number(dealIdToUse), "no").then(
        (response) => {
          if (response) {
            setDebtServiceChartView(response);
          }
        },
      );
      getDealDebtServiceChartCallAPI(Number(dealIdToUse), "yes").then(
        (response) => {
          if (response) {
            setDebtServiceChartViewPercent(response);
          }
        },
      );
      getDealDebtBalanceChartCallAPI(Number(dealIdToUse), "no").then(
        (response) => {
          if (response) {
            setDebtBalanceChartView(response);
          }
        },
      );
    }
  }, [dealIdToUse]);

  const {
    callAPI: getDealDebtServiceChartCallAPI,
    errored: getDealDebtServiceChartFailed,
    loading: loadingDealDebtServiceChart,
    errorObj: getDealDebtServiceChartErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (dealId: number, is_percent: string) =>
      getDealDebtServiceChart(dealId, is_percent),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    !loadingDealDebtServiceChart &&
      dispatch(updateDealOutputLoadedAction(!getDealDebtServiceChartFailed));
  }, [getDealDebtServiceChartFailed, loadingDealDebtServiceChart]);

  const { callAPI: getDealDebtBalanceChartCallAPI } = useAPI(
    (dealId: number, is_percent: string) =>
      getDealDebtBalanceChart(dealId, is_percent),
  );

  return (
    <ViewWrapper
      loading={loadingDealDebtServiceChart}
      error={getDealDebtServiceChartFailed}
      errorHeading={getDealDebtServiceChartErrorObj?.heading}
      errorDescription={getDealDebtServiceChartErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={
        urlLoadingPercentages[
          getDealDebtServiceChartUrl(Number(dealIdToUse), "yes")
        ]
      }
    >
      <Box className={cn("flex justify-end my-4")}>
        <ToggleSizingOutputButton />
      </Box>
      {debtServiceChart && debtServiceChartPercent && (
        <Box className={styles.classes.chartsContainer}>
          <ChartWrapper title="Debt Service">
            <Charts
              cashReportChart={debtServiceChart}
              stack
              isPercentage={false}
              label="Debt Service"
            />
          </ChartWrapper>
          <ChartWrapper title="Debt Service">
            <Charts
              cashReportChart={debtServiceChartPercent}
              stack={true}
              isPercentage
              label="Debt Service"
            />
          </ChartWrapper>
        </Box>
      )}

      {debtBalanceChart && (
        <Box className={styles.classes.chartsContainer}>
          <ChartWrapper title="Debt Balance">
            <DebtChart
              cashReportChart={debtBalanceChart}
              stack={false}
              isPercentage={false}
              label="Debt Balance"
            />
          </ChartWrapper>
        </Box>
      )}
    </ViewWrapper>
  );
}
