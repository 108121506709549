import React from "react";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { useParams } from "react-router-dom";

import StatusChip from "../../../../components/general/Chip";
import Tooltip from "../../../../components/tooltip";
import { useAPI, useAppSelector } from "../../../../utils/hooks";
import { cn, getFilledChipColor, trimString } from "../../../../utils/helpers";
import { IDealComparison } from "../../../../interfaces";
import {
  DEAL_STATUS,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
} from "../../../../constants";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from "@mui/x-data-grid-pro";
import { isString } from "lodash";

interface IProps {
  getDealComparison: (uuid: string) => Promise<IDealComparison>;
}

export default function SharedDealAnalysisView({
  getDealComparison,
}: IProps): JSX.Element {
  const { collaborationUuid } = useParams();

  const { currentCollaboration } = useAppSelector((s) => s.collaboration);

  const [dealComparisonData, setDealComparisonData] =
    React.useState<IDealComparison>();

  const {
    callAPI: getDealComparisonCallAPI,
    errored: getDealComparisonFailed,
    loading: loadingDealComparison,
  } = useAPI((uuid: string) => getDealComparison(uuid), {
    initialLoading: true,
  });

  React.useEffect(() => {
    if (currentCollaboration) {
      getDealComparisonCallAPI(currentCollaboration.deal_comparison_uuid).then(
        (res) => {
          res && setDealComparisonData(res);
        },
      );
    }
  }, [currentCollaboration]);

  const dealIds: number[] = React.useMemo(() => {
    return (dealComparisonData?.deals_to_compare || []).map((d) => d.id);
  }, [dealComparisonData]);

  const columns: GridColDef[] = [
    ...dealIds.map((dId) => ({
      field: String(dId),
      headerName: dealComparisonData?.deals_to_compare?.find((d) => {
        return d?.id === dId;
      })?.name,
      width: 250,
      headerClassName: () => {
        if (dId === dealComparisonData?.benchmark_deal?.id) {
          return "dealHeading bgGray";
        }
        return "dealHeading";
      },
      cellClassName: () => {
        if (dId === dealComparisonData?.benchmark_deal?.id) {
          return "bgGray";
        }
        return "";
      },
      sortable: false,
      renderHeader: (params: GridColumnHeaderParams) => {
        const headerName = params?.colDef?.headerName;
        // if (params?.colDef?.field == String(dealId)) {
        //   headerName = "Base Case";
        // }
        return (
          <Tooltip title={headerName} placement="top-end">
            <div
              className={cn(
                "w-full flex items-center justify-between whitespace-normal break-words",
              )}
            >
              <strong>{trimString(headerName, 45)}</strong>
            </div>
          </Tooltip>
        );
      },
      renderCell: (params: GridCellParams) => {
        if (params.row.hierarchy?.[0] === "General" && isString(params.value)) {
          const fullValue = params.value;
          if (fullValue.split(",").length > 5) {
            params.value = fullValue.split(",")[0] + ", >5";
          }

          return fullValue.length > 30 ? (
            <Tooltip title={fullValue} placement="top-end">
              <span>{params.value as string}</span>
            </Tooltip>
          ) : (
            <>{params.value}</>
          );
        }

        if (params?.id === "case_description") {
          return (
            <Tooltip title={params?.value as string} placement="top-end">
              <span>{trimString(params?.value as string, 30)}</span>
            </Tooltip>
          );
        }
        if (params?.id == "macrs_election" && isString(params?.value)) {
          const macrsElections = params?.value?.split(", ");
          return (
            <div>
              {macrsElections.map((macrsElection: string, idx: number) => (
                <div key={idx}>{macrsElection}</div>
              ))}
            </div>
          );
        }
        return <>{params.value}</>;
      },
    })),
  ];

  return (
    <>
      <Box className={cn("!mx-4 mt-4")}>
        {/* <DataGridPro
          classes={{ columnHeaderTitle: cn("font-bold") }}
          density="compact"
          treeData
          rows={rows}
          columns={columns}
          rowBuffer={100} // Prevent flickering
          loading={
            dealAnalysisInput?.length === 0 && dealAnalysisOutput?.length === 0
          }
          getRowClassName={getRowClassName}
          getRowHeight={gridRowHeight}
          pinnedRows={{
            top: type === "deal" ? undefined : rows?.slice(0, 3),
          }}
          initialState={{
            pinnedColumns: pinnedColumns,
            pagination: {
              paginationModel: {
                pageSize: 200,
              },
            },
          }}
          groupingColDef={{
            headerName: "Name",
            minWidth: 350,
            hideDescendantCount: true,
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            valueGetter: (params: any) => {
              if (params?.rowNode?.type === "pinnedRow") {
                return params?.row?.property;
              }
              return params?.rowNode?.groupingKey;
            },
          }}
          getTreeDataPath={getTreeDataPath}
          isGroupExpandedByDefault={() => !collapseAll}
          slots={{ toolbar: () => renderCustomToolbar(options) }}
          hideFooter
          disableColumnSelector
          disableColumnFilter
          disableColumnMenu
          getCellClassName={getDataGridCellClassName}
        /> */}
      </Box>
    </>
  );
}
