import React from "react";
import Chip from "@mui/material/Chip";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useAppSelector } from "../../utils/hooks";
import { PRIMARY_SIDEBAR_NAVIGATION } from "../../constants";

export default function ToggleSizingOutputButton() {
  const { dealId, caseDealId } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const { currentDeal } = useAppSelector((s) => s.deal);

  const getSizingTypeFromUrl = (path: string): string => {
    const segments = path.split("/");
    return segments[6];
  };

  const dealSizingPages = PRIMARY_SIDEBAR_NAVIGATION.find(
    (tab) => tab.label === "Deals",
  )?.subLinks?.find((sublink) => sublink.label === "Sizing")?.subLinks;

  const dealOutputPages = PRIMARY_SIDEBAR_NAVIGATION.find(
    (tab) => tab.label === "Deals",
  )?.subLinks?.find((sublink) => sublink.label === "Output")?.subLinks;

  const getRedirectPath = (currentPath: string): string | null => {
    const isSizingPath = currentPath.includes("/sizing/");
    let sizingType = getSizingTypeFromUrl(currentPath);

    const outputTypeMap: Record<string, string> = {
      "cash-equity": "tax-equity",
      "direct-pay": "transfer",
    };

    if (isSizingPath) {
      sizingType = outputTypeMap[sizingType] || sizingType;
      const outputPath = dealOutputPages?.find(
        (page) => page.value === sizingType,
      )?.path;

      if (outputPath) {
        return outputPath
          .replace("/sizing/", "/output/")
          .replace(":dealId", dealId!)
          .replace(":caseDealId", caseDealId!);
      }
    } else {
      if (currentDeal?.structure === "CEP" && sizingType === "tax-equity") {
        sizingType = "cash-equity";
      }

      if (
        currentDeal?.tax_credit_structure === "DP" &&
        sizingType === "transfer"
      ) {
        sizingType = "direct-pay";
      }

      if (sizingType === "sponsor-equity-bol") {
        sizingType = "sponsor-equity";
      }

      const sizingPath = dealSizingPages?.find(
        (page) => page.value === sizingType,
      )?.path;

      if (sizingPath) {
        return sizingPath
          .replace("/output/", "/sizing/")
          .replace(":dealId", dealId!)
          .replace(":caseDealId", caseDealId!);
      }
    }
    return null;
  };

  const handleClick = () => {
    const redirectPath = getRedirectPath(location.pathname);
    if (redirectPath) navigate(redirectPath);
  };

  const chipLabel = location.pathname.includes("/sizing/")
    ? "View Output"
    : "View Sizing";

  const isToggleDisabled = React.useMemo(() => {
    if (location.pathname.includes("/sizing/construction-debt")) {
      return !currentDeal?.is_construction_debt_added;
    }
    return false;
  }, [currentDeal?.is_construction_debt_added]);

  return currentDeal?.status === "ARCH" ? (
    <></>
  ) : (
    <Chip
      variant="outlined"
      label={chipLabel}
      onClick={handleClick}
      icon={<ArrowForwardIcon color="inherit" fontSize="small" />}
      disabled={isToggleDisabled}
    />
  );
}
