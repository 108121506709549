import React from "react";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";
import { useLocation, useNavigate } from "react-router-dom";

import ConfirmModal from "../confirm-modal";
import ProductPlansModal from "../product-plans-modal";
import ConfirmDeleteModal from "../confirm-delete-modal";
import { useAppSelector } from "../../utils/hooks";
import { updateContentLoadedAction } from "../../utils/redux/slices";

interface IProps {
  children: JSX.Element;
}

export default function AppWrapper({ children }: IProps): JSX.Element | null {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { organization } = useOrganization();

  const dispatch = useDispatch();
  const { deleteModalProps, confirmModalProps } = useAppSelector(
    (s) => s.common,
  );

  React.useEffect(() => {
    if (organization && pathname.includes("/personal/")) {
      navigate("/");
    } else if (!organization && !pathname.includes("/personal/")) {
      navigate("/personal/shared-deals");
    }
  }, [organization]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      dispatch(
        updateContentLoadedAction(
          Boolean(document.getElementById("content-loaded")),
        ),
      );
    }, 300);

    return () => clearInterval(interval);
  }, []);

  if (organization && pathname.includes("/personal/")) {
    return null;
  } else if (!organization && !pathname.includes("/personal/")) {
    return null;
  }

  return (
    <>
      {children}
      <ConfirmDeleteModal {...deleteModalProps} />
      <ConfirmModal {...confirmModalProps} />
      <ProductPlansModal />
    </>
  );
}
