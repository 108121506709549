import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import Header from "../../../components/header";
import Searchbar from "../../../components/search-bar";
import ViewWrapper from "../../../components/view-wrapper";
import { useAPI, useSessionStorage } from "../../../utils/hooks";
import { IGetSharedDetailsResponse, ITableSort } from "../../../interfaces";
import { cn, sortArrayOfObjects, trimString } from "../../../utils/helpers";

interface IProps {
  getSharedDeals: () => Promise<IGetSharedDetailsResponse>;
}

const TABLE_COLUMNS = [
  { id: "name", label: "Name", minWidth: 180, align: "left" },
  { id: "shared_by", label: "Shared By", minWidth: 180, align: "left" },
  { id: "cases", label: "Cases", minWidth: 180, align: "left" },
];

export default function SharedDealsPageView({
  getSharedDeals,
}: IProps): JSX.Element {
  const navigate = useNavigate();

  const [searchString, setSearchString] = React.useState("");
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const [_, setCurrentSharedDealOrgUuid] = useSessionStorage<null | string>(
    "current-shared-deal-org-uuid",
    null,
  );

  React.useEffect(() => {
    getSharedDealsCallAPI();
  }, []);

  const {
    callAPI: getSharedDealsCallAPI,
    errored: getSharedDealsFailed,
    loading: getSharedDealsLoading,
    response: getSharedDealsResponse,
  } = useAPI(getSharedDeals, {
    initialLoading: true,
  });

  const visibleRows = React.useMemo(() => {
    const searchedDeals = (getSharedDealsResponse?.results || []).filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );

    return sortArrayOfObjects(
      searchedDeals,
      sortTable?.orderBy,
      sortTable?.order,
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [getSharedDealsResponse, searchString, sortTable, page, rowsPerPage]);

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToSharedDealDetails = async (uuid: string, orgUuid: string) => {
    setCurrentSharedDealOrgUuid(orgUuid); // get id in function param and set
    navigate(`/personal/shared-deal/${uuid}/general`);
  };

  return (
    <Box>
      <Header toggleSidebar={() => {}} />
      <Box className="mt-[68px] min-h-[calc(100vh-68px)] overflow-y-auto py-6 px-8">
        <Typography variant="h5">Deals shared with you</Typography>
        <Searchbar
          searchString={searchString}
          onTextChange={onSearchStringChange}
        />

        <ViewWrapper
          loading={getSharedDealsLoading}
          error={getSharedDealsFailed}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }} className="mt-4">
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {TABLE_COLUMNS.map((column, idx) => {
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            disabled={column.id === "action"}
                            active={sortTable.orderBy === column.id}
                            direction={
                              sortTable.orderBy === column.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getSharedDealsResponse?.results.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_COLUMNS.length}>
                        There are no Deals shared with you.
                      </TableCell>
                    </TableRow>
                  )}

                  {visibleRows.map((deal, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={cn("cursor-pointer")}
                        onClick={() =>
                          goToSharedDealDetails(
                            deal.uuid,
                            deal.organization_uuid,
                          )
                        }
                        data-pw={`shared-deal-${idx + 1}`}
                      >
                        <TableCell align="left" title={deal.name}>
                          {trimString(deal.name, 40)}
                        </TableCell>
                        <TableCell align="left">{deal.shared_by}</TableCell>
                        <TableCell align="left">{deal.case_count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={visibleRows.length}
              className={cn("bg-table-gray")}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </ViewWrapper>
      </Box>
    </Box>
  );
}
