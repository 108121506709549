import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MuiButton from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import useStyles from "./styles";
import ConditionalProtect from "../../../../components/conditional-protect";

interface ICaseDetailsTableProps {
  title: string;
  columnHeaders: string[];
  addButtonProps?: {
    label: string;
    onClick: () => void;
  };
  children: React.ReactNode;
}

export default function CaseDetailsTable({
  title,
  columnHeaders,
  addButtonProps,
  children,
}: ICaseDetailsTableProps) {
  const styles = useStyles();
  return (
    <Paper classes={{ root: styles.classes.tableBlockContainer }}>
      <Typography fontWeight="600" marginBottom={1.5}>
        {title}
      </Typography>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow classes={{ root: styles.classes.tableRow }}>
              {columnHeaders.map((header) => (
                <TableCell> {header} </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{children}</TableBody>
        </Table>
      </TableContainer>
      {addButtonProps && (
        <ConditionalProtect type="deal">
          <MuiButton
            onClick={addButtonProps.onClick}
            classes={{ root: styles.classes.muiButton }}
          >
            {addButtonProps.label}
          </MuiButton>
        </ConditionalProtect>
      )}
    </Paper>
  );
}
