import React from "react";
import { cloneDeep } from "lodash";
import { useOrganization } from "@clerk/clerk-react";
import { useNavigate, useParams } from "react-router-dom";

import { useAPI } from "../../../utils/hooks";
import { getDealDetails } from "../../../apis/deal/base";
import {
  PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION,
  PRIMARY_SIDEBAR_NAVIGATION,
} from "../../../constants";
import { filterSidebarLinks } from "../../../utils/helpers";

export default function Output(): JSX.Element | null {
  const navigate = useNavigate();
  const { dealId, caseDealId } = useParams();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { callAPI: getDealDetailsCallAPI } = useAPI(
    (id) => getDealDetails(id),
    { initialLoading: true },
  );

  React.useEffect(() => {
    getDealDetailsCallAPI(Number(dealIdToUse)).then((deal) => {
      let links = PRIMARY_SIDEBAR_NAVIGATION.find(
        (l) => l.label === "Deals",
      )?.subLinks;

      if (!organization) {
        links = PERSONAL_ACCOUNT_SIDEBAR_NAVIGATION.find(
          (l) => l.label === "Case",
        )?.subLinks;
      }

      if (links && deal) {
        const clonedSidebar = cloneDeep(links);
        const li = filterSidebarLinks(clonedSidebar, {
          structure: deal?.structure,
          tax_credit_structure: deal?.tax_credit_structure,
        });
        const outputLinks = li?.find((l) => l?.label === "Output");
        const naviagteToUrl =
          outputLinks?.subLinks?.length &&
          outputLinks?.subLinks[0]?.path
            ?.replace(":dealId", String(dealId))
            .replace(":caseDealId", String(caseDealId));

        naviagteToUrl && navigate(naviagteToUrl);
      }
    });
  }, []);

  return null;
}
