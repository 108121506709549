import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Typography from "@mui/material/Typography";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { format } from "date-fns";

import useStyles from "./styles";
import ViewWrapper from "../../../components/view-wrapper";
import Searchbar from "../../../components/search-bar";
import Button from "../../../components/button";
import PopoverMenu from "../../../components/popover-menu";
import AddDealComparisonFormModal from "../../../components/add-deal-comparison-form-modal";
import UpdateDealComparisonFormModal from "../../../components/update-deal-comparison-form-modal";
import ConditionalProtect from "../../../components/conditional-protect";
import StickyTableCell from "../../../components/sticky-table-cell";
import { useAPI, useAppSelector } from "../../../utils/hooks";
import { setDeleteModalPropsAction } from "../../../utils/redux/slices";
import { cn, sortArrayOfObjects, trimString } from "../../../utils/helpers";
import {
  ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
  DEAL_STATUS,
  CREATION_DEAL_STATUS,
} from "../../../constants";
import {
  IDeal,
  IGetDealsParams,
  ISelectOption,
  ITableSort,
  ServerPaginatedResponse,
  IAddDealComparisonForm,
  IAddDealComparisonFormErrors,
  IDealComparison,
  IUpdateDealComparisonForm,
} from "../../../interfaces";

const columns = [
  { id: "name", label: "Deal Comparison Name", minWidth: 170, align: "left" },
  {
    id: "benchmark_deal.name",
    label: "Benchmark Deal Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "deal_count",
    label: "Number of Deals",
    minWidth: 50,
    align: "left",
  },
  { id: "modified", label: "Last Modified", minWidth: 50, align: "left" },
  { id: "action", label: "Action", minWidth: 50, align: "right" },
];

interface IProps {
  getDealComparisons: () => Promise<ServerPaginatedResponse<IDealComparison[]>>;
  addDealComparison: (form: IAddDealComparisonForm) => Promise<IDealComparison>;
  getDeals: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IDeal[]>>;
  updateDealComparison: (
    uuid: string,
    form: IUpdateDealComparisonForm,
  ) => Promise<IDealComparison>;
  deleteDealComparison: (uuid: string) => Promise<boolean>;
  getDealComparison: (uuid: string) => Promise<IDealComparison>;
}

export default function DealComparisonListView({
  addDealComparison,
  getDeals,
  getDealComparisons,
  updateDealComparison,
  deleteDealComparison,
  getDealComparison,
}: IProps): JSX.Element {
  const styles = useStyles();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);

  const [searchString, setSearchString] = React.useState("");
  const [addDealComparisonForm, setAddDealComparisonForm] =
    React.useState<IAddDealComparisonForm>(
      ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE,
    );
  const [dealOptions, setDealOptions] = React.useState<{
    [key in CREATION_DEAL_STATUS]: ISelectOption[];
  }>({
    Active: [],
    Draft: [],
  });
  const [dealComparisons, setDealComparisons] = React.useState<
    IDealComparison[]
  >([]);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(10);
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });
  const [addDealComparisonModalOpen, setAddDealComparisonModalOpen] =
    React.useState<boolean>(false);
  const [openEditFormModal, setOpenEditFormModal] =
    React.useState<boolean>(false);
  const [updateDealComparisonForm, setUpdateDealComparisonForm] =
    React.useState<IUpdateDealComparisonForm>(
      UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE,
    );

  React.useEffect(() => {
    getDealComparisonsAPI().then((res) => {
      res && setDealComparisons(res?.results);
    });
  }, []);

  const {
    callAPI: getDealComparisonsAPI,
    errored: getDealComparisonsFailed,
    loading: loadingGetDealComparisons,
  } = useAPI(() => getDealComparisons(), { initialLoading: true });

  const { callAPI: deleteDealComparisonCallAPI } = useAPI(
    (uuid: string) => deleteDealComparison(uuid),
    { setConfirmModalLoading: true },
  );

  const {
    callAPI: updateDealComparisonCallAPI,
    loading: loadingUpdateDealComparison,
    fieldErrors: formErrors,
    setFieldErrors: setFormErrors,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (uuid: string, form: IUpdateDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_ids: [form.benchmark_deal, ...form.deal_ids],
      };
      return updateDealComparison(
        uuid,
        updatedForm as IUpdateDealComparisonForm,
      );
    },
    { initialLoading: false },
  );

  const { callAPI: getDealComparisonCallAPI } = useAPI(
    (uuid: string) => getDealComparison(uuid),
    { initialLoading: true },
  );

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const filteredRows = React.useMemo(() => {
    return dealComparisons.filter((d) =>
      d.name.toLowerCase().includes(searchString.toLowerCase()),
    );
  }, [dealComparisons, searchString]);

  const visibleRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        filteredRows,
        sortTable?.orderBy,
        sortTable?.order,
      ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [filteredRows, sortTable, page, rowsPerPage],
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const goToDealComparison = (uuid: string) => {
    if (ctrlPressed) {
      window.open(`/analysis/deal-comparison/${uuid}`);
    } else {
      navigate(`/analysis/deal-comparison/${uuid}`);
    }
  };

  const handleOpenAddDealComparisonModal = async () => {
    await handleGetDeals();
    setAddDealComparisonModalOpen(true);
  };

  const handleGetDeals = async () => {
    const deals = await getDeals({}).catch(() => null);
    if (deals) {
      const categorizedDeals: {
        [key in CREATION_DEAL_STATUS]: ISelectOption[];
      } = {
        Active: [],
        Draft: [],
      };

      deals?.results?.forEach((d: IDeal) => {
        if (d?.status !== "ARCH") {
          categorizedDeals[DEAL_STATUS[d?.status]]?.push({
            label: d?.name,
            value: String(d?.id),
          });
        }
      });

      setDealOptions(categorizedDeals);
    }
  };

  const handleCloseAddDealComparisonModal = () => {
    setAddDealComparisonModalOpen(false);
  };

  const {
    callAPI: addDealComparisonCallAPI,
    fieldErrors: addDealComparisonFormErrors,
    setFieldErrors: setAddDealComparisonFormErrors,
    loading: addDealComparisonLoading,
  } = useAPI<IDealComparison, IAddDealComparisonFormErrors>(
    (form: IAddDealComparisonForm) => {
      const updatedForm = {
        name: form.name,
        deal_ids: [form.benchmark_deal, ...form.deal_ids],
      };
      return addDealComparison(updatedForm as IAddDealComparisonForm);
    },
  );

  const handleAddDealComparison = async (form: IAddDealComparisonForm) => {
    const deal = await addDealComparisonCallAPI(form);
    deal && goToDealComparison(deal.uuid);
    return deal;
  };

  const handleDeleteDealComparison = async (uuid: string) => {
    const deleted = await deleteDealComparisonCallAPI(uuid);

    // remove the comparison from the table
    if (deleted) {
      setDealComparisons((prevState) => {
        return prevState?.filter((c) => c.uuid !== uuid);
      });
    }
  };

  const handleOnDelete = (uuid: string | undefined) => {
    if (uuid === undefined) {
      return;
    }
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Deal Comparison",
        description: "Are you sure you want to delete?",
        onConfirm: () => handleDeleteDealComparison(uuid),
      }),
    );
  };

  const handleEditDealComparison = async (uuid: string) => {
    await handleGetDeals();
    const dealComparison = await getDealComparisonCallAPI(uuid);
    if (dealComparison) {
      const { name, benchmark_deal, deals_to_compare } = dealComparison;
      setUpdateDealComparisonForm({
        name,
        benchmark_deal: String(benchmark_deal?.id),
        deal_ids: deals_to_compare
          .map((d) => String(d?.id))
          .filter((d) => d !== String(benchmark_deal?.id)),
        uuid: dealComparison.uuid,
      });
      setOpenEditFormModal(true);
    }
  };

  const handleUpdate = async (form: IUpdateDealComparisonForm) => {
    const dealComparison = await updateDealComparisonCallAPI(form.uuid, form);

    if (dealComparison) {
      setDealComparisons((prevState) => {
        return prevState?.map((c) => {
          if (c.uuid === dealComparison.uuid) {
            return dealComparison;
          }
          return c;
        });
      });
    }

    return dealComparison;
  };

  return (
    <>
      <ViewWrapper
        loading={loadingGetDealComparisons}
        error={getDealComparisonsFailed}
      >
        {visibleRows.length === 0 ? (
          <Box className={styles.classes.emptyContainer}>
            <Box className={cn("flex flex-col items-center")}>
              <ConditionalProtect type="externalshare">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  label="Add a new Deal Comparison"
                  onClick={handleOpenAddDealComparisonModal}
                  btnType="primary"
                  className={styles.classes.createBtn}
                />
              </ConditionalProtect>
              <Typography
                variant="body1"
                className={styles.classes.createInfo}
                data-pw="empty-project-valuation-summary-message"
              >
                Deal comparison does not exist, click on Add Deal Comparison to
                add one.
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box className={styles.classes.container}>
            <Box className={styles.classes.topSection}>
              <Box className={styles.classes.searchAndFilterContainer}>
                <Searchbar
                  searchString={searchString}
                  onTextChange={onSearchStringChange}
                />
              </Box>
              <ConditionalProtect type="deal">
                <Button
                  canOpenUpgrade
                  startIcon={<AddIcon />}
                  btnType="primary"
                  label="Add Deal Comparison"
                  onClick={handleOpenAddDealComparisonModal}
                />
              </ConditionalProtect>
            </Box>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer classes={{ root: styles.classes.tableContainer }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead className={styles.classes.header}>
                    <TableRow>
                      {columns.map((column, idx) => {
                        if (column.id === "action") {
                          return (
                            <StickyTableCell
                              key={idx}
                              direction="right"
                              fixedColumnWidth={50}
                              align="center"
                              highZIndex
                            >
                              {column.label}
                            </StickyTableCell>
                          );
                        }
                        return (
                          <TableCell
                            key={idx}
                            align={column.align as "left" | "right"}
                            style={{ minWidth: column.minWidth }}
                          >
                            <TableSortLabel
                              active={sortTable.orderBy === column.id}
                              direction={
                                sortTable.orderBy === column.id
                                  ? sortTable.order
                                  : "asc"
                              }
                              onClick={() => sortRows(column.id)}
                            >
                              {column.label}
                            </TableSortLabel>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visibleRows.map((dealComparison, idx) => {
                      return (
                        <TableRow
                          hover
                          key={idx}
                          tabIndex={-1}
                          className={styles.classes.dataRow}
                          onClick={() =>
                            goToDealComparison(dealComparison.uuid)
                          }
                          data-pw={`deal-comparison-row-${idx + 1}`}
                        >
                          <TableCell align="left" title={dealComparison.name}>
                            {trimString(dealComparison?.name, 40)}
                          </TableCell>
                          <TableCell align="left" title="">
                            {dealComparison?.benchmark_deal !== null
                              ? trimString(
                                  dealComparison?.benchmark_deal.name,
                                  80,
                                )
                              : ""}
                          </TableCell>
                          <TableCell align="left">
                            {dealComparison.deal_count}
                          </TableCell>
                          <TableCell align="left">
                            {format(
                              new Date(dealComparison?.modified),
                              "M/d/yyyy",
                            )}
                          </TableCell>
                          <StickyTableCell
                            direction="right"
                            fixedColumnWidth={50}
                            align="center"
                          >
                            <ConditionalProtect type="deal">
                              <PopoverMenu
                                uniqueId={idx}
                                canOpenUpgrade
                                items={[
                                  {
                                    label: "Edit",
                                    onClick: () =>
                                      handleEditDealComparison(
                                        dealComparison.uuid,
                                      ),
                                  },
                                  {
                                    label: "Delete",
                                    onClick: () =>
                                      handleOnDelete(dealComparison.uuid),
                                  },
                                ]}
                              />
                            </ConditionalProtect>
                          </StickyTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={dealComparisons?.length}
                className={styles.classes.paginationRow}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </ViewWrapper>
      <AddDealComparisonFormModal
        headerLabel="Add a new Deal Comparison"
        open={addDealComparisonModalOpen}
        loading={addDealComparisonLoading}
        form={addDealComparisonForm}
        formErrors={addDealComparisonFormErrors}
        setFormErrors={setAddDealComparisonFormErrors}
        setForm={setAddDealComparisonForm}
        onClose={handleCloseAddDealComparisonModal}
        onConfirm={handleAddDealComparison}
        existingDeals={dealOptions}
      />

      <UpdateDealComparisonFormModal
        headerLabel="Edit Deal Comparison"
        open={openEditFormModal}
        loading={loadingUpdateDealComparison}
        form={updateDealComparisonForm}
        setForm={setUpdateDealComparisonForm}
        onClose={() => setOpenEditFormModal(false)}
        formErrors={formErrors}
        setFormErrors={setFormErrors}
        existingDeals={dealOptions}
        onConfirm={handleUpdate}
      />
    </>
  );
}
