import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOrganization } from "@clerk/clerk-react";

import ViewWrapper from "../../../../components/view-wrapper";
import ReportTable from "../../../../components/report-table";
import ToggleSizingOutputButton from "../../../../components/toggle-sizing-output-button";
import { useAPI } from "../../../../utils/hooks";
import { useAxios } from "../../../../components/axios-provider";
import { downloadUserDealReport } from "../../../../apis/report/deal";
import { CONSTRUCTION_DEBT_LOAN_REPORT_COLUMNS } from "../../../../constants";
import { updateDealOutputLoadedAction } from "../../../../utils/redux/slices";
import {
  cn,
  getDealReportingUrl,
  generateConstructionDebtLoanReportTableRows,
} from "../../../../utils/helpers";
import {
  ITableRow,
  ReportTerm,
  ReportType,
  ReportPerspective,
  IConstructionDebtLoanReport,
  OutputReportPeriodicityType,
} from "../../../../interfaces";

interface IProps {
  getDealReporting: (
    dealId: number,
    reportPerspective: ReportPerspective,
    reportType: ReportType,
    reportTerm: ReportTerm,
    reportPeriodicityType: OutputReportPeriodicityType,
  ) => Promise<IConstructionDebtLoanReport>;
  reportType: ReportType;
}

export default function DealOutputConstructionDebtCommonView({
  getDealReporting,
  reportType,
}: IProps): JSX.Element {
  const { caseDealId, dealId } = useParams();

  const dispatch = useDispatch();

  const { organization } = useOrganization();
  const dealIdToUse = organization ? caseDealId : dealId;

  const { urlLoadingPercentages } = useAxios();

  const [report, setReport] = React.useState<IConstructionDebtLoanReport>();
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const {
    callAPI: getDealReportingCallAPI,
    errored: getReportFailed,
    loading: loadingReport,
    errorObj: getReportErrorObj,
    showUsageLimitHitScreen,
  } = useAPI(
    (
      dealId: number,
      term: ReportTerm,
      reportPeriodicityType: OutputReportPeriodicityType,
    ) =>
      getDealReporting(
        dealId,
        "constructiondebt",
        reportType,
        term,
        reportPeriodicityType,
      ),
    { initialLoading: true },
  );

  React.useEffect(() => {
    if (Number(dealIdToUse)) {
      getDealReportingCallAPI(dealIdToUse, "default", "MO").then((res) => {
        res && setReport(res);
      });
    }
  }, [dealIdToUse]);

  React.useEffect(() => {
    !loadingReport && dispatch(updateDealOutputLoadedAction(!getReportFailed));
  }, [getReportFailed, loadingReport]);

  const handleDownloadReport = async () => {
    if (dealIdToUse) {
      setIsDownloading(true);
      await downloadUserDealReport(
        Number(dealIdToUse),
        "constructiondebt",
        reportType,
        "default",
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const tableRows: ITableRow[] = React.useMemo(() => {
    return report ? generateConstructionDebtLoanReportTableRows(report) : [];
  }, [report]);

  const reportLoadingPercentage =
    urlLoadingPercentages[
      getDealReportingUrl(
        Number(dealIdToUse),
        "constructiondebt",
        reportType,
        "default",
        "MO",
      )
    ];

  return (
    <ViewWrapper
      loading={loadingReport}
      error={getReportFailed}
      errorHeading={getReportErrorObj?.heading}
      errorDescription={getReportErrorObj?.description}
      showLimitHitView={showUsageLimitHitScreen}
      loadingPercentage={reportLoadingPercentage}
    >
      <Box>
        <Box className={cn("flex justify-end gap-2 my-4")}>
          <ToggleSizingOutputButton />
          <IconButton
            title="Download Report"
            onClick={handleDownloadReport}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        </Box>

        <ReportTable
          columns={CONSTRUCTION_DEBT_LOAN_REPORT_COLUMNS}
          rows={tableRows}
          boldTotal
          stickyTotal
          bigHeader
        />
      </Box>
    </ViewWrapper>
  );
}
