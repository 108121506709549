import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../../utils/helpers";

export default makeStyles()((theme) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    tableHeader: {
      ".MuiTableCell-root": {
        fontWeight: 600,
      },
    },
    emptyContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "100vh",
    },
    createBtn: {
      marginBottom: "16px",
      height: "60px",
      padding: "0 20px",
    },
    createInfo: {
      display: "block",
      marginTop: 20,
      backgroundColor: selectedTheme.color.aboveSurface,
      padding: theme.spacing(1, 2),
      borderRadius: 20,
      color: "#666",
    },
  };
});
