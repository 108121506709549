import { COLLABORATION_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  ServerPaginatedResponse,
  ICollaboration,
  ICollaborationCase,
  ICollaborationCaseForm,
  ICollaborationCounterparty,
  ICollaborationCounterpartyForm,
  ICollaborationDetails,
  ICollaborationForm,
} from "../../interfaces";

export const getCollaborations = async () => {
  return await AxiosHelper.get<ServerPaginatedResponse<ICollaboration[]>>(
    COLLABORATION_ROUTES.base.get,
  );
};

export const createCollaboration = async (form: ICollaborationForm) => {
  return await AxiosHelper.post<ICollaborationForm, ICollaboration>(
    COLLABORATION_ROUTES.base.create,
    form,
  );
};

export const getCollaborationDetails = async (uuid: string) => {
  return await AxiosHelper.get<ICollaborationDetails>(
    replaceParams(COLLABORATION_ROUTES.base.getDetails, { uuid }),
  );
};

export const updateCollaboration = async (
  uuid: string,
  form: ICollaborationForm,
) => {
  const url = replaceParams(COLLABORATION_ROUTES.base.update, { uuid });
  return await AxiosHelper.put<ICollaborationForm, ICollaboration>(url, form);
};

export const getCollaborationSharedCases = async (uuid: string) => {
  const url = replaceParams(COLLABORATION_ROUTES.cases.sharedCases.get, {
    uuid,
  });
  return await AxiosHelper.get<ServerPaginatedResponse<ICollaborationCase[]>>(
    url,
  );
};

export const createCollaborationSharedCase = async (
  uuid: string,
  form: ICollaborationCaseForm,
) => {
  const url = replaceParams(COLLABORATION_ROUTES.cases.sharedCases.create, {
    uuid,
  });
  return await AxiosHelper.post<ICollaborationCaseForm, ICollaborationCase>(
    url,
    form,
  );
};

export const updateCollaborationSharedCase = async (
  uuid: string,
  caseId: number,
  form: ICollaborationCaseForm,
) => {
  const url = replaceParams(COLLABORATION_ROUTES.cases.sharedCases.update, {
    uuid,
    caseId,
  });
  return await AxiosHelper.put<ICollaborationCaseForm, ICollaborationCase>(
    url,
    form,
  );
};

export const addDealCounterparty = async (
  collaborationUuid: string,
  form: ICollaborationCounterpartyForm,
) => {
  const url = replaceParams(COLLABORATION_ROUTES.base.addDealCounterparty, {
    collaborationUuid,
  });
  return await AxiosHelper.post<
    ICollaborationCounterpartyForm,
    ICollaborationCounterparty
  >(url, form);
};

export const updateDealCounterparty = async (
  collaborationUuid: string,
  id: number,
  form: ICollaborationCounterpartyForm,
) => {
  const url = replaceParams(COLLABORATION_ROUTES.base.editDealCounterparty, {
    collaborationUuid,
    id,
  });

  return await AxiosHelper.patch<
    ICollaborationCounterpartyForm,
    ICollaborationCounterparty
  >(url, form);
};

export const getDealCounterparties = async (collaborationUuid: string) => {
  const url = replaceParams(COLLABORATION_ROUTES.base.getDealCounterparties, {
    collaborationUuid,
  });
  return await AxiosHelper.get<
    ServerPaginatedResponse<ICollaborationCounterparty[]>
  >(url);
};

export const deleteCollaborationCounterparty = async (
  collaborationUuid: string,
  id: number,
) => {
  const url = replaceParams(COLLABORATION_ROUTES.base.deleteDealCounterparty, {
    collaborationUuid,
    id,
  });
  return await AxiosHelper.delete(url);
};
